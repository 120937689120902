import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GlobalNav from '../../modules/Nav'
import Overview from './Overview';
import { Sheet, Section, HStack, Media, Cell, Container, Icon, Button, Title2, Headline, Title3, Popover, Subhead, Divider, Caption1, Group, VStack, Caption2, Input, Label, Body, Loader, Tab, Title1, Space, Footnote } from '@themeable/react-components';
import { useContextValue } from '../../AppContext';
import { SaveData, CalculateChanges, timestampToDate, CreateNewSystem } from '../Utilities/UtilityFunctions';
import FoundationsNav from '../Foundations/FoundationsNav';
import Color from '../Foundations/Color';
import FoundationsSpace from '../Foundations/Space';
import Radius from '../Foundations/Radius';
import TextFamilies from '../Foundations/TextFamilies';
import TextStyles from '../Foundations/TextStyles';
import Size from '../Foundations/Size';
import ChangeLogSheet from './ChangeLogSheet';

const Home = () => {

	const { 
		context,
		setContext,
		cColor,
		cRadius,
		cSpace,
		cSize,
		cText,
		setCText
	} = useContextValue();
    const navigate = useNavigate();
	

	// =====================
	// =====================
	// Autosave feature ----
	// =====================
	// =====================
	const [pageLoadedLength, setPageLoadedLength] = useState(0);
	const [lastDataChange, setLastDataChange] = useState(0);
	const [lotOfTyping, setLotOfTyping] = useState(0);

	const [autoSaveTimestamp, setAutoSaveTimestamp] = useState('');
	const [lastDataChangeTimestamp, setLastDataChangeTimestamp] = useState();
	const [timestampOfLastChange, setTimestampOfLastChange] = useState(new Date());
	const [showTimestamp, setShowTimestamp] = useState(false);
	const [showChangeLog, setShowChangeLog] = useState(false);
	const [systemNotExist, setSystemNotExist] = useState(false);
	const [pageTitle, setPageTitle] = useState('Welcome');
	const [pageSubTitle, setPageSubTitle] = useState('Select a system below or create a new one.');
	



	return (<>    
		<main className='page-bleed' style={{ backgroundColor: 'rgb(var(--color-background-flat))', height: '100%'}}>
			<GlobalNav />

		<>
		<Section>
			<VStack grow layout='left'>
				<Title1>{pageTitle}</Title1>
				<Body>{pageSubTitle}</Body>
				<Button small text1='Create System' icon1='PlusCircle' variant='primary' onClick={() => {CreateNewSystem(null, context)}}/>
			</VStack>
		</Section>
		{context.systemList && (
			<>
			<Section>
				<VStack maxWidth='400px'>
					<Subhead color='--color-text-secondary'>Existing systems:</Subhead>
					{Object.keys(context.systemList).map((systemId) => {
						const system = context.systemList[systemId];
						return (
							<Cell key={systemId} variant='link' url={`systemid=${systemId}`}>
								<Cell.Text>
									<Body>{system.name}</Body>
									<Footnote color='--color-text-secondary'>Modified: <span style={{color: 'rgba(var(--color-text-primary), var(--color-text-primary-alpha))'}}>{timestampToDate(context.systemList[systemId].lastModified)}</span></Footnote>
								</Cell.Text>
								<Icon name='ChevronRight' />
							</Cell>
						);
					})}
				</VStack>
			</Section>
			</>
		)}
		</>

		</main>
	</>)

};

export default Home;
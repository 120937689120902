import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../services/firebase';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../services/firebase';

// import { googleLogout, useGoogleLogin, GoogleLogin } from '@react-oauth/google';

const ForgotPassword = () => {

  const navigate = useNavigate();

  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');

  const onSubmit = async (e) => {

		const auth = getAuth();
		sendPasswordResetEmail(auth, email)
  	.then(() => {
    console.log('password reset sent')
    // ..
  	})
		.catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;
			// ..
		});


	}
    
  return (
		<main >        
			<section>
				<div>
					<div>

				
				<h1> Forgot password? </h1>                                                                         
				<form>                                                                                            			
					<div>
						<label htmlFor="name">
							Email address
						</label>
						<input
							type="email"
							label="Email address"
							value={email}
							onChange={(e) => setEmail(e.target.value)}  
							required                                    
							placeholder="Email address"                                
						/>
					</div>
					                                           
				
					<button	
						type="submit" 
						onClick={onSubmit}                        
					>  
						Reset password                                
					</button>
					{/* <GoogleLogin onSuccess={googleResponseMessage} onError={googleErrorMessage} /> */}
																											
				</form>
									
						<p>
							Remember your password?{' '}

							<NavLink to={token ? `/login/${token.replace(/ /g, '%20').replace(/\//g, "%2F")}` : '/login'}>
								Log in
							</NavLink>  

						</p>                   
					</div>
				</div>
			</section>
		</main>
  )
}
 
export default ForgotPassword
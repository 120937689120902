import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import GlobalNav, { Nav } from '../modules/Nav'
import { HStack, VStack } from '../modules/Stack';

const Documentation = () => {
  const { topic } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isDemo = searchParams.get('demo') === 'true'; // This will be a boolean value
  
  const baseIframeSrc = `https://doc-site-ff745.web.app/?path=/`;
  let iframeSrc;
  
  if(isDemo) {
    iframeSrc = `${baseIframeSrc}story/components-${topic}--try-it`;
  } else if (!topic) {
    iframeSrc = `${baseIframeSrc}docs/introduction--docs`
  } else {
    iframeSrc = `${baseIframeSrc}docs/components-${topic}--docs`;
  }
  
  return (
    <section style={{backgroundColor: 'var(--color-bg-flat)', height: 'calc(100vh)', overflow: ''}}>
      <VStack style={{height: '100%'}}>
        <GlobalNav />
        <iframe style={{width: "100%", border: 'none', height: '100%'}} src={iframeSrc} title="Documentation Page"/>
      </VStack>
    </section>
  );
};

export default Documentation;


// http://localhost:3000/documentation/text?demo=true
// http://localhost:3000/documentation/text
import React from 'react';
import PropTypes from 'prop-types';

// Assuming you'll populate these styles and colors later
const styles = {
  
};

const colors = {

};

const Text = ({ children }) => {
  return <React.Fragment>{children}</React.Fragment>;
};

const styledComponent = (variant) => {
  return ({ children, color, tag = 'p', className = '', style }) => {
    const colorStyle = color ? { color: `rgb(var(--color-${color}))` } : {};
    const combinedClassName = `${variant} ${className}`.trim();
    const mergedStyles = { ...colorStyle, ...style }; // Merge the color style and the provided style
    return React.createElement(tag, {
      className: combinedClassName,
      style: mergedStyles,
    }, children);
  };
};


// You can add more tags to this list as needed
const validTags = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'div'];

Text.propTypes = {
  tag: PropTypes.oneOf(validTags),
};

Text.defaultProps = {
  tag: 'p',
};

Text.title1 = styledComponent('title1');
Text.title2 = styledComponent('title2');
Text.title3 = styledComponent('title3');
Text.headline = styledComponent('headline');
Text.body = styledComponent('body');
Text.subhead = styledComponent('subhead');
Text.footnote = styledComponent('footnote');
Text.caption1 = styledComponent('caption1');
Text.caption2 = styledComponent('caption2');

export default Text;
// AppContext.js
import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const ContextProvider = ({ children }) => {
	const [context, setContext] = useState({}); 
	const [cColor, setCColor] = useState({
		light: {
		},
		dark: {
		}
	});
	const [cRadius, setCRadius] = useState({
		compact: {
		},
		medium: {
		},
		expanded: {
		}
	});
	const [cSpace, setCSpace] = useState({
		compact: {
		},
		medium: {
		},
		expanded: {
		}
	});
	const [cSize, setCSize] = useState({
		compact: {
		},
		medium: {
		},
		expanded: {
		},
		responsive: {
		}
	});
	const [cText, setCText] = useState({
		compact: {
		},
		medium: {
		},
		expanded: {
		},
		family: {
		},
		weight: {
		},
	});
	const [altTheme, setAltTheme] = useState({});
	return (
		<AppContext.Provider value={{ 
			context,
			setContext,
			cColor,
			setCColor,
			altTheme,
			setAltTheme,
			cRadius,
			setCRadius,
			cSpace,
			setCSpace,
			cSize,
			setCSize,
			cText,
			setCText
			}}>
			{children}
		</AppContext.Provider>
	);
};

export const useContextValue = () => useContext(AppContext);

import { httpsCallable } from 'firebase/functions';
import {functions} from '../services/firebase';


const createStripePortalSession = httpsCallable(functions, 'createStripePortalSession');
const createStripeCheckoutSession = httpsCallable(functions, 'createStripeCheckoutSession');

export const handleStripeSession = async () => {
    try {
        const res = await createStripePortalSession({ returnUrl: window.location.href });
        if (res.error) {
            console.error("Received error from Cloud Function:", res.error);
            return res.error;
        } else {
            window.location.href = res.data.url;
        }
    } catch (error) {
        console.error("Error calling createStripePortalSession:", error);
        return "There was a problem initializing the portal session.";
    }
};

export const handleCheckout = async (priceId) => {
    try {
        const res = await createStripeCheckoutSession({ 
            priceId: priceId,
            returnUrl: window.location.href
        });
        if (res.error) {
            console.error("Received error from Cloud Function:", res.error);
            return res.error;
        } else {
            window.location.href = res.data.url;
        }
    } catch (error) {
        console.error("Error calling createStripeCheckoutSession:", error);
        return "There was a problem initializing the checkout process.";
    }
};

import React from 'react';
import PropTypes from 'prop-types';
const Header = ({ title, type, inset, center }) => {
  
  return (
    <>
      <div className={`header-${type} ${inset ? 'inset': ''} ${center ? 'text-center':''}`}>
        {title}
      </div>
    </>
  );
};

Header.defaultProps = {
  type: 'label',
  inset: false,
  center: false
};

Header.propTypes = {
  type: PropTypes.oneOf(['label', 'title3', 'title2', 'title1']),
  inset: PropTypes.bool
};

export default Header;

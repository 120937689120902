import React, { useEffect, useState } from "react";
import { VStack, Section, HStack, Space, Body, Footnote, Title1, Headline, Button, Tab, Divider, Subhead, Container } from "@themeable/react-components";

const SampleElements = ({ system, colorMode, screenSize }) => {
    
    const c = system.foundations?.color?.[colorMode] || {};
    const r = system.foundations?.radius?.[screenSize] || {};
    const size = system.foundations?.size?.[screenSize] || {};
    const space = system.foundations?.space?.[screenSize] || {};
    const text = system.foundations?.text?.[screenSize] || {};
    const textFamily = system.foundations?.text?.family || {};

    const hexToRgb = (hex) => {
        if (hex != undefined) {
          const hexValue = hex.replace('#', '');
          const r = parseInt(hexValue.substr(0, 2), 16);
          const g = parseInt(hexValue.substr(2, 2), 16);
          const b = parseInt(hexValue.substr(4, 2), 16);
          const rgb = `${r}, ${g}, ${b}`;
          return rgb;
        } else return ''
    };

return (
    <>
    <VStack>
    <HStack>
        <Tab active text1="Examples" icon1="Home" indicator="bottom"/>
        <Tab text1="Tab 2" />
        <Tab text1="Tab 3" />
    </HStack>
    <Divider nested />
    <Space.NewSection />

    <Headline>Text inputs</Headline>
    <HStack grow layout="top">
        <VStack grow>
        <div style={{display: 'flex', flexDirection: 'column', flex: '1'}}>
        <div style={{color: `rgba(${hexToRgb(c.textSecondary)}, ${c.textSecondaryAlpha})`, backgroundColor: '', font: 'var(--font-subhead)', margin: `${space.vExternal}px ${space.hExternal}px 0px ${space.hExternal}px`}}>
            Focused input
        </div>
        
        <div className="h-stack middle left" style={{
            border: `2px solid rgba(${hexToRgb(c.primary)}, 1)`, 
            margin: `${space.vExternal}px ${space.hExternal}px`, 
            padding: `${space.vInternal}px calc(${space.hInternal}px + ${space.lineHeight}px)`, 
            borderRadius: `${r.largeControl}px`, 
            minHeight: `${size.large}px`}}
            >
            <span style={{color: `rgba(${hexToRgb(c.textPrimary)}, ${c.textPrimaryAlpha})`, font: 'var(--font-body)', border: `rgba(${hexToRgb(c.primary)}, 1)`}}>
                Inputted text
            </span>
            <div style={{width: '.5px', height: '24px', backgroundColor: c.textPrimary}}/>                        
        </div>
    </div>
        </VStack>
        <VStack grow>
        <div style={{display: 'flex', flexDirection: 'column', flex: '1'}}>
        <div style={{color: `rgba(${hexToRgb(c.textSecondary)}, ${c.textSecondaryAlpha})`, backgroundColor: '', font: 'var(--font-subhead)', margin: `${space.vExternal}px ${space.hExternal}px 0px ${space.hExternal}px`}}>
            Error input
        </div>

        <div className="h-stack middle" style={{minHeight: `${size.large}px`, border: `1px solid ${c.negative}`, margin: `${space.vExternal}px ${space.hExternal}px 0px ${space.hExternal}px`, padding: `${space.vInternal}px ${space.hInternal}px ${space.vInternal}px calc(${space.hInternal}px + ${space.lineHeight}px)`, borderRadius: `${r.largeControl}px`}}>
            <span style={{font: 'var(--font-body)', color: `rgba(${hexToRgb(c.textSecondary)}, ${c.textSecondaryAlpha})`}}>
                Placeholder text
            </span>
            <Space.Grow/>
            <svg xmlns='http://www.w3.org/2000/svg' className={`icon`} width={size.base} height={size.base}>
            <use 
                xlinkHref={`../icons.svg#iconWarning`}
                style={{fill: c.negative }}
            />
            </svg>
        </div>

        <Footnote color="--ts-color-negative">
            Error message
        </Footnote>
    </div>
        </VStack>
    </HStack>
        
    <Divider />
    <Headline>Buttons</Headline>

    <HStack gridDesktop={2} gridTablet={2} gridMobile={1}>
        <VStack>
            <Button variant="primary" text1="Primary button" />
            <Button variant="secondary" text1="Secondary button" />
            <Button variant="quaternary" text1="Quaternary button" />
        </VStack>
        <VStack>
            <Button variant="accent" text1="Accent button" />
            <Button variant="tertiary" text1="Tertiary button" />
            <Button variant="destructive" text1="Destructive button" />
        </VStack>
    </HStack>

    <Divider />

    <HStack gridDesktop={2} gridTablet={1}>
        
        <VStack>
            <Subhead color="--color-text-secondary">Select an option:</Subhead>
            <HStack wrap >
                <Button small text1="Option 1" variant="primary"/>
                <Button small text1="Option 2" variant="secondary"/>
                <Button small text1="Option 3" variant="secondary"/>
            </HStack>
            <Divider />
            <Subhead color="--color-text-secondary">Select multiple options:</Subhead>
            <HStack wrap >
                <Button small text1="Option 1" variant="secondary" icon1="Check"/>
                <Button small text1="Option 2" variant="quaternary"/>                
                <Button small text1="Option 3" variant="secondary"/>                
                <Button small text1="Option 4" variant="quaternary"/>                
                <Button small text1="Option 5" variant="quaternary"/>                
                <Button small text1="Option 6" variant="quaternary"/>                
                <Button small text1="Option 7" variant="quaternary"/>                
            </HStack>
            <Divider />
    

        
        </VStack>
        
        <Container>
        <VStack>

            
            <HStack grow layout='middle' style={{ margin: `calc(${space.vExternal}px + ${space.vInternalSmall}px) ${space.hExternal}px`, minHeight: `${size.base}px`}}>
            <div style={{height: '4px', flex: '2', background: c.primary, borderTopLeftRadius: `${size.base}px`, borderBottomLeftRadius: `${size.base}px`}}/>
            <div style={{boxShadow: 'var(--elevation-accent)', border: `.5px solid ${c.border}`, height: `20px`, width: `20px`, borderRadius: `${size.base}px`, background: c.backgroundElevated }}/>
            <div style={{height: '4px', flex: '1', background: `rgba(${hexToRgb(c.fillControl)}, ${c.fillControlAlpha})`, borderTopRightRadius: `${size.base}px`, borderBottomRightRadius: `${size.base}px`}}/>
            </HStack>

            <Divider />

            <HStack grow layout='middle' style={{ margin: `calc(${space.vExternal}px + ${space.vInternalSmall}px) ${space.hExternal}px`, minHeight: `${size.base}px`}}>
            <div style={{position: 'relative', height: `${size.base}px`, flex: '1', background: c.primary, borderRadius: `${size.base}px`}}>
                <div style={{position: 'absolute', right: '0%' , top: '50%', transform: 'translate(-2px, -50%)', boxShadow: 'var(--elevation-accent)', border: `.5px solid ${c.border}`, height: `20px`, width: `20px`, borderRadius: `${size.base}px`, background:`${c.backgroundElevated}`, }}/>
            </div>
            <div style={{marginLeft: '-16px', height: `${size.base}px`, flex: '1', background: `rgba(${hexToRgb(c.fillControl)}, ${c.fillControlAlpha})`, borderTopRightRadius: `${size.base}px`, borderBottomRightRadius: `${size.base}px`}}/>
            </HStack>
            <HStack grow layout='middle' style={{ margin: `calc(${space.vExternal}px + ${space.vInternalSmall}px) ${space.hExternal}px`, minHeight: `${size.base}px`}}>
            <div style={{position: 'relative', height: `${size.base}px`, flex: '1', background: `rgba(${hexToRgb(c.fillControl)}, ${c.fillControlAlpha})`, borderRadius: `${size.base}px`}}>
                <div style={{position: 'absolute', left: '0%' , top: '50%', transform: 'translate(2px, -50%)', boxShadow: 'var(--elevation-accent)', border: `.5px solid ${c.border}`, height: `20px`, width: `20px`, borderRadius: `${size.base}px`, background:`${c.icon}`, }}/>
            </div>
            </HStack>
            
            <Divider />

            <HStack>

                {/* Pill on switch */}
                <HStack grow layout='middle' style={{ margin: `calc(${space.vExternal}px + ${space.vInternalSmall}px) ${space.hExternal}px`, minHeight: `${size.base}px`}}>
                <div style={{width: '44px', position: 'relative', height: `${size.base}px`, background: c.primary, borderRadius: `${size.base}px`}}>
                    <div style={{position: 'absolute', right: '0%' , top: '50%', transform: 'translate(-2px, -50%)', boxShadow: 'var(--elevation-accent)', height: `20px`, width: `20px`, borderRadius: `${size.base}px`, background:`${c.backgroundElevated}`, }}/>
                </div>
                </HStack>

                {/* Pill off switch */}
                <HStack grow layout='middle' style={{ margin: `calc(${space.vExternal}px + ${space.vInternalSmall}px) ${space.hExternal}px`, minHeight: `${size.base}px`}}>
                <div style={{width: '44px', position: 'relative', height: `${size.base}px`, background: `rgba(${hexToRgb(c.fillControl)}, ${c.fillControlAlpha})`, borderRadius: `${size.base}px`}}>
                    <div style={{position: 'absolute', left: '0%' , top: '50%', transform: 'translate(2px, -50%)', boxShadow: 'var(--elevation-accent)', border: `.5px solid ${c.border}`, height: `20px`, width: `20px`, borderRadius: `${size.base}px`, background:`${c.icon}`, }}/>
                </div>
                </HStack>

                {/* Stick on switch */}
                <HStack grow layout='middle' style={{ margin: `calc(${space.vExternal}px + ${space.vInternalSmall}px) ${space.hExternal}px`, minHeight: `${size.base}px`}}>
                <div style={{width: '38px', position: 'relative', height: `4px`, background: `rgba(${hexToRgb(c.primary)}, .3)`, borderRadius: `${size.base}px`}}>
                    <div style={{position: 'absolute', right: '0%' , top: '50%', transform: 'translate(0px, -50%)', boxShadow: 'var(--elevation-accent)', height: `20px`, width: `20px`, borderRadius: `${size.base}px`, background:`${c.primary}`, }}/>
                </div>
                </HStack>

                {/* Stick off switch */}
                <HStack grow layout='middle' style={{ margin: `calc(${space.vExternal}px + ${space.vInternalSmall}px) ${space.hExternal}px`, minHeight: `${size.base}px`}}>
                <div style={{width: '38px', position: 'relative', height: `4px`, background: `rgba(${hexToRgb(c.fillControl)}, ${c.fillControlAlpha})`, borderRadius: `${size.base}px`}}>
                    <div style={{position: 'absolute', left: '0%' , top: '50%', transform: 'translate(0px, -50%)', boxShadow: 'var(--elevation-accent)', height: `20px`, width: `20px`, borderRadius: `${size.base}px`, background:`${c.icon}`, }}/>
                </div>
                </HStack>
                
            </HStack>
            <HStack style={{margin: `${space.vExternal}px ${space.hExternal}px`, height: '1px', background: `rgba(${hexToRgb(c.Divider)}, ${c.DividerAlpha})`}}/>

            
            {/* Pagination */}
            <HStack grow layout="middle center">
                <div style={{background: `rgba(${hexToRgb(c.readOnly)}, ${c.readOnlyAlpha})`, height: '8px', width: '8px', borderRadius: '24px'}}/>
                <Space.Content/>
                <div style={{background: `rgba(${hexToRgb(c.readOnly)}, ${c.readOnlyAlpha})`, height: '8px', width: '8px', borderRadius: '24px'}}/>
                <Space.Content/>
                <div style={{background: `rgba(${hexToRgb(c.primary)}, 1)`, height: '12px', width: '12px', borderRadius: '24px'}}/>
                <Space.Content/>
                <div style={{background: `rgba(${hexToRgb(c.readOnly)}, ${c.readOnlyAlpha})`, height: '8px', width: '8px', borderRadius: '24px'}}/>
            </HStack>



        </VStack>
        </Container>
            <Space.NewSection />
    </HStack>
    </VStack>
    
</>
)

}

export { SampleElements }
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
// import { googleLogout, useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import { useLocation } from 'react-router-dom';
import { Logo } from '../../modules/Logo';
import {db, functions, auth} from '../../services/firebase';
import { httpsCallable } from 'firebase/functions';
import { Body, Button, Container, HStack, Headline, Input, Label, Media, Section, Sheet, Space, Title2, VStack } from '@themeable/react-components';
import { CreateNewSystem } from '../Utilities/UtilityFunctions';
import { useContextValue } from '../../AppContext';

const createStripeCustomer = httpsCallable(functions, 'createStripeCustomer');


const Signup = () => {

	
  const navigate = useNavigate();

  // Retrieve query parameters from the URL
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userName = searchParams.get('userName');
  const userToken = searchParams.get('token');
  const userImg = searchParams.get('img');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { context } = useContextValue();


  const onSubmit = async (e) => {
    e.preventDefault();
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        await signInWithEmailAndPassword(auth, email, password);

        // Create a new Stripe customer for this user
        const stripeResponse = await createStripeCustomer({ email: email });
        if (stripeResponse.data && stripeResponse.data.customerId) {
            const stripeCustomerId = stripeResponse.data.customerId;

            await setDoc(doc(db, 'users', userCredential.user.uid), { // Corrected the way you get user's uid
                email: email,
                name: userName,
                figmaId: userToken,
                img: userImg,
                stripeCustomerId: stripeCustomerId  // store the Stripe customer ID
            });
        } else {
            throw new Error("Failed to create Stripe customer.");
        }

		CreateNewSystem('RITkioUXdZBmVM5W2z8Z', context)

        navigate('/');

    } catch (error) {
        if (error.code === 'auth/email-already-in-use') {
            setErrorMessage('Oops! Looks like you already have an account.');
        } else {
            setErrorMessage('Something went wrong. Please try again later.');
        }
    }
};


 
  return (
    <main className='v-stack center'  style={{height: '100vh', width: '100%', backgroundImage:'url("../assets/themeable-components.jpg")', backgroundPosition: 'center center', backgroundSize: 'cover'}}>
		<Sheet/>
		<div className='sheet active'>
			<div className='background form hug' style={{background: 'rgb(var(--color-background-elevated))'}}>
				<Section>
					<VStack layout='top'>
						<Logo width='64px'/>

						<Title2>
							Let's Start
						</Title2> 
						<Body>Please answer questions below. </Body>


						{userName ? (
						<Container variant='fill'>
							<HStack layout='middle'>
								<Media 
									url={userImg} 
									profile
									size='small'
									/>
								<Headline>{userName}</Headline>
							</HStack>
						</Container>): ''}

				
						<form>
							<Label text1='Email'>
								<Container variant='outline'>
									<Input placeholder='Your email' type='email' onChange={(e) => setEmail(e.target.value)} />
								</Container>
							</Label>

							<Label text1='Password'>
								<Container variant='outline'>
									<Input placeholder='Your password' type='password' onChange={(e) => setPassword(e.target.value)} />
								</Container>
							</Label>
							<Space.Content />
							<Space.Content />
							<Button type="submit" onClick={onSubmit} variant="primary" text1='Create account'/>
						</form>

						<Space.Content />

						<Body>
							<p className="content-view text-sm text-white" style={{marginLeft: 'var(--space-external)', marginBottom: '16px'}}>
								No account yet?{' '}
								<NavLink
								to={userToken ? `/login?userName=${encodeURIComponent(userName)}&token=${encodeURIComponent(userToken)}&img=${encodeURIComponent(userImg)}` : '/login'}
								>
								<span style={{color: 'rgb(var(--color-primary))'}}>
										Sign Up
									</span>
								</NavLink>
							</p>
						</Body>

						
          


          			</VStack>
				</Section>
			</div>
		</div>
	</main>
  )     
}
 
export default Signup
import React from 'react'
import FoundationsPanel from '../../modules/FoundationsPanel'
import { Accordion, Body, Button, Caption1, Container, Divider, Footnote, Group, HStack, Headline, Icon, Input, Label, Media, Section, Space, Subhead, Switch, Tab, Tag, Title2, Title3, VStack } from '@themeable/react-components'
import { useContextValue } from '../../AppContext';
import { InputSize, InputResolution } from '../../modules/InputPixel';

function Size() {
  
	return (
    <>
	
	<VStack grow>
	{/* <Button url='https://getthemeable.com/education/how-to-create-a-size-system'>
		<Container style={{
			backgroundImage: 'url(../assets/help-articles/foundation-help-size.jpg)',
			backgroundPosition: 'center center',
			backgroundSize: 'cover',
			}}>
			<Section>
				<VStack layout='middle center'>
					<Title2 color='--color-on-primary'>
						Size
					</Title2>
					<HStack wrap>
						<Group>
							<Headline color='--color-on-primary'>
								Not sure what your settings should be? Check out this article
							</Headline>
							<Icon name='ArrowRight' color='--color-on-primary'/>
						</Group>
					</HStack>
				</VStack>
			</Section>
		</Container>
	</Button> */}

	
	<Container>
		<Section>
			<Title3>Breakpoints</Title3>
			<Footnote>When do you want your responsive sizing to switch from phone (compact) to tablet (medium) and tablet to desktop (expanded)?</Footnote>
			<HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
				<InputResolution 
					name='medium'
					label='Compact > Medium'
					/>
				<InputResolution
					name='expanded'
					label='Medium > Expanded'
					/>
			</HStack>
			<Divider />

			<InputResolution
				name='maxPageBleed'
				label='How large do you want your page margins to go?'
				/>	
		</Section>
	</Container>
		
	



    	<FoundationsPanel 
			form={<>
				<Title3>Component sizes</Title3>
				<InputSize 
					name='base'
					label='Base'
					/>
				<InputSize 
					name='small'
					label='Small'
					/>
				<InputSize 
					name='large'
					label='Large'
					/>	
			</>}
			snippet={<>
				<VStack layout='middle'>
					<Section style={{paddingTop: '0px', paddingBottom: '0px'}}>
						<Container>
							<HStack layout='left middle'>
								<Body>See more</Body>
								<Space.Grow />
								<Button variant='primary' icon1='ArrowRight'small />
							</HStack>
						</Container>
						<Button variant='primary' text1='Large Button' />
						<Button variant='primary' text1='Small Button' small />
						<Accordion 
							parentContent={<><Body>Accordion Parent</Body></>}>
							<Body>
								Accordion children
							</Body>		
						</Accordion>
					</Section>
				</VStack>

			</>}
		/>

    	


	

	</VStack>

    </>
  )
}

export default Size

const components = {
  button: {
    name: "Button",
    variants: {
      primary: {
        name: 'Primary',
        key: '9e80f3e1c219f84e71d04d5b2f55877e557d5b8c'
      },
      primarySmall: {
        name: 'Primary',
        key: '57f7614e650ad2f7c63271192b7b8cbd5d29524a'
      },
      secondary: {
        name: 'Secondary',
        key: 'e37e971c46f27762034c9bc967235ece08983ad5'
      },
      secondarySmall: {
        name: 'Secondary',
        key: '2cf09d92139a9840f3e40a87128db2075268def9'
      },
      tertiary: {
        name: 'Tertiary',
        key: '869e11f45d5d43712deafe4aef4fb25ac8b40157'
      },
      tertiarySmall: {
        name: 'Tertiary',
        key:  'bb62d94fe697489faa0281b24f9121ee0a272e42'
      },
      quaternary: {
        name: 'Quaternary',
        key: '0cd43cc1e5948cfd18d64548d913478d0402a2db'
      },
      quaternarySmall: {
        name: 'Quaternary',
        key: 'b10cb3df353dc12b23aaa79c9ed0f0e1a9aa2a73'
      },
      destructive: {
        name: "Destructive",
        key: '9c202ec41626edc817846f2e3d2521ffe8351713',
      },
      destructiveSmall: {
        name: 'Destructive',
        key: 'd29cd4aa4b625eaafc1776541a35c8635d533660',
      },          
      readOnly: {
        name: 'Read Only',
        key: '296ceda4f13fbb5cc2b73aa45d83a456249a0fb2'
      },
      readOnlySmall: {
        name: 'Read Only',
        key: 'd96cb46110b30a4805827ed3d0fbf2b682fcad40'
      },
    }
  },
  divider: {
    name: 'Divider',
    variants: {
      dividerHorizontal: {
        name: 'Horizontal',
        key: 'f17cc58dd8dbb833d6e0f9e2241ddba0d0793cba'
      },
      dividerVertical: {
        name: 'Vertical',
        key: '3306453936c4edc7fd051ddcee877adab3bb8528'
      }
    }
  },
  footnote: {
    name: 'Footnote',
    variants: {
      default: {
        name: 'Default',
        key: '5e0395a96736bee48de199b887868891884eec93',
      }
    }
  },
  inputText: {
    name: 'Text Input',
    variants: {
      cell: {
        name: 'Cell',
        key: '11c3d30a40cba5ed69d5cf730f3a87cb71e00b26'
      },
      elevated: {
        name: 'Elevated',
        key: 'c8998f575ba9bc244ea4a3634c5040508939ab83'
      },
      fill: {
        name: 'Fill',
        key: '61c0039650923c2290a504e56c1be0411f69cf6e'
      },
      outline: {
        name: 'Outline',
        key: '6d0d3486ba75cb673b0c6b09919947b7db93b158'
      }
    }
  },
  label: {
    name: 'Label',
    variants: {
      default: {
        name: 'Default',
        key: '940ccab0ab9d16ad0f3abd1d6a3737e6b1af6e5f'
      }
    }
  },
  status: {
    name: 'Status',
    variants: {
      neutral: {
        name: 'Neutral',
        key: '5259b85f80f9b7bb788b32f4ac25a670e0b59b86'
      },
      neutralSmall: {
        name: 'Neutral',
        key: '2c50a0e26aa19d86278823554535bf0dbcbd3528'
      },
      success: {
        name: 'Success',
        key: 'f7a72ec3ca4d48ea77575d5b03d3c9d8528886db'
      },
      successSmall: {
        name: 'Success',
        key: '59e2f30c83359d959f3b45bccb2435867c3eb603'
      },
      info: {
        name: 'Info',
        key: '878e536a93eb4708ce062db5cf62ce803fd7d094'
      },
      infoSmall: {
        name: 'Info',
        key: 'da623627d00baf99b32a64c7570001bf0191f005'
      },
      warning: {
        name: 'Warning',
        key: '5b90391d42e84f4795cefec7c1ba5cd2563a3d44'
      },
      warningSmall: {
        name: 'Warning',
        key: 'b3e2bfc1930917544ea3526815db8d1d527243db'
      },
      error: {
        name: 'Error',
        key: '643f4029b96e807d140024ec7ab0a7dc71c7eee7'
      },
      errorSmall: {
        name: 'Error',
        key: '6d658d59c1e7777eee55aaaa9f97db9446f5f70e'
      }
    }
  }
};

export default components;

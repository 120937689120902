import React, { useEffect, useState, useRef } from 'react';

const Sheet = ({ children, className = '' }) => {
    return (
        <div className={`sheet ${className}`}>
            {children}
        </div>
    );
};


const variantComponent = (variant) => {
    return ({ children, position = '', active: propActive = false, onDismiss, className = '' }) => {
        const [active, setActive] = useState(propActive);
        const [render, setRender] = useState(propActive);
        const alertRef = useRef(null);

        useEffect(() => {
            const handleEscapePress = (event) => {
                if (event.key === 'Escape' && active && typeof onDismiss === 'function') {
                    onDismiss();
                }
            };

            document.addEventListener('keydown', handleEscapePress);
            return () => {
                document.removeEventListener('keydown', handleEscapePress);
            };
        }, [active, onDismiss]);

        const handleOverlayClick = (event) => {
            if (event.target === event.currentTarget && typeof onDismiss === 'function') {
                onDismiss();
            }
        };

        useEffect(() => {
            if (propActive) {
                setRender(true);
                setTimeout(() => setActive(true), 50);
            } else {
                setActive(false);
                setTimeout(() => setRender(false), 500);
            }
        }, [propActive]);

        useEffect(() => {
            if (active) {
                const focusableElementsString = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [contenteditable], [tabindex]:not([tabindex="-1"])';
                const focusableElements = document.querySelectorAll(focusableElementsString);
                focusableElements.forEach(elem => {
                    if (!alertRef.current.contains(elem)) {
                        elem.setAttribute('tabindex', '-1');
                        elem.setAttribute('data-previous-tabindex', 'true');
                    }
                });
            } else {
                const previouslyFocusableElements = document.querySelectorAll('[data-previous-tabindex]');
                previouslyFocusableElements.forEach(elem => {
                    elem.removeAttribute('tabindex');
                    elem.removeAttribute('data-previous-tabindex');
                });
            }
        }, [active]);

        if (variant === 'alert' && render) {
            return (
                <div ref={alertRef} className={`overlay v-stack ${active ? 'active' : ''} ${position} ${className}`} onClick={handleOverlayClick}>
                    <div className={`sheet v-stack ${variant}`}>
                        {children}
                    </div>
                </div>
            );
        }

        return null;
    };
};

Sheet.alert = variantComponent('alert');

export default Sheet;

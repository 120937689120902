import { systemTemplates } from './SystemTemplates';
import { db, auth, functions } from '../../services/firebase';
import { collection, doc, setDoc, updateDoc, arrayUnion, deleteDoc, Timestamp, arrayRemove } from 'firebase/firestore';

import { httpsCallable } from 'firebase/functions';
const createAndUploadCSS = httpsCallable(functions, 'createAndUploadCSS');


const timestampToDate = (timestamp) => {
    // Convert seconds and nanoseconds to milliseconds
    if(timestamp) {
        const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6;
        
        // Create a new Date object for the timestamp
        const date = new Date(milliseconds);
        
        // Get the current time
        const now = new Date();
        
        // Calculate the time difference in milliseconds
        const timeDifference = now.getTime() - date.getTime();
        
        // Convert time difference to different units
        const secondsDifference = timeDifference / 1000;
        const minutesDifference = secondsDifference / 60;
        const hoursDifference = minutesDifference / 60;
        const daysDifference = hoursDifference / 24;
        
        // Format the date based on the time difference
        if (secondsDifference < 60) {
            return `a few seconds ago`;
        } else if (minutesDifference < 60) {
            return `${Math.floor(minutesDifference)}m ago`;
        } else if (hoursDifference < 24) {
            return `${Math.floor(hoursDifference)}hr ago`;
        } else if (daysDifference < 7) {
            return `${Math.floor(daysDifference)}d ago`;
        } else {
            // Get the components of the date (month, day, year)
            const month = date.getMonth() + 1; // Months are zero-based, so we add 1
            const day = date.getDate();
            const year = date.getFullYear() % 100; // Get only the last two digits of the year
            
            // Format the date as mm/dd/yy
            return `${month}/${day}/${year}`;
        }
    }
};

const CreateNewSystem = async (systemId, context) => {
    const newSystemRef = doc(collection(db, 'systems')); // Generate a new document reference
    
    let systemData = {}; // Declare as let instead of const
    
        if(systemId) {
        systemData = {
            owner: auth.currentUser.uid,
            name: `${context?.systemList[systemId]?.name} (copy)`,
            foundations: context.systemList[systemId].foundations,
            createdOn: Timestamp.now(),  // Use Timestamp.now() for lastModified field
            lastModified: Timestamp.now()
        };
    } else {
        systemData = {
            owner: auth.currentUser.uid,
            name: 'New System',
            foundations: systemTemplates.default,
            createdOn: Timestamp.now(),  // Use Timestamp.now() for lastModified field
            lastModified: Timestamp.now()
        };
    }

    return setDoc(newSystemRef, systemData)
        .then(() => {
            // Update the subscribed systems array in the user's document
            const userRef = doc(db, 'users', auth.currentUser.uid);
            return updateDoc(userRef, {
                'systems.subscribed': arrayUnion(newSystemRef.id),
            }).then(() => newSystemRef.id); // Return the new document ID
        })
        .then((newSystemId) => {
            console.log('New system created and added to user subscribed systems successfully');
            console.log(systemData); // Log systemData
            const url = `../systemid=${newSystemId}`;
            window.location.href = url; // Navigate to the new URL
            // Optionally, you can navigate to a different page or perform any other action
            return newSystemId; // Return the new document ID
        })
        .catch((error) => {
            console.error('Error creating system:', error);
            // Handle the error accordingly
            throw error; // Propagate the error further if needed
        });
};

function hexToRGB(hex) {
    // Remove the "#" character from the hex code if it's there
    hex = hex.replace(/^#/, '');

    // Parse the red, green, and blue values from the hex code
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return  `${r}, ${g}, ${b}`;
}

const AddAltThemeToDom = (altTheme, cColor, cRadius, cSpace, cSize, cText) => {

    // Deep clone the altTheme object to prevent modification
    let altThemeForDOM = JSON.parse(JSON.stringify(altTheme));

    // Function to recursively merge two objects
    function mergeObjects(target, source) {

        for (const key in source) {
            if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
                // If the key exists in both objects and is an object, merge recursively
                if (target.hasOwnProperty(key) && typeof target[key] === 'object' && !Array.isArray(target[key])) {
                    mergeObjects(target[key], source[key]);
                } else {
                    // Otherwise, assign the value directly
                    target[key] = source[key];
                }
            } else {
                // If the key exists in the target and is not blank, override
                if (target.hasOwnProperty(key) && source[key] !== "") {
                    target[key] = source[key];
                }
            }
        }
    }

    mergeObjects(altThemeForDOM.color.light, cColor.light);
    mergeObjects(altThemeForDOM.color.dark, cColor.dark);

    mergeObjects(altThemeForDOM.radius.compact, cRadius.compact);
    mergeObjects(altThemeForDOM.radius.medium, cRadius.medium);
    mergeObjects(altThemeForDOM.radius.expanded, cRadius.expanded);
    
    mergeObjects(altThemeForDOM.space.compact, cSpace.compact);
    mergeObjects(altThemeForDOM.space.medium, cSpace.medium);
    mergeObjects(altThemeForDOM.space.expanded, cSpace.expanded);
    
    mergeObjects(altThemeForDOM.size.compact, cSize.compact);
    mergeObjects(altThemeForDOM.size.medium, cSize.medium);
    mergeObjects(altThemeForDOM.size.expanded, cSize.expanded);
    mergeObjects(altThemeForDOM.size.responsive, cSize.resolution);
    
    mergeObjects(altThemeForDOM.text.weight, cText.weight);
    mergeObjects(altThemeForDOM.text.compact, cText.compact);
    mergeObjects(altThemeForDOM.text.medium, cText.medium);
    mergeObjects(altThemeForDOM.text.expanded, cText.expanded);
    mergeObjects(altThemeForDOM.text.family, cText.family);

    


    let style = document.getElementById('alt-theme');

    if (!style) {
        // Create a new style tag if it doesn't exist
        style = document.createElement('style');
        style.id = 'alt-theme';
        document.head.appendChild(style);
    }



    const colorL = altThemeForDOM.color.light
    const colorD = altThemeForDOM.color.dark
    const radius = altThemeForDOM.radius
    const size = altThemeForDOM.size
    const space = altThemeForDOM.space
    const text = altThemeForDOM.text
    const textFamily = cText?.family

    style.textContent = `
    
    @import url("${textFamily.src}");
    @import url("${textFamily.titleSrc}");
    
    :root .alt-theme {
        --ts-font-title-family: ${textFamily?.titleName !== '' ? `${textFamily?.titleName}, ` : ''} ${text.family?.titleFallback};
        --ts-font-family:  ${textFamily?.name !== '' ? `${textFamily?.name}, ` : ''} ${text?.family?.fallback};

        --ts-font-title1-light: ${text.weight.title1Light};
        --ts-font-title1-regular: ${text.weight.title1Regular};
        --ts-font-title1-bold: ${text.weight.title1Bold};
        --ts-font-title2-light: ${text.weight.title2Light};
        --ts-font-title2-regular: ${text.weight.title2Regular};
        --ts-font-title2-bold: ${text.weight.title2Bold};
        --ts-font-title3-light: ${text.weight.title3Light};
        --ts-font-title3-regular: ${text.weight.title3Regular};
        --ts-font-title3-bold: ${text.weight.title3Bold};
        --ts-font-headline-light: ${text.weight.headlineLight};
        --ts-font-headline-regular: ${text.weight.headlineRegular};
        --ts-font-headline-bold: ${text.weight.headlineBold};
        --ts-font-body-light: ${text.weight.bodyLight};
        --ts-font-body-regular: ${text.weight.bodyRegular};
        --ts-font-body-bold: ${text.weight.bodyBold};
        --ts-font-subhead-light: ${text.weight.subheadLight};
        --ts-font-subhead-regular: ${text.weight.subheadRegular};
        --ts-font-subhead-bold: ${text.weight.subheadBold};
        --ts-font-footnote-light: ${text.weight.footnoteLight};
        --ts-font-footnote-regular: ${text.weight.footnoteRegular};
        --ts-font-footnote-bold: ${text.weight.footnoteBold};
        --ts-font-caption1-light: ${text.weight.caption1Light};
        --ts-font-caption1-regular: ${text.weight.caption1Regular};
        --ts-font-caption1-bold: ${text.weight.caption1Bold};
        --ts-font-caption2-light: ${text.weight.caption2Light};
        --ts-font-caption2-regular: ${text.weight.caption2Regular};
        --ts-font-caption2-bold: ${text.weight.caption2Bold};
    }

    :root .alt-theme * {
        transition: all .4s;
    }

    @media (prefers-color-scheme: light) {
        :root .alt-theme {
            --ts-color-primary: ${hexToRGB(colorL.primary)};
            --ts-color-primary-alpha: ${colorL.primaryAlpha};
            --ts-color-on-primary: ${hexToRGB(colorL.onPrimary)};
            --ts-color-accent: ${hexToRGB(colorL.accent)};
            --ts-color-accent-alpha: ${colorL.accentAlpha};
            --ts-color-on-accent: ${hexToRGB(colorL.onAccent)};
            --ts-color-text-primary: ${hexToRGB(colorL.textPrimary)};
            --ts-color-text-primary-alpha: ${colorL.textPrimaryAlpha};
            --ts-color-text-secondary: ${hexToRGB(colorL.textSecondary)};
            --ts-color-text-secondary-alpha: ${colorL.textSecondaryAlpha};
            --ts-color-background-flat: ${hexToRGB(colorL.backgroundFlat)};
            --ts-color-background-elevated: ${hexToRGB(colorL.backgroundElevated)};
            --ts-color-surface-hover: ${hexToRGB(colorL.surfaceHover)};
            --ts-color-surface-hover-alpha: ${colorL.surfaceHoverAlpha};
            --ts-color-surface-down: ${hexToRGB(colorL.surfaceDown)};
            --ts-color-surface-down-alpha: ${colorL.surfaceDownAlpha};
            --ts-color-fill-control: ${hexToRGB(colorL.fillControl)};
            --ts-color-fill-control-alpha: ${colorL.fillControlAlpha};
            --ts-color-fill-container: ${hexToRGB(colorL.fillContainer)};
            --ts-color-fill-container-alpha: ${colorL.fillContainerAlpha};
            --ts-color-positive: ${hexToRGB(colorL.positive)};
            --ts-color-negative: ${hexToRGB(colorL.negative)};
            --ts-color-informative: ${hexToRGB(colorL.informative)};
            --ts-color-notice: ${hexToRGB(colorL.notice)};
            --ts-color-icon: ${hexToRGB(colorL.icon)};
            --ts-color-icon-alpha: ${colorL.iconAlpha};
            --ts-color-border: ${hexToRGB(colorL.border)};
            --ts-color-border-alpha: ${colorL.borderAlpha};
            --ts-color-divider: ${hexToRGB(colorL.divider)};
            --ts-color-divider-alpha: ${colorL.dividerAlpha};
            --ts-color-read-only: ${hexToRGB(colorL.readOnly)};
            --ts-color-read-only-alpha: ${colorL.readOnlyAlpha};
        }
    }
    
    @media (prefers-color-scheme: dark) {
        :root .alt-theme {
            --ts-color-primary: ${hexToRGB(colorD.primary)};
            --ts-color-primary-alpha: ${colorD.primaryAlpha};
            --ts-color-on-primary: ${hexToRGB(colorD.onPrimary)};
            --ts-color-accent: ${hexToRGB(colorD.accent)};
            --ts-color-accent-alpha: ${colorD.accentAlpha};
            --ts-color-on-accent: ${hexToRGB(colorD.onAccent)};
            --ts-color-text-primary: ${hexToRGB(colorD.textPrimary)};
            --ts-color-text-primary-alpha: ${colorD.textPrimaryAlpha};
            --ts-color-text-secondary: ${hexToRGB(colorD.textSecondary)};
            --ts-color-text-secondary-alpha: ${colorD.textSecondaryAlpha};
            --ts-color-background-flat: ${hexToRGB(colorD.backgroundFlat)};
            --ts-color-background-elevated: ${hexToRGB(colorD.backgroundElevated)};
            --ts-color-surface-hover: ${hexToRGB(colorD.surfaceHover)};
            --ts-color-surface-hover-alpha: ${colorD.surfaceHoverAlpha};
            --ts-color-surface-down: ${hexToRGB(colorD.surfaceDown)};
            --ts-color-surface-down-alpha: ${colorD.surfaceDownAlpha};
            --ts-color-fill-control: ${hexToRGB(colorD.fillControl)};
            --ts-color-fill-control-alpha: ${colorD.fillControlAlpha};
            --ts-color-fill-container: ${hexToRGB(colorD.fillContainer)};
            --ts-color-fill-container-alpha: ${colorD.fillContainerAlpha};
            --ts-color-positive: ${hexToRGB(colorD.positive)};
            --ts-color-negative: ${hexToRGB(colorD.negative)};
            --ts-color-informative: ${hexToRGB(colorD.informative)};
            --ts-color-notice: ${hexToRGB(colorD.notice)};
            --ts-color-icon: ${hexToRGB(colorD.icon)};
            --ts-color-icon-alpha: ${colorD.iconAlpha};
            --ts-color-border: ${hexToRGB(colorD.border)};
            --ts-color-border-alpha: ${colorD.borderAlpha};
            --ts-color-divider: ${hexToRGB(colorD.divider)};
            --ts-color-divider-alpha: ${colorD.dividerAlpha};
            --ts-color-read-only: ${hexToRGB(colorD.readOnly)};
            --ts-color-read-only-alpha: ${colorD.readOnlyAlpha};
        }
    }

    :root .alt-theme.light {
            --ts-color-primary: ${hexToRGB(colorL.primary)};
            --ts-color-primary-alpha: ${colorL.primaryAlpha};
            --ts-color-on-primary: ${hexToRGB(colorL.onPrimary)};
            --ts-color-accent: ${hexToRGB(colorL.accent)};
            --ts-color-accent-alpha: ${colorL.accentAlpha};
            --ts-color-on-accent: ${hexToRGB(colorL.onAccent)};
            --ts-color-text-primary: ${hexToRGB(colorL.textPrimary)};
            --ts-color-text-primary-alpha: ${colorL.textPrimaryAlpha};
            --ts-color-text-secondary: ${hexToRGB(colorL.textSecondary)};
            --ts-color-text-secondary-alpha: ${colorL.textSecondaryAlpha};
            --ts-color-background-flat: ${hexToRGB(colorL.backgroundFlat)};
            --ts-color-background-elevated: ${hexToRGB(colorL.backgroundElevated)};
            --ts-color-surface-hover: ${hexToRGB(colorL.surfaceHover)};
            --ts-color-surface-hover-alpha: ${colorL.surfaceHoverAlpha};
            --ts-color-surface-down: ${hexToRGB(colorL.surfaceDown)};
            --ts-color-surface-down-alpha: ${colorL.surfaceDownAlpha};
            --ts-color-fill-control: ${hexToRGB(colorL.fillControl)};
            --ts-color-fill-control-alpha: ${colorL.fillControlAlpha};
            --ts-color-fill-container: ${hexToRGB(colorL.fillContainer)};
            --ts-color-fill-container-alpha: ${colorL.fillContainerAlpha};
            --ts-color-positive: ${hexToRGB(colorL.positive)};
            --ts-color-negative: ${hexToRGB(colorL.negative)};
            --ts-color-informative: ${hexToRGB(colorL.informative)};
            --ts-color-notice: ${hexToRGB(colorL.notice)};
            --ts-color-icon: ${hexToRGB(colorL.icon)};
            --ts-color-icon-alpha: ${colorL.iconAlpha};
            --ts-color-border: ${hexToRGB(colorL.border)};
            --ts-color-border-alpha: ${colorL.borderAlpha};
            --ts-color-divider: ${hexToRGB(colorL.divider)};
            --ts-color-divider-alpha: ${colorL.dividerAlpha};
            --ts-color-read-only: ${hexToRGB(colorL.readOnly)};
            --ts-color-read-only-alpha: ${colorL.readOnlyAlpha};
        }
        :root .alt-theme.dark {
            --ts-color-primary: ${hexToRGB(colorD.primary)};
            --ts-color-primary-alpha: ${colorD.primaryAlpha};
            --ts-color-on-primary: ${hexToRGB(colorD.onPrimary)};
            --ts-color-accent: ${hexToRGB(colorD.accent)};
            --ts-color-accent-alpha: ${colorD.accentAlpha};
            --ts-color-on-accent: ${hexToRGB(colorD.onAccent)};
            --ts-color-text-primary: ${hexToRGB(colorD.textPrimary)};
            --ts-color-text-primary-alpha: ${colorD.textPrimaryAlpha};
            --ts-color-text-secondary: ${hexToRGB(colorD.textSecondary)};
            --ts-color-text-secondary-alpha: ${colorD.textSecondaryAlpha};
            --ts-color-background-flat: ${hexToRGB(colorD.backgroundFlat)};
            --ts-color-background-elevated: ${hexToRGB(colorD.backgroundElevated)};
            --ts-color-surface-hover: ${hexToRGB(colorD.surfaceHover)};
            --ts-color-surface-hover-alpha: ${colorD.surfaceHoverAlpha};
            --ts-color-surface-down: ${hexToRGB(colorD.surfaceDown)};
            --ts-color-surface-down-alpha: ${colorD.surfaceDownAlpha};
            --ts-color-fill-control: ${hexToRGB(colorD.fillControl)};
            --ts-color-fill-control-alpha: ${colorD.fillControlAlpha};
            --ts-color-fill-container: ${hexToRGB(colorD.fillContainer)};
            --ts-color-fill-container-alpha: ${colorD.fillContainerAlpha};
            --ts-color-positive: ${hexToRGB(colorD.positive)};
            --ts-color-negative: ${hexToRGB(colorD.negative)};
            --ts-color-informative: ${hexToRGB(colorD.informative)};
            --ts-color-notice: ${hexToRGB(colorD.notice)};
            --ts-color-icon: ${hexToRGB(colorD.icon)};
            --ts-color-icon-alpha: ${colorD.iconAlpha};
            --ts-color-border: ${hexToRGB(colorD.border)};
            --ts-color-border-alpha: ${colorD.borderAlpha};
            --ts-color-divider: ${hexToRGB(colorD.divider)};
            --ts-color-divider-alpha: ${colorD.dividerAlpha};
            --ts-color-read-only: ${hexToRGB(colorD.readOnly)};
            --ts-color-read-only-alpha: ${colorD.readOnlyAlpha};
        }
        
        @media screen and (max-width: 720px){
            :root .alt-theme {
                --ts-space-v: ${parseInt(space.compact.vExtra) + parseInt(space.compact.vUnify)}px;
                --ts-space-h: ${parseInt(space.compact.hExtra) + parseInt(space.compact.hUnify)}px;
                --ts-space-v-small: ${space.compact.vSmall}px;
                --ts-space-v-extra: ${parseInt(space.compact.vExtra)}px;
                --ts-space-v-section: ${space.compact.vSection}px;
                --ts-space-v-unify: ${parseInt(space.compact.vUnify)}px;
                --ts-space-h-section: ${space.compact.hSection}px;
                --ts-space-h-small: ${space.compact.hSmall}px;
                --ts-space-h-extra: ${space.compact.hExtra}px;
                --ts-space-h-unify: ${space.compact.hUnify}px;
                --ts-space-content: ${space.compact.content}px;
                --ts-size-base: ${size.compact.base}px;
                --ts-size-small: ${size.compact.small}px;
                --ts-size-large: ${size.compact.large}px;
                --ts-radius-small-control: ${radius.compact.smallControl}px;
                --ts-radius-large-control: ${radius.compact.largeControl}px;
                --ts-radius-container: ${radius.compact.container}px;
                --ts-radius-page: ${radius.compact.page}px;
                --ts-font-title1: ${text.weight.title1} ${text.compact.title1Size / 16}rem/${text.compact.title1LineHeight / 16}rem var(--ts-font-title-family);
                --ts-font-title1-letter-spacing: ${text.compact.title1LetterSpacing}em;
                --ts-font-title2: ${text.weight.title2} ${text.compact.title2Size / 16}rem/${text.compact.title2LineHeight / 16}rem var(--ts-font-title-family);
                --ts-font-title2-letter-spacing: ${text.compact.title2LetterSpacing}em;
                --ts-font-title3: ${text.weight.title3} ${text.compact.title3Size / 16}rem/${text.compact.title3LineHeight / 16}rem var(--ts-font-title-family);
                --ts-font-title3-letter-spacing: ${text.compact.title3LetterSpacing}em;
                --ts-font-headline: ${text.weight.headline} ${text.compact.headlineSize / 16}rem/${text.compact.headlineLineHeight / 16}rem var(--ts-font-family);
                --ts-font-headline-letter-spacing: ${text.compact.headlineLetterSpacing}em;
                --ts-font-body: ${text.weight.body} ${text.compact.bodySize / 16}rem/${text.compact.bodyLineHeight / 16}rem var(--ts-font-family);
                --ts-font-body-letter-spacing: ${text.compact.bodyLetterSpacing}em;
                --ts-font-subhead: ${text.weight.subhead} ${text.compact.subheadSize / 16}rem/${text.compact.subheadLineHeight / 16}rem var(--ts-font-family);
                --ts-font-subhead-letter-spacing: ${text.compact.subheadLetterSpacing}em;
                --ts-font-footnote: ${text.weight.footnote} ${text.compact.footnoteSize / 16}rem/${text.compact.footnoteLineHeight / 16}rem var(--ts-font-family);
                --ts-font-footnote-letter-spacing: ${text.compact.footnoteLetterSpacing}em;
                --ts-font-caption1: ${text.weight.caption1} ${text.compact.caption1Size / 16}rem/${text.compact.caption1LineHeight / 16}rem var(--ts-font-family);
                --ts-font-caption1-letter-spacing: ${text.compact.caption1LetterSpacing}em;
                --ts-font-caption2: ${text.weight.caption2} ${text.compact.caption2Size / 16}rem/${text.compact.caption2LineHeight / 16}rem var(--ts-font-family);
                --ts-font-caption2-letter-spacing: ${text.compact.caption2LetterSpacing}em;
            }
        }
        :root .alt-theme.compact {
            --ts-space-v: ${parseInt(space.compact.vExtra) + parseInt(space.compact.vUnify)}px;
            --ts-space-h: ${parseInt(space.compact.hExtra) + parseInt(space.compact.hUnify)}px;
            --ts-space-v-small: ${space.compact.vSmall}px;
            --ts-space-v-extra: ${parseInt(space.compact.vExtra)}px;
            --ts-space-v-section: ${space.compact.vSection}px;
            --ts-space-v-unify: ${parseInt(space.compact.vUnify)}px;
            --ts-space-h-section: ${space.compact.hSection}px;
            --ts-space-h-small: ${space.compact.hSmall}px;
            --ts-space-h-extra: ${space.compact.hExtra}px;
            --ts-space-h-unify: ${space.compact.hUnify}px;
            --ts-space-content: ${space.compact.content}px;
            --ts-size-base: ${size.compact.base}px;
            --ts-size-small: ${size.compact.small}px;
            --ts-size-large: ${size.compact.large}px;
            --ts-radius-small-control: ${radius.compact.smallControl}px;
            --ts-radius-large-control: ${radius.compact.largeControl}px;
            --ts-radius-container: ${radius.compact.container}px;
            --ts-radius-page: ${radius.compact.page}px;
            --ts-font-title1: ${text.weight.title1} ${text.compact.title1Size / 16}rem/${text.compact.title1LineHeight / 16}rem var(--ts-font-title-family);
            --ts-font-title1-letter-spacing: ${text.compact.title1LetterSpacing}em;
            --ts-font-title2: ${text.weight.title2} ${text.compact.title2Size / 16}rem/${text.compact.title2LineHeight / 16}rem var(--ts-font-title-family);
            --ts-font-title2-letter-spacing: ${text.compact.title2LetterSpacing}em;
            --ts-font-title3: ${text.weight.title3} ${text.compact.title3Size / 16}rem/${text.compact.title3LineHeight / 16}rem var(--ts-font-title-family);
            --ts-font-title3-letter-spacing: ${text.compact.title3LetterSpacing}em;
            --ts-font-headline: ${text.weight.headline} ${text.compact.headlineSize / 16}rem/${text.compact.headlineLineHeight / 16}rem var(--ts-font-family);
            --ts-font-headline-letter-spacing: ${text.compact.headlineLetterSpacing}em;
            --ts-font-body: ${text.weight.body} ${text.compact.bodySize / 16}rem/${text.compact.bodyLineHeight / 16}rem var(--ts-font-family);
            --ts-font-body-letter-spacing: ${text.compact.bodyLetterSpacing}em;
            --ts-font-subhead: ${text.weight.subhead} ${text.compact.subheadSize / 16}rem/${text.compact.subheadLineHeight / 16}rem var(--ts-font-family);
            --ts-font-subhead-letter-spacing: ${text.compact.subheadLetterSpacing}em;
            --ts-font-footnote: ${text.weight.footnote} ${text.compact.footnoteSize / 16}rem/${text.compact.footnoteLineHeight / 16}rem var(--ts-font-family);
            --ts-font-footnote-letter-spacing: ${text.compact.footnoteLetterSpacing}em;
            --ts-font-caption1: ${text.weight.caption1} ${text.compact.caption1Size / 16}rem/${text.compact.caption1LineHeight / 16}rem var(--ts-font-family);
            --ts-font-caption1-letter-spacing: ${text.compact.caption1LetterSpacing}em;
            --ts-font-caption2: ${text.weight.caption2} ${text.compact.caption2Size / 16}rem/${text.compact.caption2LineHeight / 16}rem var(--ts-font-family);
            --ts-font-caption2-letter-spacing: ${text.compact.caption2LetterSpacing}em;
        }
        @media screen and (min-width: 721px) and (max-width: 1200px){
            :root .alt-theme {
                --ts-space-v: ${parseInt(space.medium.vExtra) + parseInt(space.medium.vUnify)}px;
                --ts-space-v-small: ${space.medium.vSmall}px;
                --ts-space-v-extra: ${parseInt(space.medium.vExtra)}px;
                --ts-space-v-section: ${space.medium.vSection}px;
                --ts-space-v-unify: ${parseInt(space.medium.vUnify)}px;
                --ts-space-h: ${parseInt(space.medium.hExtra) + parseInt(space.medium.hUnify)}px;
                --ts-space-h-section: ${space.medium.hSection}px;
                --ts-space-h-small: ${space.medium.hSmall}px;
                --ts-space-h-extra: ${space.medium.hExtra}px;
                --ts-space-h-unify: ${space.medium.hUnify}px;
                --ts-space-content: ${space.medium.content}px;
                --ts-size-base: ${size.medium.base}px;
                --ts-size-small: ${size.medium.small}px;
                --ts-size-large: ${size.medium.large}px;
                --ts-radius-small-control: ${radius.medium.smallControl}px;
                --ts-radius-large-control: ${radius.medium.largeControl}px;
                --ts-radius-container: ${radius.medium.container}px;
                --ts-radius-page: ${radius.medium.page}px;
                --ts-font-title1: ${text.weight.title1} ${text.medium.title1Size / 16}rem/${text.medium.title1LineHeight / 16}rem var(--ts-font-title-family);
                --ts-font-title1-letter-spacing: ${text.medium.title1LetterSpacing}em;
                --ts-font-title2: ${text.weight.title2} ${text.medium.title2Size / 16}rem/${text.medium.title2LineHeight / 16}rem var(--ts-font-title-family);
                --ts-font-title2-letter-spacing: ${text.medium.title2LetterSpacing}em;
                --ts-font-title3: ${text.weight.title3} ${text.medium.title3Size / 16}rem/${text.medium.title3LineHeight / 16}rem var(--ts-font-title-family);
                --ts-font-title3-letter-spacing: ${text.medium.title3LetterSpacing}em;
                --ts-font-headline: ${text.weight.headline} ${text.medium.headlineSize / 16}rem/${text.medium.headlineLineHeight / 16}rem var(--ts-font-family);
                --ts-font-headline-letter-spacing: ${text.medium.headlineLetterSpacing}em;
                --ts-font-body: ${text.weight.body} ${text.medium.bodySize / 16}rem/${text.medium.bodyLineHeight / 16}rem var(--ts-font-family);
                --ts-font-body-letter-spacing: ${text.medium.bodyLetterSpacing}em;
                --ts-font-subhead: ${text.weight.subhead} ${text.medium.subheadSize / 16}rem/${text.medium.subheadLineHeight / 16}rem var(--ts-font-family);
                --ts-font-subhead-letter-spacing: ${text.medium.subheadLetterSpacing}em;
                --ts-font-footnote: ${text.weight.footnote} ${text.medium.footnoteSize / 16}rem/${text.medium.footnoteLineHeight / 16}rem var(--ts-font-family);
                --ts-font-footnote-letter-spacing: ${text.medium.footnoteLetterSpacing}em;
                --ts-font-caption1: ${text.weight.caption1} ${text.medium.caption1Size / 16}rem/${text.medium.caption1LineHeight / 16}rem var(--ts-font-family);
                --ts-font-caption1-letter-spacing: ${text.medium.caption1LetterSpacing}em;
                --ts-font-caption2: ${text.weight.caption2} ${text.medium.caption2Size / 16}rem/${text.medium.caption2LineHeight / 16}rem var(--ts-font-family);
                --ts-font-caption2-letter-spacing: ${text.medium.caption2LetterSpacing}em;
            }
        }
        :root .alt-theme.medium {
            --ts-space-v: ${parseInt(space.medium.vExtra) + parseInt(space.medium.vUnify)}px;
            --ts-space-v-small: ${space.medium.vSmall}px;
            --ts-space-v-extra: ${parseInt(space.medium.vExtra)}px;
            --ts-space-v-unify: ${parseInt(space.medium.vUnify)}px;
            --ts-space-v-section: ${space.medium.vSection}px;
            --ts-space-h: ${parseInt(space.medium.hExtra) + parseInt(space.medium.hUnify)}px;
            --ts-space-h-section: ${space.medium.hSection}px;
            --ts-space-h-small: ${space.medium.hSmall}px;
            --ts-space-h-extra: ${space.medium.hExtra}px;
            --ts-space-h-unify: ${space.medium.hUnify}px;
            --ts-space-content: ${space.medium.content}px;
            --ts-size-base: ${size.medium.base}px;
            --ts-size-small: ${size.medium.small}px;
            --ts-size-large: ${size.medium.large}px;
            --ts-radius-small-control: ${radius.medium.smallControl}px;
            --ts-radius-large-control: ${radius.medium.largeControl}px;
            --ts-radius-container: ${radius.medium.container}px;
            --ts-radius-page: ${radius.medium.page}px;
            --ts-font-title1: ${text.weight.title1} ${text.medium.title1Size / 16}rem/${text.medium.title1LineHeight / 16}rem var(--ts-font-title-family);
            --ts-font-title1-letter-spacing: ${text.medium.title1LetterSpacing}em;
            --ts-font-title2: ${text.weight.title2} ${text.medium.title2Size / 16}rem/${text.medium.title2LineHeight / 16}rem var(--ts-font-title-family);
            --ts-font-title2-letter-spacing: ${text.medium.title2LetterSpacing}em;
            --ts-font-title3: ${text.weight.title3} ${text.medium.title3Size / 16}rem/${text.medium.title3LineHeight / 16}rem var(--ts-font-title-family);
            --ts-font-title3-letter-spacing: ${text.medium.title3LetterSpacing}em;
            --ts-font-headline: ${text.weight.headline} ${text.medium.headlineSize / 16}rem/${text.medium.headlineLineHeight / 16}rem var(--ts-font-family);
            --ts-font-headline-letter-spacing: ${text.medium.headlineLetterSpacing}em;
            --ts-font-body: ${text.weight.body} ${text.medium.bodySize / 16}rem/${text.medium.bodyLineHeight / 16}rem var(--ts-font-family);
            --ts-font-body-letter-spacing: ${text.medium.bodyLetterSpacing}em;
            --ts-font-subhead: ${text.weight.subhead} ${text.medium.subheadSize / 16}rem/${text.medium.subheadLineHeight / 16}rem var(--ts-font-family);
            --ts-font-subhead-letter-spacing: ${text.medium.subheadLetterSpacing}em;
            --ts-font-footnote: ${text.weight.footnote} ${text.medium.footnoteSize / 16}rem/${text.medium.footnoteLineHeight / 16}rem var(--ts-font-family);
            --ts-font-footnote-letter-spacing: ${text.medium.footnoteLetterSpacing}em;
            --ts-font-caption1: ${text.weight.caption1} ${text.medium.caption1Size / 16}rem/${text.medium.caption1LineHeight / 16}rem var(--ts-font-family);
            --ts-font-caption1-letter-spacing: ${text.medium.caption1LetterSpacing}em;
            --ts-font-caption2: ${text.weight.caption2} ${text.medium.caption2Size / 16}rem/${text.medium.caption2LineHeight / 16}rem var(--ts-font-family);
            --ts-font-caption2-letter-spacing: ${text.medium.caption2LetterSpacing}em;
        }
        @media screen and (min-width: 1201px){
            :root .alt-theme {
                --ts-space-v: ${parseInt(space.expanded.vExtra) + parseInt(space.expanded.vUnify)}px;
                --ts-space-v-small: ${space.expanded.vSmall}px;
                --ts-space-v-extra: ${parseInt(space.expanded.vExtra)}px;
                --ts-space-v-section: ${space.expanded.vSection}px;
                --ts-space-v-unify: ${parseInt(space.expanded.vUnify)}px;
                --ts-space-h: ${parseInt(space.expanded.hExtra) + parseInt(space.expanded.hUnify)}px;
                --ts-space-h-section: ${space.expanded.hSection}px;
                --ts-space-h-small: ${space.expanded.hSmall}px;
                --ts-space-h-extra: ${space.expanded.hExtra}px;
                --ts-space-h-unify: ${space.expanded.hUnify}px;
                --ts-space-content: ${space.expanded.content}px;
                --ts-size-base: ${size.expanded.base}px;
                --ts-size-small: ${size.expanded.small}px;
                --ts-size-large: ${size.expanded.large}px;
                --ts-radius-small-control: ${radius.expanded.smallControl}px;
                --ts-radius-large-control: ${radius.expanded.largeControl}px;
                --ts-radius-container: ${radius.expanded.container}px;
                --ts-radius-page: ${radius.expanded.page}px;
                --ts-font-title1: ${text.weight.title1} ${text.expanded.title1Size / 16}rem/${text.expanded.title1LineHeight / 16}rem var(--ts-font-title-family);
                --ts-font-title1-letter-spacing: ${text.expanded.title1LetterSpacing}em;
                --ts-font-title2: ${text.weight.title2} ${text.expanded.title2Size / 16}rem/${text.expanded.title2LineHeight / 16}rem var(--ts-font-title-family);
                --ts-font-title2-letter-spacing: ${text.expanded.title2LetterSpacing}em;
                --ts-font-title3: ${text.weight.title3} ${text.expanded.title3Size / 16}rem/${text.expanded.title3LineHeight / 16}rem var(--ts-font-title-family);
                --ts-font-title3-letter-spacing: ${text.expanded.title3LetterSpacing}em;
                --ts-font-headline: ${text.weight.headline} ${text.expanded.headlineSize / 16}rem/${text.expanded.headlineLineHeight / 16}rem var(--ts-font-family);
                --ts-font-headline-letter-spacing: ${text.expanded.headlineLetterSpacing}em;
                --ts-font-body: ${text.weight.body} ${text.expanded.bodySize / 16}rem/${text.expanded.bodyLineHeight / 16}rem var(--ts-font-family);
                --ts-font-body-letter-spacing: ${text.expanded.bodyLetterSpacing}em;
                --ts-font-subhead: ${text.weight.subhead} ${text.expanded.subheadSize / 16}rem/${text.expanded.subheadLineHeight / 16}rem var(--ts-font-family);
                --ts-font-subhead-letter-spacing: ${text.expanded.subheadLetterSpacing}em;
                --ts-font-footnote: ${text.weight.footnote} ${text.expanded.footnoteSize / 16}rem/${text.expanded.footnoteLineHeight / 16}rem var(--ts-font-family);
                --ts-font-footnote-letter-spacing: ${text.expanded.footnoteLetterSpacing}em;
                --ts-font-caption1: ${text.weight.caption1} ${text.expanded.caption1Size / 16}rem/${text.expanded.caption1LineHeight / 16}rem var(--ts-font-family);
                --ts-font-caption1-letter-spacing: ${text.expanded.caption1LetterSpacing}em;
                --ts-font-caption2: ${text.weight.caption2} ${text.expanded.caption2Size / 16}rem/${text.expanded.caption2LineHeight / 16}rem var(--ts-font-family);
                --ts-font-caption2-letter-spacing: ${text.expanded.caption2LetterSpacing}em;
            }
        }
        :root .alt-theme.expanded {
            --ts-space-v: ${parseInt(space.expanded.vExtra) + parseInt(space.expanded.vUnify)}px;
            --ts-space-h: ${parseInt(space.expanded.hExtra) + parseInt(space.expanded.hUnify)}px;
            --ts-space-v-small: ${space.expanded.vSmall}px;
            --ts-space-v-extra: ${parseInt(space.expanded.vExtra)}px;
            --ts-space-v-section: ${space.expanded.vSection}px;
            --ts-space-v-unify: ${parseInt(space.expanded.vUnify)}px;
            --ts-space-h-section: ${space.expanded.hSection}px;
            --ts-space-h-small: ${space.expanded.hSmall}px;
            --ts-space-h-extra: ${space.expanded.hExtra}px;
            --ts-space-h-unify: ${space.expanded.hUnify}px;
            --ts-space-content: ${space.expanded.content}px;
            --ts-size-base: ${size.expanded.base}px;
            --ts-size-small: ${size.expanded.small}px;
            --ts-size-large: ${size.expanded.large}px;
            --ts-radius-small-control: ${radius.expanded.smallControl}px;
            --ts-radius-large-control: ${radius.expanded.largeControl}px;
            --ts-radius-container: ${radius.expanded.container}px;
            --ts-radius-page: ${radius.expanded.page}px;
            --ts-font-title1: ${text.weight.title1} ${text.expanded.title1Size / 16}rem/${text.expanded.title1LineHeight / 16}rem var(--ts-font-title-family);
            --ts-font-title1-letter-spacing: ${text.expanded.title1LetterSpacing}em;
            --ts-font-title2: ${text.weight.title2} ${text.expanded.title2Size / 16}rem/${text.expanded.title2LineHeight / 16}rem var(--ts-font-title-family);
            --ts-font-title2-letter-spacing: ${text.expanded.title2LetterSpacing}em;
            --ts-font-title3: ${text.weight.title3} ${text.expanded.title3Size / 16}rem/${text.expanded.title3LineHeight / 16}rem var(--ts-font-title-family);
            --ts-font-title3-letter-spacing: ${text.expanded.title3LetterSpacing}em;
            --ts-font-headline: ${text.weight.headline} ${text.expanded.headlineSize / 16}rem/${text.expanded.headlineLineHeight / 16}rem var(--ts-font-family);
            --ts-font-headline-letter-spacing: ${text.expanded.headlineLetterSpacing}em;
            --ts-font-body: ${text.weight.body} ${text.expanded.bodySize / 16}rem/${text.expanded.bodyLineHeight / 16}rem var(--ts-font-family);
            --ts-font-body-letter-spacing: ${text.expanded.bodyLetterSpacing}em;
            --ts-font-subhead: ${text.weight.subhead} ${text.expanded.subheadSize / 16}rem/${text.expanded.subheadLineHeight / 16}rem var(--ts-font-family);
            --ts-font-subhead-letter-spacing: ${text.expanded.subheadLetterSpacing}em;
            --ts-font-footnote: ${text.weight.footnote} ${text.expanded.footnoteSize / 16}rem/${text.expanded.footnoteLineHeight / 16}rem var(--ts-font-family);
            --ts-font-footnote-letter-spacing: ${text.expanded.footnoteLetterSpacing}em;
            --ts-font-caption1: ${text.weight.caption1} ${text.expanded.caption1Size / 16}rem/${text.expanded.caption1LineHeight / 16}rem var(--ts-font-family);
            --ts-font-caption1-letter-spacing: ${text.expanded.caption1LetterSpacing}em;
            --ts-font-caption2: ${text.weight.caption2} ${text.expanded.caption2Size / 16}rem/${text.expanded.caption2LineHeight / 16}rem var(--ts-font-family);
            --ts-font-caption2-letter-spacing: ${text.expanded.caption2LetterSpacing}em;
        }
    }
    `;

    
    document.head.appendChild(style);
    
}

const SaveData = async (cColor, cRadius, cSpace, cSize, cText, context) => {
    try {
        // Construct the data to save
        const dataToSave = {
            cColor: cColor,
            cRadius: cRadius,
            cSpace: cSpace,
            cSize: cSize,
            cText: cText
        };

        // Get a reference to the system document
        const systemRef = doc(collection(db, 'systems'), context.selectedSystem);

        // Set the data in the "staging" collection under the specified system
        const stagingRef = doc(collection(systemRef, 'staging'), 'latest');
        await setDoc(stagingRef, dataToSave);

        console.log('Data saved successfully to Firestore');
    } catch (error) {
        console.error('Error saving data:', error);
        // Handle error
    }
};

const CalculateChanges = (cColor, cRadius, cSpace, cSize, cText, context) => {
    
    cText = cText || {};
    cText.weight = cText.weight || {};
    cText.compact = cText.compact || {};
    cText.medium = cText.medium || {};
    cText.expanded = cText.expanded || {};
    cText.family = cText.family || {};

    const cleanObject = (obj) => {
        let cleaned = {};
        let numberOfChanges = 0;

        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    const nested = cleanObject(obj[key]);
                    if (Object.keys(nested.cleaned).length !== 0) {
                        cleaned[key] = nested.cleaned;
                        numberOfChanges += nested.numberOfChanges;
                    }
                } else if (obj[key] !== null && obj[key] !== '') {
                    cleaned[key] = obj[key];
                    numberOfChanges++;
                }
            }
        }
        return { cleaned, numberOfChanges };
    };

    const cleanText = (cText, context) => {
        // Initialize a cleaned object
        let cleaned = {};
    
        // Check if cText and context are defined and have the family property
        const systemContext = context.systemList[context.selectedSystem].foundations.text;
        
        // Check for differences in the 'family' property
        cleaned.family = {};
        for (const familyValue in cText.family) {
            if (cText.family[familyValue] !== systemContext.family[familyValue]) {
                // If the values are different, add them to the cleaned object
                cleaned.family[familyValue] = cText.family[familyValue];
            }
        }
    
        // Check for differences in other properties and exclude properties without any non-empty children
        for (const key in cText) {
            if (key !== 'family' && Object.keys(cText[key]).some(prop => cText[key][prop] !== '')) {
                cleaned[key] = {};
                for (const otherTextProperties in cText[key]) {
                    if (cText[key][otherTextProperties] !== systemContext[key][otherTextProperties]) {
                        cleaned[key][otherTextProperties] = cText[key][otherTextProperties];
                    }
                }
            }
        }
    
        return cleaned;
    };
    
    
    
        
    
    

    const countFields = (obj) => {
        let count = 0;
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    count += countFields(obj[key]);
                } else {
                    count++;
                }
            }
        }
        return count;
    };

    const cleanedColor = cleanObject(cColor);
    const cleanedRadius = cleanObject(cRadius);
    const cleanedSpace = cleanObject(cSpace);
    const cleanedSize = cleanObject(cSize);
    const cleanedText = cleanText(cText, context);
    const textChanges = countFields(cleanedText);


    const totalChanges =
        cleanedColor.numberOfChanges +
        cleanedRadius.numberOfChanges +
        cleanedSpace.numberOfChanges +
        cleanedSize.numberOfChanges +
        textChanges;

    // Handle case when cleanedText is empty
    const text = textChanges > 0 ? cleanedText : {};

    return {
        total: totalChanges,
        color: cleanedColor.cleaned,
        radius: cleanedRadius.cleaned,
        space: cleanedSpace.cleaned,
        size: cleanedSize.cleaned,
        text: text,
        textChanges: textChanges
    };
};

const PublishUpdate = async ( setContext, context, color, radius, space, size, text, setPublishState) => {
    
    const foundations = {};
    setPublishState('publishing');
    
    const filterForData = (element, category) => {
        const filteredData = {};
    
        const filterObject = (obj) => {
            const filteredObj = {};
            for (const key in obj) {
                const value = obj[key];
                if ((typeof value === 'object' && Object.keys(value).length > 0) || (typeof value !== 'object' && value !== '')) {
                    if (typeof value === 'object') {
                        const filteredValue = filterObject(value);
                        if (Object.keys(filteredValue).length > 0) {
                            filteredObj[key] = filteredValue;
                        }
                    } else {
                        filteredObj[key] = value;
                    }
                }
            }
            return filteredObj;
        };
    
        // Call filterObject for nested objects
        filteredData[category] = filterObject(element);
    
        // If there's any data after filtering, add it to foundations under the respective category
        if (Object.keys(filteredData[category]).length > 0) {
            foundations[category] = filteredData[category];
        }
    };

    filterForData(color, 'color');
    filterForData(radius, 'radius');
    filterForData(space, 'space');
    filterForData(size, 'size');
    filterForData(text, 'text');

    foundations.text.family = text.family;


    const dataToUpdate = {
        foundations
    }

    try {        
        // Get a reference to the system document within "systems" collection
        const systemRef = doc(collection(db, 'systems'), context.selectedSystem);


        // Update the data in the specified system document, merging it with existing data
        await setDoc(systemRef, dataToUpdate, { merge: true });

        // Delete the "latest" document inside the "staging" subcollection
        const stagingLatestRef = doc(collection(systemRef, 'staging'), 'latest');
        await deleteDoc(stagingLatestRef);

        const res = await createAndUploadCSS({ 
			css: {
			systemId: context.selectedSystem,
			foundations: context.systemList[context.selectedSystem].foundations,
			} 
      	});

        console.log(res.data);

        setPublishState('published')
    } catch (error) {
        console.error('Error updating data:', error);
        // Handle error
    }
}

const DeleteSystem = async (context) => {
    console.log('deeeeleeeting!')

    try {
        // Delete the document from the "systems" collection
        const systemDocRef = doc(db, 'systems', context.selectedSystem);
        await deleteDoc(systemDocRef);

        // Remove the system from the user's subscribed systems array
        const userRef = doc(db, 'users', auth.currentUser.uid);
        await updateDoc(userRef, {
            'systems.subscribed': arrayRemove(context.selectedSystem),
        });

        console.log('System deleted successfully');

        window.location.href = '/';
        return true; // Indicate successful deletion
    } catch (error) {
        console.error('Error deleting system:', error);
        // Handle the error accordingly
        throw error; // Propagate the error further if needed
    }
};



export { CreateNewSystem, AddAltThemeToDom, SaveData, CalculateChanges, PublishUpdate, DeleteSystem, timestampToDate };

import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { auth } from "../../services/firebase";

const PrivateRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user); // Check if user is authenticated
      setIsLoading(false); // Set loading state to false once authentication state is resolved
    });

    return () => unsubscribe(); // Cleanup the subscription when the component unmounts
  }, []);

  if (isLoading) {
    return // <div>Loading...</div>; // Show a loading indicator while authentication state is being resolved
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />; // Redirect to login page if user is not authenticated
  }

  return children; // Render the protected content if user is authenticated
};

export default PrivateRoute;

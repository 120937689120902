import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Logo, LogoHorizontal, LogoVertical } from './Logo';
import { signOut } from "firebase/auth";
import { auth, db, functions } from '../services/firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import Container from './Container';
import { handleStripeSession, handleCheckout } from '../services/stripe';
import { Button, Tab, Space, Popover, Section, Media, HStack, Cell, VStack, Body, Icon, Loader, Footnote, Title3 } from '@themeable/react-components';
import { CreateNewSystem } from '../page/Utilities/UtilityFunctions';

const GlobalNav = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const childrenRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [loadingStripe, setLoadingStripe] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // const scrollY = window.scrollY;
      // const childrenTop = childrenRef.current.offsetTop;
      // setIsSticky(scrollY >= childrenTop);

    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [children]);

  const stick = isSticky ? 'stick' : '';


  
  const userInfo = async () => {
    const user = auth.currentUser;
    const userDocRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userDocRef);
    
    if (userDoc.exists()) {
      setUserImage(userDoc.data().img);
      setSubscriptionStatus(userDoc.data().subscription)
    }
  };

  useEffect(() => {
    userInfo();
  }, []); 

  const onLogout = () => {
    signOut(auth).then(() => {
      navigate('/login')
    }).catch((error) => {
    });
  }

  const handlePortalSession = async () => {
	setLoadingStripe(true)
    const error = await handleStripeSession();
    if (error) {
        setErrorMessage(error);
		setLoadingStripe(false)
    }
  }

  const handlePaymentCheckout = async (priceId) => {
    const error = await handleCheckout(priceId);
    if (error) {
        setErrorMessage(error);
    }
  }

  return (
    <nav className={`nav ${stick}`}>
      <Section style={{paddingBottom: '0px', paddingTop: '0px'}}>

			<HStack layout='bottom'>
				
				{/* <HStack showOn='mobile' grow>
					<Popover trigger={<Button variant='quaternary' icon1='Hamburger' />}>
						<ButtonCell onClick={onLogout} contentView={'Log out'}/>
					</Popover>
				</HStack> */}

				{/* <HStack showOn='mobile'>
					<Button url='../'>
						<Logo/>
					</Button>
				</HStack> */}

				<Button url='../'>
					<HStack showOn='tablet desktop'>
						<LogoHorizontal/>
					</HStack>
					<HStack showOn='mobile'>
						<Logo />
					</HStack>
				</Button>

				<HStack grow layout='right middle'>
					{/* <Tab small active={location.pathname === '/'} text1='Home' url='../' indicator='bottom'/>
					<Tab small active={location.pathname === '/roadmap'} text1='Roadmap' url='../roadmap' indicator='bottom'/>
					<Tab small active={location.pathname === '/documentation'} text1='Documentation' url='../documentation' indicator='bottom'/> */}
					<Button text1='Create system' icon1='PlusCircle' small onClick={() => {CreateNewSystem()}}/>
					<Popover trigger={userImage ? ( <Button><Media url={userImage} caption="User" width='30px' profile/></Button>):(<Button>
						<Media 
							profile>
								{auth.currentUser.email.charAt(0).toUpperCase()}
							</Media>
					</Button>)}>

						<Container variant='blank'>
							<VStack>
								<Container variant='blank'>
									<Cell 
										variant='button' 
										onClick={onLogout}
										showDivider
										
										>
										<Cell.Text>
											<Body>Log Out</Body>
										</Cell.Text>
										<Icon name='LogIn' />
									</Cell>
									
									<Cell 
										variant='link' 
										onClick={onLogout}
										showDivider
										
										url='https://docs.google.com/forms/d/e/1FAIpQLSfQykfbAIeHjAxEurwNfPZikYmQ9FY5P4CJjVSJKX6DzILTKA/viewform'
										>
											<HStack layout='middle'>
												<Cell.Text>
													<Body>Give Feedback</Body>
												</Cell.Text>
												<Icon name='ChevronRight' />
											</HStack>
									</Cell>
									
									{ subscriptionStatus === 'premium' ? (
										<Cell 
											variant='button' 
											onClick={handlePortalSession}
											
											
											>
											<HStack layout='middle'>
												<Cell.Text>
													<Body>Manage Billing</Body>
												</Cell.Text>
												{loadingStripe ? (<Loader color='--color-icon'/>):(<Icon name='ChevronRight' />)}
											</HStack>
										</Cell>

									):(<></>)}

								</Container>
								
								{subscriptionStatus !== 'premium'?(

									<Container variant='fill'>
									<Cell 
										variant='button' 
										onClick={() => {handlePaymentCheckout('price_1Nf8ItHUEMXMwifTAxyefQ8d'); setLoadingStripe(true);}}
										
										
										>
										<Cell.Text>
											<Body>Upgrade Account</Body>
											{errorMessage && <Footnote color='--color-negative'>{errorMessage}</Footnote>}
										</Cell.Text>
										{loadingStripe ? (<Loader color='--color-icon'/>):(<Icon name='ChevronRight' />)}
									</Cell>
								</Container>
								) :(<></>)}
							</VStack>
						</Container>
					</Popover>
				</HStack>
			</HStack>
		</Section>

		{children && (<>
			<Space.Content />
			<div ref={childrenRef} className="child-sticky-section">
				{children}
			</div>
		</>)}
    </nav>
  )
}

GlobalNav.propTypes = {
  label: PropTypes.string,
}

export default GlobalNav

export const systemTemplates = {
  default: {
	text: {
		expanded: {
			title3LetterSpacing: "0",
			headlineLetterSpacing: "0",
			title2Size: "40",
			title1LineHeight: "56",
			subheadLineHeight: "20",
			bodyLineHeight: "24",
			title3LineHeight: "40",
			subheadSize: "14",
			caption1LetterSpacing: "0",
			caption2LineHeight: "14",
			caption1Size: "13",
			footnoteSize: "13",
			caption2LetterSpacing: "0",
			bodyLetterSpacing: "0",
			title2LineHeight: "44",
			headlineLineHeight: "24",
			title2LetterSpacing: "-.01",
			caption1LineHeight: "16",
			title3Size: "36",
			footnoteLetterSpacing: "0",
			title1Size: "48",
			footnoteLineHeight: "20",
			subheadLetterSpacing: "0",
			caption2Size: "12",
			bodySize: "16",
			headlineSize: "18",
			title1LetterSpacing: "-.01"
		},
		compact: {
			title3LineHeight: "32",
			title1Size: "40",
			title3Size: "28",
			headlineLetterSpacing: "0",
			caption1LetterSpacing: "0",
			subheadLetterSpacing: "0",
			caption1Size: "13",
			footnoteLetterSpacing: "0",
			title2LineHeight: "36",
			subheadSize: "14",
			title1LineHeight: "44",
			bodyLetterSpacing: "0",
			headlineLineHeight: "24",
			bodyLineHeight: "24",
			bodySize: "16",
			footnoteLineHeight: "20",
			caption2LineHeight: "14",
			title3LetterSpacing: "0",
			headlineSize: "18",
			title2Size: "32",
			title2LetterSpacing: "-.01",
			caption2LetterSpacing: "0",
			title1LetterSpacing: "-.01",
			caption2Size: "12",
			footnoteSize: "13",
			subheadLineHeight: "20",
			caption1LineHeight: "16"
		},
		medium: {
			bodyLineHeight: "24",
			footnoteLetterSpacing: "0",
			title1Size: "44",
			subheadSize: "14",
			headlineLetterSpacing: "0",
			title2LineHeight: "40",
			caption2LineHeight: "14",
			footnoteLineHeight: "20",
			footnoteSize: "13",
			headlineLineHeight: "24",
			caption2Size: "12",
			caption2LetterSpacing: "0",
			title3LineHeight: "36",
			subheadLetterSpacing: "0",
			caption1Size: "13",
			headlineSize: "18",
			bodyLetterSpacing: "0",
			caption1LetterSpacing: "0",
			title3LetterSpacing: "0",
			caption1LineHeight: "16",
			title2LetterSpacing: "-.01",
			title3Size: "32",
			title1LetterSpacing: "-.01",
			bodySize: "16",
			title1LineHeight: "48",
			title2Size: "36",
			subheadLineHeight: "20"
		},
		weight: {
			footnoteBold: "600",
			bodyLight: "400",
			footnoteLight: "300",
			footnote: "400",
			caption2Bold: "600",
			bodyBold: "600",
			subhead: "500",
			title2Regular: "400",
			title2: "600",
			subheadLight: "300",
			title2Light: "300",
			caption2: "600",
			headlineBold: "600",
			footnoteRegular: "400",
			body: "400",
			title1: "600",
			title1Light: "300",
			title3Light: "300",
			subheadRegular: "400",
			subheadBold: "600",
			caption1Bold: "600",
			title3Bold: "800",
			caption1Light: "400",
			headline: "600",
			title3Regular: "400",
			headlineRegular: "400",
			caption1: "600",
			title2Bold: "800",
			title1Bold: "800",
			headlineLight: "400",
			bodyRegular: "400",
			caption2Light: "400",
			title1Regular: "400",
			caption2Regular: "400",
			caption1Regular: "400",
			title3: "500"
		},
		family: {
			name: "",
			titleFallback: "system-ui, \"Segoe UI\", Roboto, Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
			fallback: "system-ui, \"Segoe UI\", Roboto, Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
			titleName: "Space Grotesk",
			src: "",
			titleSrc: "https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap"
		}
	},
	radius: {
		medium: {
			smallControl: "16",
			page: "32",
			container: "16",
			largeControl: "10"
		},
		expanded: {
			smallControl: "16",
			largeControl: "10",
			container: "16",
			page: "32"
		},
		compact: {
			largeControl: "10",
			container: "16",
			smallControl: "16",
			page: "24"
		}
	},
	color: {
		light: {
			textSecondaryAlpha: ".7",
			textPrimary: "#1d1e20",
			iconAlpha: ".8",
			accentAlpha: "1",
			notice: "#D4AC21",
			accent: "#F97558",
			primary: "#4782F0",
			onPrimary: "#FFFFFF",
			fillContainer: "#555658",
			surfaceHoverAlpha: "0.08",
			negative: "#F95862",
			primaryAlpha: "1",
			divider: "#555658",
			backgroundElevated: "#FFFFFF",
			dividerAlpha: "0.15",
			surfaceDown: "#555658",
			fillControl: "#555658",
			icon: "#303036",
			informative: "#1488E1",
			backgroundFlat: "#F5F5F5",
			border: "#555658",
			surfaceDownAlpha: "0.04",
			readOnly: "#555658",
			readOnlyAlpha: "0.5",
			surfaceHover: "#555658",
			fillContainerAlpha: "0.06",
			borderAlpha: "0.12",
			fillControlAlpha: "0.09",
			textSecondary: "#1d1e20",
			textPrimaryAlpha: "1",
			positive: "#25B545",
			onAccent: "#FFFFFF"
		},
		dark: {
			negative: "#e01515",
			surfaceHover: "#FFFFFF",
			notice: "#FFED47",
			surfaceDown: "#4782F0",
			iconAlpha: "0.7",
			textPrimaryAlpha: "1",
			accent: "#E85333",
			dividerAlpha: "0.11",
			textSecondary: "#F5F5F5",
			onAccent: "#FFFFFF",
			backgroundElevated: "#1C1C1C",
			fillControlAlpha: "0.08",
			positive: "#15e088",
			fillContainerAlpha: "0.05",
			primaryAlpha: "1",
			borderAlpha: "0.1",
			readOnlyAlpha: ".4",
			border: "#FFFFFF",
			fillControl: "#FFFFFF",
			accentAlpha: "1",
			surfaceDownAlpha: "0.08",
			fillContainer: "#FFFFFF",
			backgroundFlat: "#121212",
			textPrimary: "#F5F5F5",
			primary: "#4782F0",
			textSecondaryAlpha: "0.7",
			divider: "#FFFFFF",
			icon: "#F5F5F5",
			readOnly: "#FFFFFF",
			surfaceHoverAlpha: "0.11",
			informative: "#1588e1",
			onPrimary: "#ffffff"
		}
	},
	size: {
		responsive: {
			expanded: "1200",
			medium: "720",
			maxPageBleed: "1500"
		},
		expanded: {
			large: "44",
			small: "32",
			base: "24"
		},
		compact: {
			large: "44",
			small: "32",
			base: "24"
		},
		medium: {
			large: "44",
			base: "24",
			small: "32"
		}
	},
	space: {
		compact: {
			hExtra: "6",
			hSmall: "4",
			content: "8",
			vExtra: "6",
			hSection: "8",
			hUnify: "4",
			vSection: "8",
			vSmall: "4",
			vUnify: "4"
		},
		expanded: {
			hUnify: "4",
			hSmall: "4",
			vSection: "16",
			hSection: "16",
			hExtra: "12",
			vUnify: "4",
			vExtra: "10",
			content: "8",
			vSmall: "4"
		},
		medium: {
			vExtra: "6",
			hUnify: "4",
			hExtra: "8",
			vUnify: "4",
			content: "8",
			hSmall: "4",
			hSection: "16",
			vSmall: "4",
			vSection: "16"
		}
	}
  },
  Blue: {
    color: {
      light: {
        primary: '59, 170, 251, 1',
        onPrimary: '0, 0, 0, 1',
        
        accent: '59, 170, 251, .20',
        onAccent: '59, 170, 251, 1',
        
        textPrimary: '255, 255, 255, 1',
        textSecondary: '179, 179, 179, 1',
    
        backgroundElevated: "31, 35, 38, 1",
        backgroundFlat: '21, 21, 21, 1',
        
        surfaceHover: '14, 89, 143, .08',
        surfaceDown: '14, 89, 143, .06',
    
        fillContainer: '255, 255, 255, .11',
        fillControl: '255, 255, 255, .15',
    
        positive: '62, 251, 59, 1',
        informative: '59, 170, 251, 1',
        negative: '251, 82, 59, 1',
        notice: '255, 237, 71, 1',
    
        icon: '203, 203, 203, 1',
        border: '65, 65, 65, 1',
        divider: '255, 255, 255, .25',
        readOnly: '141, 157, 165, 1',
      },
    },
    radius: {
      largeControl: '4',
      smallControl: '4',
      container: '4',
      page: '0',
    },
    space: {
      default: {
        content: '8',
        hSection: '8',
        hExternal: '8',
        hInternal: '16',
        hInternalSmall: '4',
        lineHeight: '0',
    
        vSection: '8',
        vExternal: '8',
        vInternal: '8',
        vInternalSmall: '2',
      }
    },
    size: {
      default: {
        large: '44',
        small: '24',
        base: '24',
      }
    }
  },
  Purple: {
    color: {
      light: {
        primary: '174, 117, 249, 1',
        onPrimary: '255, 255, 255, 1',
        
        accent: '221, 121, 193, 1',
        onAccent: '255, 255, 255, 1',
        
        textPrimary: '51, 14, 74, 1',
        textSecondary: '51, 14, 74, .6',
    
        backgroundElevated: "246, 236, 251, 1",
        backgroundFlat: '255, 255, 255, 1',
        
        surfaceHover: '151, 71, 255, .08',
        surfaceDown: '151, 71, 255, .06',
    
        fillContainer: '174, 117, 249, .13',
        fillControl: '174, 117, 249, .20',
    
        positive: '62, 251, 59, 1',
        informative: '59, 170, 251, 1',
        negative: '153, 173, 27, 1',
        notice: '255, 237, 71, 1',
    
        icon: '135, 111, 165, .4',
        border: '203, 190, 218, 1',
        divider: '174, 117, 249, .12',
        readOnly: '141, 157, 165, 1',
      },
    },
    radius: {
      largeControl: '14',
      smallControl: '2',
      container: '8',
      page: '32',
    },
    space: {
      default: {
        content: '8',
        hSection: '20',
        hExternal: '6',
        hInternal: '8',
        hInternalSmall: '6',
        lineHeight: '4',
    
        vSection: '20',
        vExternal: '6',
        vInternal: '6',
        vInternalSmall: '6',
      }
    },
    size: {
      default: {
        large: '44',
        small: '32',
        base: '24',
      }
    }
  },
  Green: {
    color: {
      light: {
        primary: '255, 255, 255, 1',
        onPrimary: '0, 0, 0, 1',
        
        accent: '30, 215, 96, 1',
        onAccent: '0, 0, 0, 1',
        
        textPrimary: '240, 240, 240, 1',
        textSecondary: '179, 179, 179, 1',
    
        backgroundElevated: "37, 37, 37, 1",
        backgroundFlat: '21, 21, 21, 1',
        
        surfaceHover: '14, 89, 143, .08',
        surfaceDown: '14, 89, 143, .06',
    
        fillContainer: '255, 255, 255, .09',
        fillControl: '255, 255, 255, .11',
    
        positive: '62, 251, 59, 1',
        informative: '59, 170, 251, 1',
        negative: '255, 0, 2, 1',
        notice: '255, 237, 71, 1',
    
        icon: '203, 203, 203, 1',
        border: '65, 65, 65, 1',
        divider: '255, 255, 255, .25',
        readOnly: '141, 157, 165, 1',
      },
    },
    radius: {
      largeControl: '24',
      smallControl: '16',
      container: '32',
      page: '48',
    },
    space: {
      default: {
        content: '8',
        hSection: '16',
        hExternal: '16',
        hInternal: '12',
        hInternalSmall: '4',
        lineHeight: '4',
    
        vSection: '16',
        vExternal: '12',
        vInternal: '8',
        vInternalSmall: '4',
      }
    },
    size: {
      default: {
        large: '44',
        small: '32',
        base: '24',
      }
    }
  	},
  	Orange: {
	size: {
		expanded: {
			small: "32",
			base: "24",
			large: "44"
		},
		medium: {
			base: "24",
			small: "32",
			large: "44"
		},
        compact: {
			base: "24",
			small: "32",
			large: "44"
		}
	},
	space: {
        medium: {
			vInternalSmall: "4",
			hSection: "26",
			hInternalSmall: "4",
			content: "8",
			hInternal: "12",
			hExternal: "8",
			lineHeight: "4",
			vInternal: "8",
			vExternal: "8",
			vSection: "26"
		},
		compact: {
			hInternalSmall: "4",
			vExternal: "6",
			content: "8",
			vSection: "10",
			vInternal: "8",
			hInternal: "12",
			hSection: "10",
			vInternalSmall: "4",
			hExternal: "6",
			lineHeight: "4"
		},
		expanded: {
			hInternal: "12",
			vInternalSmall: "4",
			lineHeight: "4",
			hExternal: "12",
			vInternal: "8",
			content: "8",
			hInternalSmall: "4",
			hSection: "32",
			vSection: "32",
			vExternal: "12"
		}
	},
	radius: {
		compact: {
			page: "32",
			container: "16",
			largeControl: "10",
			smallControl: "16"
		},
		expanded: {
			page: "32",
			container: "16",
			largeControl: "10",
			smallControl: "16"
		},
		medium: {
			largeControl: "10",
			container: "16",
			smallControl: "16",
			page: "32"
		}
	},
	text: {
		expanded: {
			caption2Size: "12",
			title2LetterSpacing: "0",
			title3LetterSpacing: "0",
			bodyLineHeight: "24",
			caption2LineHeight: "14",
			subheadLetterSpacing: "0",
			headlineLineHeight: "24",
			footnoteSize: "13",
			title2Size: "40",
			caption2LetterSpacing: "0",
			headlineSize: "18",
			title3Size: "32",
			bodySize: "16",
			title2LineHeight: "56",
			caption1LineHeight: "16",
			title1LineHeight: "72",
			headlineLetterSpacing: "0",
			footnoteLineHeight: "20",
			subheadSize: "14",
			subheadLineHeight: "20",
			title3LineHeight: "40",
			caption1LetterSpacing: "0",
			bodyLetterSpacing: "0",
			title1LetterSpacing: "0",
			title1Size: "56",
			footnoteLetterSpacing: "0",
			caption1Size: "13"
		},
		weight: {
			title2Light: "400",
			caption2: "600",
			title2Bold: "400",
			title2Regular: "400",
			caption1Regular: "400",
			caption2Light: "300",
			headline: "600",
			subheadLight: "300",
			title1Bold: "400",
			subheadBold: "600",
			bodyLight: "300",
			subheadRegular: "400",
			title3Light: "400",
			caption2Regular: "400",
			footnoteRegular: "400",
			title1Light: "400",
			title1: "400",
			caption1Bold: "600",
			title2: "400",
			caption1Light: "300",
			footnoteLight: "300",
			body: "300",
			title3Bold: "400",
			caption1: "600",
			headlineBold: "600",
			headlineLight: "300",
			footnote: "400",
			subhead: "600",
			bodyRegular: "400",
			bodyBold: "600",
			footnoteBold: "600",
			title3: "400",
			title1Regular: "400",
			title3Regular: "400",
			caption2Bold: "600",
			headlineRegular: "400"
		},
		compact: {
			caption2LetterSpacing: "0",
			title1LineHeight: "50",
			footnoteLineHeight: "20",
			caption2LineHeight: "14",
			subheadSize: "14",
			title2LetterSpacing: "0",
			subheadLineHeight: "20",
			title3LineHeight: "32",
			title2Size: "36",
			caption1LineHeight: "16",
			subheadLetterSpacing: "0",
			bodyLetterSpacing: "0",
			caption1Size: "13",
			caption1LetterSpacing: "0",
			headlineLetterSpacing: "0",
			footnoteSize: "13",
			title2LineHeight: "52",
			bodySize: "16",
			title1Size: "44",
			title3LetterSpacing: "0",
			title1LetterSpacing: "0",
			title3Size: "28",
			headlineSize: "18",
			bodyLineHeight: "24",
			caption2Size: "12",
			headlineLineHeight: "24",
			footnoteLetterSpacing: "0"
		},
		family: {
            src: "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap",
            titleFallback: "\"New York\", \"Roboto Serif\", ui-serif, serif",
            titleSrc: "https://fonts.googleapis.com/css2?family=Lora&display=swap",
            titleName: "Lora",
            fallback: "system-ui, \"Segoe UI\", Roboto, Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
            name: "Montserrat"
		},
        medium: {
            footnoteSize: "13",
            title3LineHeight: "36",
            caption2LineHeight: "14",
            title2Size: "38",
            bodyLineHeight: "24",
            headlineLetterSpacing: "0",
            headlineSize: "18",
            subheadLineHeight: "20",
			title1LetterSpacing: "0",
			headlineLineHeight: "24",
			caption1Size: "13",
			caption2LetterSpacing: "0",
			footnoteLetterSpacing: "0",
			title3LetterSpacing: "0",
			caption1LineHeight: "16",
			caption2Size: "12",
			title2LetterSpacing: "0",
			title3Size: "30",
			subheadSize: "14",
			bodySize: "16",
			caption1LetterSpacing: "0",
			title2LineHeight: "54",
			footnoteLineHeight: "20",
			title1LineHeight: "56",
            title1Size: "48",
            subheadLetterSpacing: "0",
            bodyLetterSpacing: "0"
		}
	},
	color: {
    	light: {
			onAccent: "#FFFFFF",
			onPrimary: "#ffffff",
			fillControlAlpha: ".09",
			backgroundElevated: "#ffffff",
			borderAlpha: ".2",
			negative: "#e01515",
			divider: "#19093e",
			icon: "#303036",
			informative: "#1588e1",
			accentAlpha: "1",
			surfaceHover: "#9747ff",
			positive: "#15e088",
			primary: "#1488e1",
			readOnly: "#5d6569",
			surfaceHoverAlpha: ".08",
			accent: "#7358f9",
			textPrimary: "#1d1e20",
			fillContainerAlpha: ".05",
			surfaceDownAlpha: ".06",
			textPrimaryAlpha: "1",
			notice: "#FFED47",
			border: "#3c3d49",
			textSecondary: "#1d1e20",
			iconAlpha: ".8",
			primaryAlpha: "1",
			fillContainer: "#322a46",
			surfaceDown: "#9747ff",
			fillControl: "#322a46",
			textSecondaryAlpha: ".7",
			backgroundFlat: "#f5f1f9",
			dividerAlpha: ".5",
			readOnlyAlpha: "1"
		},
		dark: {
			dividerAlpha: ".4",
			onPrimary: "#ffffff",
			negative: "#de1717",
			accentAlpha: "1",
			textPrimaryAlpha: "1",
			borderAlpha: ".2",
			readOnlyAlpha: "1",
			textPrimary: "#f0f0f0",
			surfaceDownAlpha: ".06",
			fillControl: "#a997c3",
			primary: "#1777de",
			primaryAlpha: "1",
			fillContainerAlpha: ".05",
			textSecondary: "#ffffff",
			iconAlpha: "1",
			surfaceHoverAlpha: ".08",
			backgroundFlat: "#1e1e1f",
			positive: "#17de77",
			onAccent: "#ffffff",
			fillContainer: "#a997c3",
			backgroundElevated: "#36363a",
			fillControlAlpha: ".1",
			notice: "#ecf934",
			divider: "#b2aed0",
			accent: "#8f4ff8",
			border: "#a1a1a1",
			icon: "#b0a7c8",
			surfaceDown: "#9151fa",
			informative: "#1777de",
			readOnly: "#606b71",
			textSecondaryAlpha: ".7",
			surfaceHover: "#9050f9"
    	}
	},
	}
}
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage"


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCOrEddcA-DyjhHWjDI-jsaiRadBws6KfE",
  authDomain: "themeable-ce1f4.firebaseapp.com",
  projectId: "themeable-ce1f4",
  messagingSenderId: "171368579147",
  appId: "1:171368579147:web:1b0346d4f0e9a937974dce",
  measurementId: "G-80YR6JRWVK",
  storageBucket: "gs://system-style"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
// export const persistLocally = setPersistence(auth, auth.Auth.Persistence.LOCAL)
export default app;
import React, { useEffect, useState } from 'react'
import FoundationsPanel from '../../modules/FoundationsPanel'
import { Accordion, Body, Button, Caption1, Container, Divider, Footnote, Group, HStack, Headline, Icon, Input, Label, Media, Section, Space, Subhead, Switch, Tab, Tag, Title2, Title3, VStack } from '@themeable/react-components'
import { useContextValue } from '../../AppContext';
import InputColor from '../../modules/InputColor';
import InputAlpha from '../../modules/InputAlpha';


function Color() {
	

return (<>
<VStack>
	<Button url='https://getthemeable.com/education/how-to-create-a-color-system'>
		<Container style={{
			backgroundImage: 'url(../assets/help-articles/foundation-help-color.jpg)',
			backgroundSize: 'cover',
			backgroundPosition: 'center center',
			}}>
			<Section>
				<VStack layout='middle center'>
					<Title2 color='--color-on-primary'>
						Color
					</Title2>
					<HStack wrap>
						<Group>
							<Headline color='--color-on-primary'>
								Not sure what your settings should be? Check out this article
							</Headline>
							<Icon name='ArrowRight' color='--color-on-primary'/>
						</Group>
					</HStack>
				</VStack>
			</Section>
		</Container>
	</Button>
	<FoundationsPanel 
		form={<>
			<Title3>Prominent Colors</Title3>
			<HStack>
				<InputColor 
					label='Primary'
					name='primary'
					/>
				<InputAlpha 
					name='primaryAlpha'
					/>
			</HStack>
			<HStack>
				<InputColor
					label='Color on Primary'
					name='onPrimary'
					/>
				<InputAlpha 
					hide
					/>
			</HStack>
			<Divider />
			<HStack>
				<InputColor 
					label='Accent'
					name='accent'
					/>
				<InputAlpha 
					name='accentAlpha'
					/>
			</HStack>
			<HStack>
				<InputColor
					label='Color on Accent'
					name='onAccent'
					/>
				<InputAlpha 
					hide
					/>
			</HStack>					
		</>}
		snippet={<>
		<Section>

			<VStack layout='middle'>

				<Subhead>Select an Option:</Subhead>
				<HStack layout='middle left' wrap>
					<Button 
						variant='tertiary'
						text1='Option'
						small
						/>
					<Button 
						variant='primary'
						text1='Selected option'
						small
						/>
					<Button 
						variant='tertiary'
						text1='Option 3'
						small
						/>
					<Button 
						variant='tertiary'
						text1='Option 4'
						small
						/>
					<Button 
						variant='tertiary'
						text1='Option 5'
						small
						/>
				</HStack>
				<Divider nested />
				<HStack layout='middle'>
					<Subhead>Remember next time?</Subhead>
					<Space.Grow />
					<Switch 
						checked
						onChange={(e) => {console.log(e)}}
						/>
				</HStack>
				<Divider nested />
				<Space.NewSection />
				<HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
					<Button 
						variant='accent'
						text1='Save as Draft'
						/>
					<Button 
						variant='primary'
						text1='Publish'
						/>
				</HStack>
					<Button 
						variant='tertiary'
						text1='Cancel'
						/>
			</VStack>
		</Section>
		</>}
	/>


	<FoundationsPanel 
		form={<>
			<Title3>Text/Glyph Colors</Title3>
			<HStack>
				<InputColor 
					label='Text Primary'
					name='textPrimary'
					/>
				<InputAlpha 
					name='textPrimaryAlpha'
					/>
			</HStack>		
			<HStack>
				<InputColor 
					label='Text Secondary'
					name='textSecondary'
					/>
				<InputAlpha 
					name='textSecondaryAlpha'
					/>
			</HStack>
			<Divider />		
			<HStack>
				<InputColor 
					label='Icon'
					name='icon'
					/>
				<InputAlpha 
					name='iconAlpha'
					/>
			</HStack>
		</>}
		snippet={<>
		<Section>

			<VStack layout='middle left'>

				<HStack layout='left middle'>
					<Tag text1='Tag Example' status='neutral' />
					<Space.Content />
					<Icon name='HeartOutline' />
					<Space.Content />
					<Icon name='ThreeDots' />
				</HStack>


				<Title2>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit
				</Title2>
				<Body color='--ts-color-text-secondary'>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. 
				</Body>

				<HStack>
					<Divider vertical />
					<Space.Content />
					<Body>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
					</Body>
				</HStack>
				<Space.NewSection />
				<Button small variant='secondary' text1='Show more' icon2='ChevronDown' />
			</VStack>
		</Section>
		</>}
	/>
	
	
	<FoundationsPanel 
		form={<>
			<Title3>Decorative Elements</Title3>

				<HStack>
					<InputColor 
						label='Background (Elevated)'
						name='backgroundElevated'
						/>
					<InputAlpha 
						hide
						/>
				</HStack>		
				<HStack>
					<InputColor 
						label='Background (Flat)'
						name='backgroundFlat'
						/>
					<InputAlpha 
						hide
						/>
				</HStack>		

			<Divider />

				<HStack>
					<InputColor 
						label='Fill (Container)'
						name='fillContainer'
						/>
					<InputAlpha 
						name='fillContainerAlpha'
						/>
				</HStack>		
				<HStack>
					<InputColor 
						label='Fill (Control)'
						name='fillControl'
						/>
					<InputAlpha 
						name='fillControlAlpha'
						/>
				</HStack>	

			<Divider />

				<HStack>
					<InputColor 
						label='Border'
						name='border'
						/>
					<InputAlpha 
						name='borderAlpha'
						/>
				</HStack>		
				<HStack>
					<InputColor 
						label='Read Only'
						name='readOnly'
						/>
					<InputAlpha 
						name='readOnlyAlpha'
						/>
				</HStack>		
				<HStack>
					<InputColor 
						label='Divider'
						name='divider'
						/>
					<InputAlpha 
						name='dividerAlpha'
						/>
				</HStack>		
			
		</>}
		snippet={<>
		<Section>
			<VStack layout='middle'>

				<Caption1 color='--ts-color-text-secondary' align='center'>Background</Caption1>
				<Container variant='outline'>
					<Caption1 color='--ts-color-text-secondary'>Border</Caption1>
						<HStack gridDesktop={2} gridMobile={2} gridTablet={2}>

							<Container variant='default'>
								<Caption1 color='--ts-color-text-secondary'>Background Elevated</Caption1>
									<VStack layout='middle center'>
										<Tag status='neutral' text1='Fill (Control)'/>
										<HStack>
											<Switch checked={false} onChange={() => {}}/> <Button small text1='Button' />
										</HStack>
									</VStack>
							</Container>
							<Container variant='fill'>
								<Caption1 color='--ts-color-text-secondary'>Fill Container</Caption1>
									<VStack layout='middle center'>
										<Tag status='neutral' text1='Fill (Control)'/>
										<HStack>
											<Switch checked={false} onChange={() => {}}/> <Button small text1='Button' />
										</HStack>
									</VStack>
							</Container>
						</HStack>
				</Container>
				<Caption1 color='--ts-color-text-secondary' align='center'>Divider</Caption1>
				<Divider />
				<Container variant='default'>
					<HStack>
						<Caption1 color='--ts-color-text-secondary'>Background Elevated</Caption1>
						<Divider vertical />
						<Container variant='fill'>
							<Body color='--ts-color-read-only'>Placeholder (read only)</Body>
						</Container>
					</HStack>
				</Container>
				<Caption1 color='--ts-color-text-secondary' align='center'>Read only</Caption1>
				<HStack layout='middle center' gridDesktop={3} gridMobile={3} gridTablet={3}>
					<HStack></HStack>
					<HStack layout='center middle'>
						<div 
							style={{
								background: 'rgba(var(--ts-color-read-only), var(--ts-color-read-only-alpha))',
								width: '8px',
								height: '8px',
								borderRadius: '16px',
								margin: 'calc(var(--ts-space-content) / 2)'
							}}
							/>
						<div 
							style={{
								background: 'rgba(var(--ts-color-read-only), var(--ts-color-read-only-alpha))',
								width: '8px',
								height: '8px',
								borderRadius: '16px',
								margin: 'calc(var(--ts-space-content) / 2)'
							}}
							/>
						<div 
							style={{
								background: 'rgba(var(--ts-color-primary), 1)',
								width: '12px',
								height: '12px',
								borderRadius: '16px',
								margin: 'calc(var(--ts-space-content) / 2)'
							}}
							/>
						<div 
							style={{
								background: 'rgba(var(--ts-color-read-only), var(--ts-color-read-only-alpha))',
								width: '8px',
								height: '8px',
								borderRadius: '16px',
								margin: 'calc(var(--ts-space-content) / 2)'
							}}
							/>
					</HStack>
					<HStack layout='right'>
						<Group>
							<Button small variant='readOnly' text1='Read Only'/>
							<Button small variant='secondary' text1='Next' />
						</Group>
					</HStack>

				</HStack>
			</VStack>

		</Section>
		</>}
	/>



	<FoundationsPanel 
		form={<>
			<Title3>Interactions</Title3>
			<HStack>
				<InputColor 
					label='Hover'
					name='surfaceHover'
					/>
				<InputAlpha 
					name='surfaceHoverAlpha'
					/>
			</HStack>		
			<HStack>
				<InputColor 
					label='Clicked/Down'
					name='surfaceDown'
					/>
				<InputAlpha 
					name='surfaceDownAlpha'
					/>
			</HStack>
		</>}
		snippet={<>
		<Section>

			<HStack layout='middle left' gridDesktop={2} gridMobile={1} gridTablet={2}>
				<VStack layout='left'>
					<HStack layout='left'>
						<Tab 
							active
							icon1='Placeholder'
							text1='Tab 1'
							small
							stacked
							/>
						<Tab 
							icon1='Placeholder'
							text1='Tab 2'
							small
							stacked
							/>
						<Tab 
							icon1='Placeholder'
							text1='Tab 3'
							small
							stacked
							/>
					</HStack>
					<Button small icon1='CloseCircle' text1='Delete'/>
				</VStack>
				<VStack>
					<Accordion defaultActive parentContent={<>
						Parent
					</>}>
						<Container>
							<Input placeholder='Placeholder' />
						</Container>
						<Container variant='outline'>
							<Input placeholder='Placeholder' />
						</Container>
						<Space.Content />
						<Button text1='Confirm'/>
						<HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
							<Button text1='Delete' variant='destructive'/>
							<Button text1='Cancel' variant='tertiary'/>
						</HStack>
					</Accordion>
				</VStack>
			</HStack>
		</Section>
		</>}
	/>


<FoundationsPanel 
		form={<>
			<Title3>Status colors</Title3>
			<HStack>
				<InputColor 
					label='Positive'
					name='positive'
					/>
				<InputAlpha 
					hide
					/>
			</HStack>		
			<HStack>
				<InputColor 
					label='Informative'
					name='informative'
					/>
				<InputAlpha 
					hide
					/>
			</HStack>		
			<HStack>
				<InputColor 
					label='Notice'
					name='notice'
					/>
				<InputAlpha 
					hide
					/>
			</HStack>		
			<HStack>
				<InputColor 
					label='Negative'
					name='negative'
					/>
				<InputAlpha 
					hide
					/>
			</HStack>		

		</>}
		snippet={<>
		<Section>

			<VStack layout='middle left'>

				<HStack layout='middle'>
					<Group>
						<Icon name='CheckCircle' color='--ts-color-positive'/>
						<Space.Content />
						<Body color='--ts-color-positive'>Good job!</Body>
					</Group>
				</HStack>
				<HStack layout='middle'>
					<Group>
						<Icon name='Info' color='--ts-color-informative'/>
						<Space.Content />
						<Body color='--ts-color-informative'>Processing your order...</Body>
					</Group>
				</HStack>
				<HStack layout='middle'>
					<Group>
						<Icon name='Warning' color='--ts-color-notice'/>
						<Space.Content />
						<Body color='--ts-color-notice'>We noticed some unusual activity</Body>
					</Group>
				</HStack>
				<HStack layout='middle'>
					<Group>
						<Icon name='Trash' color='--color-negative'/>
						<Space.Content />
						<Body color='--ts-color-negative'>Are you sure you want to delete? <br/>This cannot be undone.</Body>
					</Group>
				</HStack>

			</VStack>
		</Section>

		</>}
	/>

</VStack>		
	</>)}

export default Color
import React from 'react';
import PropTypes from 'prop-types';

const Space = ({ children }) => {
  return <React.Fragment>{children}</React.Fragment>;
};

const styledSpace = (variant) => {
  return ({ className = '' }) => {
    const combinedClassName = `${variant} ${className}`.trim();
    return <div className={combinedClassName} />;
  };
};

Space.NewSection = styledSpace('new-section');
Space.Grow = styledSpace('grow');
Space.Content = styledSpace('content');
// ... add more variants as needed

export default Space;

import React from 'react'
import FoundationsPanel from '../../modules/FoundationsPanel'
import { Accordion, Body, Button, Caption1, Container, Divider, Footnote, Group, HStack, Headline, Icon, Input, Label, Media, Section, Space, Subhead, Switch, Tab, Tag, Title2, Title3, VStack } from '@themeable/react-components'
import { useContextValue } from '../../AppContext';
import { InputSpace } from '../../modules/InputPixel';

function SpaceFoundations() {
	const { context, cSpace, setCSpace } = useContextValue();

  return (
    <>
	<VStack layout=''>


	<Button url='https://getthemeable.com/education/how-to-create-a-spacing-system'>
		<Container style={{
			backgroundImage: 'url(../assets/help-articles/foundation-help-spacing.jpg)',
			backgroundPosition: 'center center',
			backgroundSize: 'cover',
			}}>
			<Section>
				<VStack layout='middle center'>
					<Title2 color='--color-on-primary'>
						Space
					</Title2>
					<HStack wrap>
						<Group>
							<Headline color='--color-on-primary'>
								Not sure what your settings should be? Check out this article
							</Headline>
							<Icon name='ArrowRight' color='--color-on-primary'/>
						</Group>
					</HStack>
				</VStack>
			</Section>
		</Container>
	</Button>


	<FoundationsPanel 
		form={<>
			<Title3>Internal Spacing</Title3>
			<Headline>Small components</Headline>
			<HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
				<InputSpace 
					name='hSmall'
					label='Horizontal'
					/>
				<InputSpace 
					name='vSmall'
					label='Vertical'
					/>	
			</HStack>
			<Divider />
			<Headline>Large components</Headline>
			<HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
				<InputSpace 
					name='hExtra'
					label='Horizontal'
					/>
				<InputSpace 
					name='vExtra'
					label='Vertical'
					/>	
			</HStack>
		</>}
	snippet={<>
	<Section>

	<VStack layout='middle'>
		<Container>
			<HStack layout='left middle'>
				<Body>Task complete</Body>
				<Space.Grow />
				<Button icon1='Check' small />
			</HStack>
		</Container>
		<Container>
			<HStack layout='left middle'>
				<Body>See remaning tasks</Body>
				<Space.Grow />
				<Button icon1='ArrowRight' small variant='primary'/>
			</HStack>
		</Container>
		<Space.NewSection />
		<HStack layout='middle center'>
			<Group>
				<Button variant='quaternary' icon1='MediaVolume' small/>
				<Button variant='secondary' icon1='MediaSkipBack' small/>
				<Button variant='primary' icon1='MediaPlay' />
				<Button variant='secondary' icon1='MediaSkipForward' small/>
				<Button variant='tertiary' icon1='MediaShuffle' small/>
			</Group>
		</HStack>
		<Space.NewSection />
		<HStack layout='center middle'>
			<Button variant='destructive' icon1='Placeholder' text1='Cancel'/>
			<Button variant='secondary' icon1='Placeholder' text1='Continue'/>
		</HStack>
	</VStack>
	</Section>

		</>}
	/>


<FoundationsPanel 
		form={<>
			<Title3>Section Spacing</Title3>  
			<Space.Content /> 
			<HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
				<InputSpace 
					name='hSection'
					label='Horizontal'
					/>
				<InputSpace 
					name='vSection'
					label='Vertical'
					/>	
			</HStack>
		</>}
	snippet={<>
	<VStack layout='top'>
		<VStack>
			<Section style={{paddingTop: '0px', paddingBottom: '0px'}}>
			<HStack layout='left middle'>
				<Button variant='quaternary' icon1='Hamburger' small/>
				<Space.Grow />
				<Button variant='secondary' icon1='Person' small/>
				<Button variant='primary' text1='Purchase' small/>
			</HStack>
			</Section>
		</VStack>
		<Divider nested extendLeft extendRight/>

		<Section>
			<Title3>Title</Title3>
			<Body>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut 
			</Body>
			<Divider />

				<VStack layout='top'>
					<HStack layout='left'>
						<Button variant='primary' text1='Button' />
						<Button text1='Button' />
					</HStack>
					<HStack layout='left'>
						<Button  variant='tertiary' text1='Button' />
						<Button  variant='quaternary' text1='Button' />
					</HStack>
				</VStack>
				
				<VStack layout='top'>
					<HStack layout='left'>
						<Button small variant='primary' text1='Button' />
						<Button small text1='Button' />
					</HStack>
					<HStack layout='left'>
						<Button small  variant='tertiary' text1='Button' />
						<Button small  variant='quaternary' text1='Button' />
					</HStack>
				</VStack>

		</Section>

	</VStack>
		</>}
	/>




<FoundationsPanel 
		form={<>
			<Title3>Finer Details</Title3>   
			<HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
				<InputSpace 
					name='content'
					label='Content'
					/>
			</HStack>
			<Space.Content />
			<Headline>Text/Icon adjustments</Headline>
			<HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
				<InputSpace 
					name='hUnify'
					label='Horizontal'
					/>
				<InputSpace 
					name='vUnify'
					label='Vertical'
					/>
				
			</HStack>
		</>}
	snippet={<>
	<Section>
		<VStack layout='middle'>
			<VStack>
				<Container>
					<Body>
						Text inside container
					</Body>
				</Container>
				<Input value='Text input' />
				<Input leadingIconName='Placeholder' value='Text input' trailingIconName='Plus'/>
			</VStack>
		
			<HStack>
				<Group>
					<Button 
						variant='primary'
						icon1='Placeholder'
						/>
					<Button 
						variant='primary'
						text1='Button'
						/>
					<Button 
						variant='primary'
						text1='Button'
						icon1='Placeholder'
						/>
					<Button 
						variant='primary'
						text1='Button'
						iconw='Placeholder'
						/>
				</Group>
			</HStack>
			<HStack>
				<Group>
					<Button 
						variant='quaternary'
						icon1='Placeholder'
						/>
					<Button 
						variant='quaternary'
						text1='Button'
						/>
					<Button 
						variant='quaternary'
						text1='Button'
						icon1='Placeholder'
						/>
					<Button 
						variant='quaternary'
						text1='Button'
						iconw='Placeholder'
						/>
				</Group>
			</HStack>
			<HStack wrap>
				<Group>
					<Button 
						variant='primary'
						icon1='Placeholder'
						small
						/>
					<Button 
						variant='primary'
						text1='Button'
						small
						/>
					<Button 
						variant='primary'
						text1='Button'
						icon1='Placeholder'
						small
						/>
					<Button 
						variant='primary'
						text1='Button'
						iconw='Placeholder'
						small
						/>
				
					<Button 
						variant='quaternary'
						icon1='Placeholder'
						small
						/>
					<Button 
						variant='quaternary'
						text1='Button'
						small
						/>
					<Button 
						variant='quaternary'
						text1='Button'
						icon1='Placeholder'
						small
						/>
					<Button 
						variant='quaternary'
						text1='Button'
						iconw='Placeholder'
						small
						/>
				</Group>
			</HStack>

		</VStack>
	</Section>
		</>}
	/>



	</VStack>

    </>
  )
}

export default SpaceFoundations
import PropTypes from 'prop-types';
import Icon from './Icon';
import Header from './Header';
import Footer from './Footer';
import React from 'react';
import { VStack } from './Stack';

const Input = ({ leadingIcon, trailingIcon, variant, label, type, value, onChange, footnote, inset, error, grow, placeholder }) => {
  const handleInputChange = (newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  const growClass = grow ? true : false; // Determine if 'grow' class should be added

  return (
    <VStack grow>
      <Header title={label} inset={inset} />
      <label className={`input container-${variant} h-stack ${error ? 'error-outline' : ''}`} style={{ textTransform: 'uppercase' }}>
        {leadingIcon ? <Icon name={`${leadingIcon}`} size={24}/> : ''}
        <input
          type={type} 
          value={value}
          placeholder={placeholder}
          className={`${variant} content-view`}
          onChange={(e) => handleInputChange(e.target.value)}
        />
        {trailingIcon && <Icon name={'Percent'} size={24} color={'icon'} />}
      </label>
      <Footer inset={inset} text1={footnote} error/>
    </VStack>
  );
};

Input.defaultProps = {
  type: 'text',
  variant: 'secondary',
  leadingIcon: null,
  grow: false, // Default value for grow prop
};

Input.propTypes = {
  variant: PropTypes.oneOf(['outline', 'fill', 'default']),
  type: PropTypes.oneOf(['text', 'password', 'email', 'number']),
  value: PropTypes.string,
  onChange: PropTypes.func,
  leadingIcon: PropTypes.string, // Assuming leadingIcon is a string name of the icon
  grow: PropTypes.bool,  // Add grow as a boolean prop
};

export default Input;

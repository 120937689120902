import { Container, Input, Label, VStack } from '@themeable/react-components'
import React, { useEffect, useState } from 'react'
import { useContextValue } from '../AppContext';


const variableNameMap = {
    vExternal: 'v-ext',
    hExternal: 'h-ext',
    hInternalSmall: 'h-int-sm',
    hSection: 'h-section',
    vSection: 'v-section',
    // Add more mappings as needed
};


function InputRadius({ name, label }) {

    const { context, cRadius, setCRadius } = useContextValue();

    const handleAlphaChange = (event) => {
        const { name, value } = event.target;
        setCRadius(prev => {    
            // Initialize an object to hold the updates for all density modes
            const updatedDensityModes = {};
    
            // Loop through all density modes
            ['compact', 'medium', 'expanded'].forEach(mode => {
                // If the current mode is the same as the context density, update its value
                if (mode === context.density) {
                    updatedDensityModes[mode] = {
                        ...prev[mode],
                        [name]: value === '' ? '' : (parseInt(value)).toString()
                    };
                } else {
                    // If the current mode is not the same as the context density, update it to an empty string if it's not previously set
                    updatedDensityModes[mode] = {
                        ...prev[mode],
                        [name]: prev[mode][name] || ''
                    };
                }
            });
    
            return updatedDensityModes;
        });
    };
    

  
    return (
        <VStack>
            <Label text1={label}>
                <Container variant='fill'>
                <Input 
                    type='number'
                    placeholder={context.systemList[context.selectedSystem].foundations.radius[context.density][name]}
                    align='right'
                    trailingIconColor='--color-text-secondary'
                    trailingIconName='Pixel'
                    onChange={handleAlphaChange}
                    name={name}
                    value={cRadius[context.density][name] || ''}
                    />
                </Container>
            </Label>
        </VStack>
    )
}

function InputSpace({ name, label }) {

    const { context, cSpace, setCSpace } = useContextValue();
    const [highlightedElements, setHighlightedElements] = useState([]);

    const convertToKebabCase = (name) => {
        return variableNameMap[name] || ''; // Use the mapping if available, otherwise return an empty string
    };



    const handleAlphaChange = (event) => {
        const { name, value } = event.target;
        setCSpace(prev => {    
            const updatedDensityModes = {};
            ['compact', 'medium', 'expanded'].forEach(mode => {
                // If the current mode is the same as the context density, update its value
                if (mode === context.density) {
                    updatedDensityModes[mode] = {
                        ...prev[mode],
                        [name]: value === '' ? '' : (parseInt(value)).toString()
                    };
                } else {
                    // If the current mode is not the same as the context density, update it to an empty string if it's not previously set
                    updatedDensityModes[mode] = {
                        ...prev[mode],
                        [name]: prev[mode][name] || ''
                    };
                }
            });
            return updatedDensityModes;
        });
    };



return (
    // <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <VStack>
            <Label text1={label}>
                <Container variant='fill'>
                <Input 
                    type='number'
                    placeholder={context.systemList[context.selectedSystem].foundations.space[context.density][name]}
                    align='right'
                    trailingIconColor='--color-text-secondary'
                    trailingIconName='Pixel'
                    onChange={handleAlphaChange}
                    name={name}
                    value={cSpace[context.density][name] || ''}
                    />
                </Container>
            </Label>
        </VStack>
    // </div>
)
}

function InputSize({ name, label }) {
    const { context, cSize, setCSize } = useContextValue();

    const handleAlphaChange = (event) => {
        const { name, value } = event.target;
        setCSize(prev => {    
            const updatedDensityModes = {};
            ['compact', 'medium', 'expanded'].forEach(mode => {
                if (mode === context.density) {
                    updatedDensityModes[mode] = {
                        ...prev[mode],
                        [name]: value === '' ? '' : (parseInt(value)).toString()
                    };
                } else {
                    updatedDensityModes[mode] = {
                        ...prev[mode],
                        [name]: prev[mode][name] || ''
                    };
                }
            });
            return {
                ...prev,
                ...updatedDensityModes
            };
        });
    };

    return (
        <VStack>
            <Label text1={label}>
                <Container variant='fill'>
                    <Input 
                        type='number'
                        placeholder={context.systemList[context.selectedSystem].foundations.size[context.density][name]}
                        align='right'
                        trailingIconColor='--color-text-secondary'
                        trailingIconName='Pixel'
                        onChange={handleAlphaChange}
                        name={name}
                        value={cSize[context.density][name] || ''}
                    />
                </Container>
            </Label>
        </VStack>
    );
}

function InputResolution({ name, label }) {
    const { context, cSize, setCSize } = useContextValue();

    const handleAlphaChange = (event) => {
        const { name, value } = event.target;
        setCSize(prev => ({
            ...prev,
            responsive: {
                ...prev.responsive,
                [name]: value === '' ? '' : (parseInt(value)).toString()
            }
        }));
    };

    return (
        <VStack>
            <Label text1={label}>
                <Container variant='fill'>
                    <Input 
                        type='number'
                        placeholder={context.systemList[context.selectedSystem]?.foundations?.size?.responsive !== undefined ? context.systemList[context.selectedSystem]?.foundations?.size?.responsive[name] : ''}
                        align='right'
                        trailingIconColor='--color-text-secondary'
                        trailingIconName='Pixel'
                        onChange={handleAlphaChange}
                        name={name}
                        value={cSize.responsive[name] || ''}
                    />
                </Container>
            </Label>
        </VStack>
    );
}


export { InputRadius, InputSpace, InputSize, InputResolution }
import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from '../services/firebase';
import {db} from '../services/firebase';
import { Navigate, useNavigate } from 'react-router-dom';
import GlobalNav, { Nav } from '../modules/Nav'
import Icon from '../modules/Icon';
import ContentView from '../modules/ContentView';
import { collection, doc, getDoc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import Container from '../modules/Container'; 
import Header from '../modules/Header';
import { Button } from '@themeable/react-components';
// import Button from '../modules/Button';
// import Button from '@mui/material/Button';

const Roadmap = () => {
  
  

  return (

    <section style={{backgroundColor: 'var(--color-bg-flat)'}}>
      <GlobalNav />

      <main className='section'>
        
        <Container>
          <div className='node'>
            <h3 className='headline'>Component customizations</h3>
            <p className='footnote'>Ability to turn on and off specific variations of components</p>
          </div>
        </Container>
        
        <Container>          <div className='node'>

          <h3 className='headline'>More components</h3>
          <p className='footnote'>We want to add sliders, switches, accordions, code snippets and several more components to the list.</p>
          </div></Container>
        
        <Container>          <div className='node'>

          <h3 className='headline'>Code access</h3>
          <p className='footnote'>All Figma components will have a coded counterpart and will be accessible from Figma.</p>
          </div></Container>
        
        <Container>          <div className='node'>

          <h3 className='headline'>Content tokens</h3>
          <p className='footnote'>Ability for external content team to manage the content fed into each component to recommend spcific phrases or offer language support.</p>
          </div></Container>
        
        <Container>          <div className='node'>

          <h3 className='headline'>Contribution</h3>
          <p className='footnote'>Give our users the ability to add their own components to the system</p>
          </div></Container>
        
        <Container>          <div className='node'>

          <h3 className='headline'>Custom Font Family</h3>
          <p className='footnote'>Ability to add your own font family.</p>
          </div></Container>
        
        <Container>          <div className='node'>

          <h3 className='headline'>Roadmap voting</h3>
          <p className='footnote'>Giving our users the option to vote each quarter on features they want to see prioritized.</p>
          </div></Container>

        <Container>          <div className='node'>

          <h3 className='headline'>Mode toggling</h3>
          <p className='footnote'>Option to toggle modes between light, dark, high contrast, low contrast</p>
          </div></Container>

        <Container>          <div className='node'>

          <h3 className='headline'>Tutorials</h3>
          <p className='footnote'>Adding tutorials, walkthroughs of taking advantage of all your system has to offer.</p>
          </div></Container>
        
        <Container>          <div className='node'>

          <h3 className='headline'>Commenting</h3>
          <p className='footnote'>Organizational level and global level commenting/bug reporting on components</p>
          </div> </Container>
        
        <Container>          <div className='node'>

          <h3 className='headline'>Documentation</h3>
          <p className='footnote'>Documentation on a per component/variant basis. Also provide custom documentation if organizations want to customize their documentation.</p>
          </div></Container>
        
        <Container>          <div className='node'>

          <h3 className='headline'>Live code preview</h3>
          <p className='footnote'>Being that every Themeable Figma component has a coded counterpart, an export feature that quickly renders a live preview any developer can start with.</p>
          </div></Container>
        
        <Container>          <div className='node'>

          <h3 className='headline'>Toggle guides/safe area</h3>
          <p className='footnote'>Each component comes with built in spacing, so a function that toggles on the visibility of those spacers.</p>
          </div></Container>
        
        <Container>          <div className='node'>

          <h3 className='headline'>Screens</h3>
          <p className='footnote'>Pre-built screens/templates to get you started quicker.</p>
        
        </div></Container>
        


      </main>
    </section>
  );
};

export default Roadmap;
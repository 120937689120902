import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ color }) => {

  const dotInnerStyle = {
    backgroundColor: `rgb(var(--color-${color}))`
  };

  return (
    <div className="loader">
      <div className='dot'><div className='dot-inner' style={dotInnerStyle} /></div>
      <div className='dot'><div className='dot-inner' style={dotInnerStyle} /></div>
      <div className='dot'><div className='dot-inner' style={dotInnerStyle} /></div>
      <div className='dot'><div className='dot-inner' style={dotInnerStyle} /></div>
      <div className='dot'><div className='dot-inner' style={dotInnerStyle} /></div>
    </div>
  );
};

Loader.defaultProps = {
  color: 'icon'
};

Loader.propTypes = {
  color: PropTypes.string
};

export default Loader;

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GlobalNav from '../../modules/Nav'
import Overview from './Overview';
import { Sheet, Section, HStack, Media, Cell, Container, Icon, Button, Title2, Headline, Title3, Popover, Subhead, Divider, Caption1, Group, VStack, Caption2, Input, Label, Body, Loader, Tab, Title1, Space, Footnote } from '@themeable/react-components';
import { useContextValue } from '../../AppContext';
import { SaveData, CalculateChanges, timestampToDate, CreateNewSystem } from '../Utilities/UtilityFunctions';
import FoundationsNav from '../Foundations/FoundationsNav';
import Color from '../Foundations/Color';
import FoundationsSpace from '../Foundations/Space';
import Radius from '../Foundations/Radius';
import TextFamilies from '../Foundations/TextFamilies';
import TextStyles from '../Foundations/TextStyles';
import Size from '../Foundations/Size';
import ChangeLogSheet from './ChangeLogSheet';

const Home = () => {

	const { 
		context,
		setContext,
		cColor,
		cRadius,
		cSpace,
		cSize,
		cText,
		setCText
	} = useContextValue();
    const navigate = useNavigate();
	

	// =====================
	// =====================
	// Autosave feature ----
	// =====================
	// =====================
	const [pageLoadedLength, setPageLoadedLength] = useState(0);
	const [lastDataChange, setLastDataChange] = useState(0);
	const [lotOfTyping, setLotOfTyping] = useState(0);

	const [autoSaveTimestamp, setAutoSaveTimestamp] = useState('');
	const [lastDataChangeTimestamp, setLastDataChangeTimestamp] = useState();
	const [timestampOfLastChange, setTimestampOfLastChange] = useState(new Date());
	const [showTimestamp, setShowTimestamp] = useState(false);
	const [showChangeLog, setShowChangeLog] = useState(false);
	const [systemNotExist, setSystemNotExist] = useState(false);
	const [pageTitle, setPageTitle] = useState('Loading System...');
	const [pageSubTitle, setPageSubTitle] = useState('');


	const [changes, setChanges] = useState({});

	useEffect(() => {
		const interval = setInterval(() => {
			setPageLoadedLength(prevSeconds => prevSeconds + 1);
			setLastDataChange(prevSeconds => prevSeconds + 1);
			setLotOfTyping(prevSeconds => prevSeconds + 1);
		}, 1000);
		return () => clearInterval(interval);

		
	}, []);
	
	useEffect(() => {
	
		if( context.systemList ) {
			if(context?.systemList?.[context?.selectedSystem] === undefined) {
				setPageTitle('System Unavailable')
				setPageSubTitle('This system does not exist. Create a new system or choose one of your existing systems.')
			}
		}

	}, [context.systemList])
	
	
	useEffect(() => {
		setLastDataChange(0)
		if(context.selectedSystem && context?.systemList[context?.selectedSystem]?.name !== undefined) {
			setChanges(CalculateChanges(cColor, cRadius, cSpace, cSize, cText, context));
		} else {
			if(context.systemList) {
				setSystemNotExist(true)
			}
		}

	}, [ cColor, cRadius, cSpace, cSize, cText ])
	
	
	useEffect(() => {
		if(pageLoadedLength > 5) {
			if (lastDataChange === 3) {
				SaveData(cColor, cRadius, cSpace, cSize, cText, context);
				setLastDataChangeTimestamp(pageLoadedLength);
				const timeOfDay = new Date();
				setTimestampOfLastChange(timeOfDay);
				setLotOfTyping(0);
				setShowTimestamp(true);
			} 
			if (lastDataChange < 4) {
				if (lotOfTyping === 10) {
					SaveData(cColor, cRadius, cSpace, cSize, cText, context);
					setLastDataChangeTimestamp(pageLoadedLength);
					setLotOfTyping(0);
					setShowTimestamp(true);
				}
			}
		}
	}, [lastDataChange])

    useEffect(() => {
		const totalSecondsSinceSave = pageLoadedLength - lastDataChangeTimestamp;
		const totalMinutesSinceSave = Math.floor(totalSecondsSinceSave / 60);
	
		if (totalMinutesSinceSave < 1) {
			setAutoSaveTimestamp('a few seconds ago');
		} else if (totalMinutesSinceSave < 10) {
			setAutoSaveTimestamp(`${totalMinutesSinceSave}m ago`);
		} else {
			const formattedTime = `${timestampOfLastChange.getHours()}:${('0' + timestampOfLastChange.getMinutes()).slice(-2)}`;
			setAutoSaveTimestamp(`@ ${formattedTime}`);
		}

    }, [pageLoadedLength]);



	

	
	const { token } = useParams();
	useEffect(() => {
		if (token) {
			const params = token.split('&');
			params.forEach((param) => {
				const [key, value] = param.split('=');
				if (key === 'systemid') {
					setContext(prev => ({
						...prev, 
						selectedSystem: decodeURIComponent(value),
						page: null
					}));
				} 
				if(key === 'page') {
					setContext(prev => ({
						...prev,
						page: decodeURIComponent(value)
					}))
				} 
    		});
  		}
	}, [token]);





	useEffect(() => {
		if (context.systemList !== undefined) {
			const contextText = context?.systemList[context?.selectedSystem]?.foundations?.text;
	
			setCText(prev => ({
				...prev,
				family: {
					...prev.family, // Make sure prev.family exists
					titleName: prev.family ? prev.family.titleName || contextText?.family?.titleName : contextText?.family?.titleName,
					titleSrc: prev.family ? prev.family.titleSrc || contextText?.family?.titleSrc : contextText?.family?.titleSrc,
					name: prev.family ? prev.family.name || contextText?.family?.name : contextText?.family?.name,
					src: prev.family ? prev.family.src || contextText?.family?.src : contextText?.family?.src,
					fallback: prev.family ? prev.family.fallback || contextText?.family?.fallback : contextText?.family?.fallback,
					titleFallback: prev.family ? prev.family.titleFallback || contextText?.family?.titleFallback : contextText?.family?.titleFallback,
				}
			}));
		}
	}, [context]);

	const CreateSystemLocal = () => {
		CreateNewSystem(null, context)
	}
	
	
	useEffect(() => {
	  console.log(context)
	}, [context])
	

if (context.systemList) {
return (
	<main className='page-bleed' style={{ backgroundColor: 'rgb(var(--color-background-flat))', height: '100%'}}>
	<GlobalNav>
		{!systemNotExist &&(

		
		<Section style={{paddingTop: '0px', paddingBottom: '0px'}}>

			<HStack layout='bottom right'>
				<Tab 
					active={context.page === null} 
					text1='Overview' 
					indicator='bottom'
					onClick={() => {navigate(`/systemid=${context.selectedSystem}`);}}
					/>
				<Tab 
					active={context.page === 'foundations'} 
					text1='Foundations' 
					indicator='bottom'
					onClick={() => {navigate(`/systemid=${context.selectedSystem}&page=foundations`);}}
					/>
				<Space.Grow />

				<HStack layout='middle right'>
					<Subhead>Selected system:</Subhead>
					<Popover trigger={
						<Button 
						icon1='Bullet'
						text1={ context?.systemList[context?.selectedSystem]?.name}
						icon2='ChevronUpDown'
						small
						/>
					}>
						<Container variant='blank'>
							<Group>
								{Object.entries(context?.systemList).map(([id, system], index, array) => (
									<div key={index}>
										<Cell
											variant='link'
											url={`../systemid=${id}`}
											>
											<HStack layout='middle'>
												<Cell.Text>
													<Body>{system?.name}</Body>
													
												</Cell.Text>
												<Icon name={context?.systemList[context?.selectedSystem]?.id === system?.id ? 'Check':''} />
											</HStack>
										</Cell>
										{index !== array.length - 1 && <Divider nested/>}
									</div>
								))}
							</Group>
						</Container>
					</Popover>
				</HStack>


			</HStack>

		</Section>
		)}
	</GlobalNav>
	
	{systemNotExist && (
		<>
		<Section>
			<VStack grow layout='left'>
				<Title1>{pageTitle}</Title1>
				<Body>{pageSubTitle}</Body>
				<Button text1='Return Home' small icon1='Home' variant='primary' url='/'/>
			</VStack>
			<VStack maxWidth='500px'>
				<Space.NewSection />
				
				{/* Might be tossed out... */}
				
				{/* <Subhead color='--color-text-secondary'>Existing systems:</Subhead>
				{Object.keys(context.systemList).map((systemId) => {
					const system = context.systemList[systemId];
					return (
						<Cell key={systemId} variant='link' url={`systemid=${systemId}`}>
							<Cell.Text>
								<Body>{system.name}</Body>
								<Footnote color='--color-text-secondary'>Modified: <span style={{color: 'rgba(var(--color-text-primary), var(--color-text-primary-alpha))'}}>{timestampToDate(context.systemList[systemId].lastModified)}</span></Footnote>
							</Cell.Text>
							<Icon name='ChevronRight' />
						</Cell>
					);
				})} */}

			</VStack>
		</Section>
		</>
	)}
	{context.page===null ?(<Overview />):''}
	{context.page==='foundations' ?(
	<>
		<ChangeLogSheet active={showChangeLog} changes={changes} handleSheetChange={() => {setShowChangeLog(false)}}/>
		<Section style={{position: 'relative'}}>
			<VStack layout='top'>
				<HStack gridDesktop={2} gridTablet={2} gridMobile={1} grow layout='bottom'>
				<Title1>Foundations</Title1>
				<HStack layout='right middle'>
					<Group>
						{showTimestamp && (
						<Footnote align='right' color='--color-text-secondary'>
							Synced updates {autoSaveTimestamp}
						</Footnote>
						)}
						{changes.total > 0?(<>
						<Space.Content />
						
						<Button variant='primary' text1={`View Updates (${changes.total})`} small onClick={() => {setShowChangeLog(true)}}/>
						</>):''}
					</Group>
				</HStack>
				</HStack>
				<Space.Content />
				<Space.Content />
				<HStack layout='left top' grow>
					<FoundationsNav />
					{context.subpage === 'color'?(<Color />):''}
					{context.subpage === 'radius'?(<Radius />):''}
					{context.subpage === 'space'?(<FoundationsSpace />):''}
					{context.subpage === 'size'?(<Size />):''}
					{/* Text subpages */}
					{context.activeL3 === 'families'?(<TextFamilies />):''}
					{context.activeL3 === 'styles'?(<TextStyles />):''}
				</HStack>
			</VStack>

		</Section>
	</>
	):''}
	

</main>

  );
} else {
	return (<>    
		<main className='page-bleed' style={{ backgroundColor: 'rgb(var(--color-background-flat))', height: '100%'}}>
			<GlobalNav>
				{systemNotExist &&(

				
				<Section style={{paddingTop: '0px', paddingBottom: '0px'}}>

					<HStack layout='bottom right'>
						<Tab 
							active={context.page === null} 
							text1='Overview' 
							small 
							indicator='bottom'
							onClick={() => {navigate(`/systemid=${context.selectedSystem}`);}}
							/>
						<Tab 
							active={context.page === 'foundations'} 
							text1='Foundations' 
							small 
							indicator='bottom'
							onClick={() => {navigate(`/systemid=${context.selectedSystem}&page=foundations`);}}
							/>
						<Space.Grow />

						{context.selectedSystem}
						{ context.selectedSystem && (
							<HStack layout='middle right'>
							<Subhead>Selected system:</Subhead>
							<Popover trigger={
								<Button 
								icon1='Bullet'
								text1={ context?.systemList[context?.selectedSystem]?.name}
								icon2='ChevronUpDown'
								small
								/>
							}>
								<Group>
									{Object.entries(context?.systemList).map(([id, system], index, array) => (
										<div key={index}>
											<Cell
												variant='link'
												url={`../systemid=${id}`}
												>
												<Cell.Text>
													<Body>{system?.name}</Body>
													
												</Cell.Text>
												<Space.Content />
												<Icon name={context?.systemList[context?.selectedSystem]?.id === system?.id ? 'Check':''} />
											</Cell>
											{index !== array.length - 1 && <Divider nested/>}
										</div>
									))}
								</Group>
							</Popover>
							</HStack>
						)}


					</HStack>

				</Section>
				)}
			</GlobalNav>

		<>
		<Section>
			<VStack grow layout='left'>
				<Title1>{pageTitle}</Title1>
				<Body>{pageSubTitle}</Body>
				<Button small text1='Create System' icon1='PlusCircle' variant='primary' onClick={CreateSystemLocal}/>
			</VStack>
		</Section>
		</>

		</main>
	</>)
}
};

export default Home;
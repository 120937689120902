import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import Button from './Button';

const RadioChip = ({ label, selected, onClick }) => {
  return (
    <Button small
      text1={label}
      variant={selected ? 'primary' : 'secondary'}
      onClick={onClick}
    />
  );
};

RadioChip.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default RadioChip;

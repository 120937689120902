import React, { useState, useEffect } from 'react';
import GlobalNav from '../../modules/Nav';
import { useParams, useNavigate  } from 'react-router-dom';
import { doc, getDoc, updateDoc, deleteDoc, arrayRemove } from 'firebase/firestore';
import { db, functions } from '../../services/firebase';
import { httpsCallable } from 'firebase/functions';
import InputColor from '../../modules/InputColor';
import Input from '../../modules/Input';
import Header from '../../modules/Header';
import Container from '../../modules/Container';
import { auth } from '../../services/firebase'; // Import the 'auth' object from your Firebase configuration file
import RadioChip from '../../modules/RadioChip';
import Sheet from '../../modules/Sheet';
import components from '../../data/components';
import { handleCheckout } from '../../services/stripe';
import Space from '../../modules/Space';
import { Divider, Button, HStack, VStack, Group, Section, Title2, Body, Headline, Caption1, Title1, Title3, Subhead, Footnote } from '@themeable/react-components'
import { SampleElements } from './SampleElements';
import { TextForm } from './TextForm';
import { SampleText } from './SampleText';

const createAndUploadCSS = httpsCallable(functions, 'createAndUploadCSS');


const Systems = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [updateButtonLoading, setUpdateButtonLoading] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [premiumModal, setPremiumModal] = useState('');
  const [stripeLoading, setStripeLoading] = useState(false);
  const [colorMode, setColorMode] = useState('light');
  const [screenSize, setScreenSize] = useState('compact');
  const [system, setSystem] = useState({});


  const userInfo = async () => {
    const user = auth.currentUser;
    const userDocRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userDocRef);
    
    if (userDoc.exists()) {
      setSubscriptionStatus(userDoc.data().subscription)
    }
  };

  useEffect(() => {
    userInfo();
  }, []); 

 // Function to convert camel case to sentence case
const camelToSentenceCase = (camelCase) => {
  // Special cases mapping
  const specialCases = {
    
    rLargeControl: 'Radius Large Control',
    rSmallControl: 'Radius Small Control',
    rContainer: 'Radius Container Margins',
    rPage: 'Radius Pages/Modals',

    sContent: 'Space Content',
    sLineHeight: 'Space Text Offset',
    sVSection: 'Space Vertical Section',
    sHSection: 'Space Horizontal Section',
    sHExternal: 'Space Horizontal External',
    sVExternal: 'Space Vertical External',
    sHInternal: 'Space Horizontal Internal',
    sVInternal: 'Space Vertical Internal',
    sHInternalSmall: 'Space Horizontal Internal Small',
    sVInternalSmall: 'Space Vertical Internal Small',

    name: 'System name',

  };

  if (specialCases[camelCase]) {
    return specialCases[camelCase];
  }

  return camelCase.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
};

let systemId;
// URL parameters
const { token } = useParams();
useEffect(() => {
	if (token) {
		const params = token.split('&');

    params.forEach((param) => {
		const [key, value] = param.split('=');
		if (key === 'systemId') {
			systemId = decodeURIComponent(value);
			setSystem((prevState) => ({
				...prevState,
				id: systemId
		}))  
      }
	  if (key === 'colorMode') {
		// Do light/dark mode stuffs
		const URIColorMode = decodeURIComponent(value);
		setColorMode(URIColorMode);
	  }
	  if (key === 'screenMode') {
		// do screen modes stuff
	}
    });
  }
}, [token]);


const [systemCSS, setSystemCSS] = useState('');

  // Initializes system locally
  useEffect(() => {
    const fetchData = async () => {
      try {
        const docSnap = await getDoc(doc(db, 'systems', systemId));
        const data = docSnap.data();
        setSystem((prevState) => ({
          ...prevState,
		  name: data.name,
		  foundations: data.foundations,
        }));
        setSystemCSS(data.css)
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching system data:', error);
      }
    };
    fetchData();
  }, []);

  


    


  // To update the state values:
const handleInputChange = (type, name, value) => {
console.log(name);
console.log(type);

if (typeof value === 'object' && value.target) {
	value = value.target.value;
}

setSystem((prevState) => {
	const newState = { ...prevState };
	if (type === 'color') {
		// Update the specific color property
		newState.foundations.color[colorMode][name] = value;
	} else if (type === 'textFamily') {
		newState.foundations.text.family[name] = value;
	} else if (type === 'textWeight') {
		newState.foundations.text.weight[name] = value;
	} else if (type === 'name') { 
		newState.name = value;
	} else {
		newState.foundations[type][screenSize][name] = value;
	}
	return newState;
});

setUpdateButtonLoading(false);
setShowUpdateButton(true);
};
  
  


  const deleteSystem = async () => {
    const user = auth.currentUser;
  
    try {
      // Delete the system document
      await deleteDoc(doc(db, 'systems', system.id));
  
      // Update the subscribed array in the user's document
      const userRef = doc(db, 'users', user.uid);
    
      await updateDoc(userRef, {
        'systems.subscribed': arrayRemove(system.id)
      });
      
      navigate('../')
  
      console.log('System and subscription deleted successfully.');
    } catch (error) {
      console.error('Error deleting system:', error);
    }
  };
  
  const confirmDeleteSystem = () => {
    const shouldDelete = window.confirm(`Are you sure you want to delete "${system.name}?"`);
  
    if (shouldDelete) {
      deleteSystem();
    }
  };

// =======================================
// ======Pushes JSON to Firebase==========
// =======================================
const updateSystem = async () => {
  setUpdateButtonLoading(true)
  if (subscriptionStatus === 'premium') {
    const emptyFields = [];
    for (const fieldName in system) {
      const fieldValue = system[fieldName] || ''; // Assigns a blank string if the value is undefined

      if (typeof fieldValue === 'object') {
	  } else if (typeof fieldValue === 'string' && fieldValue.trim() === '') {
		const sentenceCaseFieldName = camelToSentenceCase(fieldName);
		emptyFields.push(sentenceCaseFieldName);
	  }
    }

    if (emptyFields.length === 0) {
		console.log(system.id)

      try {
        const systemRef = doc(db, 'systems', system.id);
      
      // Reformat color values from hex to RGBA
      await updateDoc(systemRef, {
		name: system.name,
        foundations: system.foundations,
        components: components
      });
      console.log('System data updated successfully!');
    } catch (error) {
      console.error('Error updating system data:', error);
    }
    
    try {
		console.log('when trying to upload css, this is the data: ')
		console.log(system)
      	const res = await createAndUploadCSS({ 
			css: {
			systemId: system.id,
			foundations: system.foundations,
			} 
      	});
      	setSystemCSS(res.data)
		} catch (error) {
		console.error("Error calling createAndUploadCSS:", error);
		}
		setShowUpdateButton(false);
    } 
	else {
      alert(`Please fill out the following fields: ${emptyFields.join(', ')}`);
    }
  } else {
    setPremiumModal('active');
  }
setUpdateButtonLoading(false);
};


const [selectedTab, setSelectedTab] = useState('colors');
const handleTabClick = (tab) => {
  setSelectedTab(tab);
};
  

  const handleDismiss = () => {
    setPremiumModal(false);  // Or any other logic you want
};


const handlePaymentCheckout = async (priceId) => {
  setStripeLoading(true)
  const error = await handleCheckout(priceId);
  if (error) {
    console.log(error)
  }
}

const hexToRgb = (hex) => {
	if (hex != undefined) {
	  const hexValue = hex.replace('#', '');
	  const r = parseInt(hexValue.substr(0, 2), 16);
	  const g = parseInt(hexValue.substr(2, 2), 16);
	  const b = parseInt(hexValue.substr(4, 2), 16);
	  const rgb = `${r}, ${g}, ${b}`;
	  return rgb;
	} else return ''
};

const c = system.foundations?.color?.[colorMode] || {};
const r = system.foundations?.radius?.[screenSize] || {};
const size = system.foundations?.size?.[screenSize] || {};
const space = system.foundations?.space?.[screenSize] || {};
const textFamily = system.foundations?.text?.family || {};
const text = system.foundations?.text?.[screenSize] || {};
const textWeight = system.foundations?.text?.weight || {};

const editorStyle = (`
	.editor {
		width: 100%;
		--size-icon: ${size.base}px;
		--size-small-controls: ${size.small}px;
		--size-large-controls: ${size.large}px;
		--ts-radius-small-control: ${r.smallControl}px;
		--ts-radius-large-control: ${r.largeControl}px;
		--ts-radius-profile: 1000px;
		--ts-radius-container: ${r.container}px;
		--ts-radius-page: ${r.page}px;

		--ts-space-v-sm-int: ${space.vInternalSmall}px;
		--ts-space-v-lg-int: ${space.vInternal}px;
		--ts-space-v-ext: ${space.vExternal}px;
		--ts-space-v-section: ${space.vSection}px;
		--ts-space-h-sm-int: ${space.hInternalSmall}px;
		--ts-space-h-lg-int: ${space.hInternal}px;
		--ts-space-h-ext: ${space.hExternal}px;
		--ts-space-h-section: ${space.hSection}px;
		--ts-space-text-offset: ${space.lineHeight}px;
		--ts-space-content: ${space.content}px;


		--ts-font-title-family: ${textFamily?.titleName ? `'${textFamily?.titleName}', `: ''}${textFamily?.titleFallback};
		--ts-font-family: ${textFamily?.name ? `'${textFamily?.name}', `: ''}${textFamily?.fallback};
		

		--ts-font-title1: ${textWeight.title1} ${text.title1Size / 16}rem/${text.title1LineHeight / 16}rem var(--ts-font-title-family);
		--ts-font-title1-light: ${textWeight.title1Light};
		--ts-font-title1-regular: ${textWeight.title1Regular};
		--ts-font-title1-bold: ${textWeight.title1Bold};
		--ts-font-title1-letter-spacing: ${text.title1LetterSpacing}em;
		
		--ts-font-title2: ${textWeight.title2} ${text.title2Size / 16}rem/${text.title2LineHeight / 16}rem var(--ts-font-title-family);
		--ts-font-title2-light: ${textWeight.title2Light};
		--ts-font-title2-regular: ${textWeight.title2Regular};
		--ts-font-title2-bold: ${textWeight.title2Bold};
		--ts-font-title2-letter-spacing: ${text.title2LetterSpacing}em;
		
		--ts-font-title3: ${textWeight.title3} ${text.title3Size / 16}rem/${text.title3LineHeight / 16}rem var(--ts-font-title-family);
		--ts-font-title3-light: ${textWeight.title3Light};
		--ts-font-title3-regular: ${textWeight.title3Regular};
		--ts-font-title3-bold: ${textWeight.title3Bold};
		--ts-font-title3-letter-spacing: ${text.title3LetterSpacing}em;
		
		--ts-font-headline: ${textWeight.headline} ${text.headlineSize / 16}rem/${text.headlineLineHeight / 16}rem var(--ts-font-family);
		--ts-font-headline-light: ${textWeight.headlineLight};
		--ts-font-headline-regular: ${textWeight.headlineRegular};
		--ts-font-headline-bold: ${textWeight.headlineBold};
		--ts-font-headline-letter-spacing: ${text.headlineLetterSpacing}em;
		
		--ts-font-body: ${textWeight.body} ${text.bodySize / 16}rem/${text.bodyLineHeight / 16}rem var(--ts-font-family);
		--ts-font-body-light: ${textWeight.bodyLight};
		--ts-font-body-regular: ${textWeight.bodyRegular};
		--ts-font-body-bold: ${textWeight.bodyBold};
		--ts-font-body-letter-spacing: ${text.bodyLetterSpacing}em;
		
		--ts-font-subhead: ${textWeight.subhead} ${text.subheadSize / 16}rem/${text.subheadLineHeight / 16}rem var(--ts-font-family);
		--ts-font-subhead-light: ${textWeight.subheadLight};
		--ts-font-subhead-regular: ${textWeight.subheadRegular};
		--ts-font-subhead-bold: ${textWeight.subheadBold};
		--ts-font-subhead-letter-spacing: ${text.subheadLetterSpacing}em;
		
		--ts-font-footnote: ${textWeight.footnote} ${text.footnoteSize / 16}rem/${text.footnoteLineHeight / 16}rem var(--ts-font-family);
		--ts-font-footnote-light: ${textWeight.footnoteLight};
		--ts-font-footnote-regular: ${textWeight.footnoteRegular};
		--ts-font-footnote-bold: ${textWeight.footnoteBold};
		--ts-font-footnote-letter-spacing: ${text.footnoteLetterSpacing}em;
		
		--ts-font-caption1: ${textWeight.caption1} ${text.caption1Size / 16}rem/${text.caption1LineHeight / 16}rem var(--ts-font-family);
		--ts-font-caption1-light: ${textWeight.caption1Light};
		--ts-font-caption1-regular: ${textWeight.caption1Regular};
		--ts-font-caption1-bold: ${textWeight.caption1Bold};
		--ts-font-caption1-letter-spacing: ${text.caption1LetterSpacing}em;
		
		--ts-font-caption2: ${textWeight.caption2} ${text.caption2Size / 16}rem/${text.caption2LineHeight / 16}rem var(--ts-font-family);
		--ts-font-caption2-light: ${textWeight.caption2Light};
		--ts-font-caption2-regular: ${textWeight.caption2Regular};
		--ts-font-caption2-bold: ${textWeight.caption2Bold};
		--ts-font-caption2-letter-spacing: ${text.caption2LetterSpacing}em;
		

		--animate-function--soft-start: cubic-bezier(1,0,1,1);
		--animate-function--soft-end: cubic-bezier(0,0,0,1);
		--animate-function--linear: linear;
		--animate-length--large: .8s;
		--animate-length--medium: .6s;
		--animate-length--small: .4s;

		--ts-color-fill-control: ${hexToRgb(c.fillControl)};
		--ts-color-fill-control-alpha: ${c.fillControlAlpha};
		--ts-color-fill-container: ${hexToRgb(c.fillContainer)};
		--ts-color-fill-container-alpha: ${c.fillContainerAlpha};
		--ts-color-background-flat: ${hexToRgb(c.backgroundFlat)};
		--ts-color-background-elevated: ${hexToRgb(c.backgroundElevated)};
		--ts-color-primary: ${hexToRgb(c.primary)};
		--ts-color-primary-alpha: ${c.primaryAlpha};
		--ts-color-on-primary: ${hexToRgb(c.onPrimary)};
		--ts-color-accent: ${hexToRgb(c.accent)};
		--ts-color-accent-alpha: ${c.accentAlpha};
		--ts-color-on-accent: ${hexToRgb(c.onAccent)};
		--ts-color-overlay: 0, 0, 0, .6;
		--ts-color-negative: ${hexToRgb(c.negative)};
		--ts-color-positive: ${hexToRgb(c.positive)};
		--ts-color-notice: ${hexToRgb(c.notice)};
		--ts-color-informative: ${hexToRgb(c.informative)};
		--ts-color-read-only: ${hexToRgb(c.readOnly)}, ${c.readOnlyAlpha};
		--ts-color-icon: ${hexToRgb(c.icon)}, ${c.iconAlpha};
		--ts-color-border: ${hexToRgb(c.border)}, ${c.borderAlpha};
		--ts-color-divider: ${hexToRgb(c.divider)};
		--ts-color-divider-alpha: ${c.dividerAlpha};
		--ts-color-text-primary: ${hexToRgb(c.textPrimary)};
		--ts-color-text-primary-alpha: ${c.textPrimaryAlpha};
		--ts-color-text-secondary: ${hexToRgb(c.textSecondary)};
		--ts-color-text-secondary: ${c.textSecondaryAlpha};
		--ts-color-surface-hover: ${hexToRgb(c.surfaceHover)};
		--ts-color-surface-hover-alpha: ${c.surfaceHoverAlpha};
		--ts-color-surface-down: ${hexToRgb(c.surfaceDown)};
		--ts-color-surface-down-alpha: ${c.surfaceDownAlpha};
		
		--elevation-nav: 0px 4px 7px rgba(0, 0, 0, 0.28);
		--elevation-modal: -160px 0px 195px 136px rgba(0, 0, 0, 0.25);
		--elevation-menu: 0px 10px 80px -2px rgba(0, 0, 0, 0.5);
		--elevation-accent: 0px 4px 4px 0px rgba(0, 0, 0, .25);
	}
`);

useEffect(() => {
console.log(system);
}, [system])


  if (isLoading) return ;

  return (
    <>
      <GlobalNav>
        <Section style={{paddingTop: '0px', paddingBottom: '0px'}}>
			<HStack>
				<Title2>
				{system.name}
				</Title2>
				<Space.Grow />
				{showUpdateButton && (
					<Button text1='Publish system' variant='primary' loading={updateButtonLoading} onClick={updateSystem}/>
					)}
				<Button text1='Delete' variant='destructive' onClick={confirmDeleteSystem}/>
			</HStack>
		</Section>
      </GlobalNav>

      
      <HStack style={{position: 'relative'}}>

      <div className='desktop tablet' style={{width: '100%', height: '100%', position: 'absolute', zIndex:'-1', left: '0px', top: '0px', display: 'flex', flexDirection: 'row'}}>
        <div style={{flex: '1', height: '100%', background: 'rgba(var(--color-background-elevated))', right: '0px', top: '0px'}}/>
        <div className='desktop tablet' style={{flex: '1', height: '100%', background: c.backgroundFlat, boxShadow: 'inset 4px 4px 12px rgba(0,0,0,.1)', right: '0px', top: '0px'}}/>
      </div>
		
		{/* Modals - Premium */}
		<Sheet.alert active={premiumModal} position='middle center' onDismiss={handleDismiss}>
			<div className='h-stack middle'>
				<Title3>Ready to Get Started?</Title3>        
				<div className='spacer'/>
				<Button small leadingIcon='Close' onClick={() => {setPremiumModal('')}}/>
			</div>
			<Body color='text-secondary'>
			We're thrilled to see you're interested in Themeable! To unlock the full experience and dive into everything we have to offer, let's get your subscription set up. By subscribing, you're gaining access to a single source of truth for your designers and developers and much more.
			</Body>

			<div className='new-section'/>
			<div className='h-stack'>
			<Button grow type='link' text1='Learn more' variant='tertiary' onClick={() => navigate()}/>
			<Button grow text1='Subscribe' variant='primary' loading={stripeLoading} onClick={() => handlePaymentCheckout('price_1Nf8ItHUEMXMwifTAxyefQ8d')} />
			</div>          
		</Sheet.alert>
      	
		<HStack grow layout="top" gridDesktop={2} gridTablet={2} gridMobile={1}>
      
			{/* Left side (form) */}
			<VStack grow>
				<Section>
					
					<Container variant="outline">
					<Input label='System name' variant='fill' value={system.name} onChange={(e) => handleInputChange('name', 'name', e)} />
					{systemCSS ? (
					<>
					<Group>
						<Subhead color={'text-secondary'}>
						Embed link:
						</Subhead>
						<Caption1 color={'text-secondary'} style={{wordBreak: 'break-word'}}>
						{`<link rel="stylesheet" href="https://us-central1-themeable-ce1f4.cloudfunctions.net/styles/${system.id}">`}
						</Caption1>
					</Group>
					</>

					): ''}
					</Container>
					
					{/* Chip tabs */}
					<Space.NewSection/>
					<VStack style={{position: 'sticky', zIndex: '2', top: '65px', background: 'rgb(var(--color-background-elevated))'}}>

					<HStack>
						<Group>
							<RadioChip label="Colors" selected={selectedTab === 'colors'} onClick={() => handleTabClick('colors')}/>
							<RadioChip label="Radius" selected={selectedTab === 'radius'} onClick={() => handleTabClick('radius')}/>
							<RadioChip label="Spacing" selected={selectedTab === 'spacing'} onClick={() => handleTabClick('spacing')}/>
							<RadioChip label="Size" selected={selectedTab === 'size'} onClick={() => handleTabClick('size')}/>
							<RadioChip label="Text" selected={selectedTab === 'text'} onClick={() => handleTabClick('text')}/>
						</Group>
					</HStack>

					<Divider nested/>

					</VStack>

					<HStack>

					<VStack grow>
						{selectedTab === 'colors' && (
						<>
						<VStack style={{position: 'sticky', zIndex: '2', top: '113px', background: 'rgb(var(--color-background-elevated))'}}>

							<HStack layout='middle'>
							<Subhead>
								Color modes:
							</Subhead>
							<RadioChip label="Light" selected={colorMode === 'light'} onClick={() => setColorMode('light')}/>
							<RadioChip label="Dark" selected={colorMode === 'dark'} onClick={() => setColorMode('dark')}/>
							</HStack>

							<Divider nested/>

						</VStack>
							<div className='new-section'/>
							<Header type={'title2'} title='Colors'/>
						<VStack grow>
						<Container variant="outline">
							<Header type={'title3'} title={'Prominent colors'}/>
							<Footnote color={'text-secondary'}>
								The "Primary" and "Accent" are your standout colors, making your UI pop. Colors labeled "On Primary" or "On Accent" sit on top of these main shades. Please note that, for certain component elements, opacity value may be ignored and in others, opacity will be calculated dynamically for you.
							</Footnote>
							<HStack>
							<InputColor label='Primary' variant='fill' value={c.primary} onChange={(value) => handleInputChange('color', 'primary', value)} />
							<Input label='Opacity (0 - 1)' variant='fill' value={c.primaryAlpha} onChange={(value) => handleInputChange('color', 'primaryAlpha', value)} />
							</HStack>
							<HStack grow>
							<InputColor label='On Primary' variant='fill' value={c.onPrimary} onChange={(value) => handleInputChange('color', 'onPrimary', value)}/>
							<Space.Grow/>
							</HStack>
							<HStack>
							<InputColor label='Accent' variant='fill' value={c.accent} onChange={(value) => handleInputChange('color','accent', value)} />
							<Input label='Opacity (0 - 1)' variant='fill' value={c.accentAlpha} onChange={(e) => handleInputChange('color', 'accentAlpha', e)} />
							</HStack>
							<HStack grow>
							<InputColor label='On Accent' variant='fill' value={c.onAccent} onChange={(e) => handleInputChange('color', 'onAccent', e)}/>
							<Space.Grow/>
							</HStack>
						</Container>

						<Container variant="outline">
							<Header type={'title3'} title={'Text colors'}/>
								<Footnote color={'text-secondary'}>
								Text colors comprise the substance of your page's content. The "Primary" shade should exhibit heightened legibility, while the "Secondary" shade must harmonize with "Primary" by providing complementary aesthetics without sacrificing readability.
								</Footnote>
							<HStack>



								<InputColor label='Text primary' variant='fill' value={c.textPrimary} onChange={(e) => handleInputChange('color', 'textPrimary', e)}/>
								
								
								
								<Input label='Opacity (0 - 1)' variant='fill' value={c.textPrimaryAlpha} onChange={(e) => handleInputChange('color', 'textPrimaryAlpha', e)}/>
							</HStack>

							<HStack>
								<InputColor label='Text secondary' variant='fill' value={c.textSecondary} onChange={(e) => handleInputChange('color', 'textSecondary', e)}/>
								<Input label='Opacity (0 - 1)' variant='fill' value={c.textSecondaryAlpha} onChange={(e) => handleInputChange('color', 'textSecondaryAlpha', e)}/>
							</HStack>
						</Container>
					
						<Container variant="outline">
							<Header type='title3' title={'Background colors'}/>
								<Footnote color={'text-secondary'}>
									Background colors establish the base of your website. They should be subtle and progressively get lighter (both in light and dark mode) for each level of elevation.
								</Footnote>
							<HStack>
							<InputColor label='Background (elevated)' variant='fill' value={c.backgroundElevated} onChange={(e) => handleInputChange('color', 'backgroundElevated', e)}/>
							<Space.Grow/>
							</HStack>
							<HStack>
							<InputColor label='Background (flat)' variant='fill' value={c.backgroundFlat} onChange={(e) => handleInputChange('color', 'backgroundFlat', e)}/>
							<Space.Grow/>
							</HStack>
						</Container>  

						<Container variant="outline">
							<Header type='title3' title='Surface colors'/>
								<Footnote color={'text-secondary'}>
									Surfaces are used for interaction. A Surface is transparent while resting, and then for hover/down, take on different shades of black/gray with opacity. Be careful not to make the down state too dark as it can be used in some situations to indicate a selected tab.
								</Footnote>
							<HStack>
							<InputColor label='Surface hover' variant='fill' value={c.surfaceHover} onChange={(e) => handleInputChange('color', 'surfaceHover', e)}/>
							<Input label='Opacity (0 - 1)' variant='fill' value={c.surfaceHoverAlpha} onChange={(e) => handleInputChange('color', 'surfaceHoverAlpha', e)}/>
							</HStack>
							<HStack>
							<InputColor label='Surface down' variant='fill' value={c.surfaceDown} onChange={(e) => handleInputChange('color', 'surfaceDown', e)}/>
							<Input label='Opacity (0 - 1)' variant='fill' value={c.surfaceDownAlpha} onChange={(e) => handleInputChange('color', 'surfaceDownAlpha', e)}/>
							</HStack>
						</Container>

						<Container variant="outline">
							<Header type='title3' title='Fills'/>
								<Footnote color={'text-secondary'}>
								The "Fill" color falls closely in alignment with iOS, but instead of offereing "Primary, Secondary, Tertiary, Quaternary" options, it declares a specific fill for controls that a user would interact with, and a specific fill for elements that are purely containers and don't have interaction.
								</Footnote>
							<HStack>
							<InputColor label='Fill container' variant='fill' value={c.fillContainer} onChange={(e) => handleInputChange('color', 'fillContainer', e)}/>
							<Input label='Opacity (0 - 1)' variant='fill' value={c.fillContainerAlpha} onChange={(e) => handleInputChange('color', 'fillContainerAlpha', e)}/>
							</HStack>
							<HStack>
							<InputColor label='Fill control' variant='fill' value={c.fillControl} onChange={(e) => handleInputChange('color', 'fillControl', e)}/>
							<Input label='Opacity (0 - 1)' variant='fill' value={c.fillControlAlpha} onChange={(e) => handleInputChange('color', 'fillControlAlpha', e)}/>
							</HStack>
						</Container>

						<Container variant="outline">
							<Header type='title3' title='Notification colors'/>
								<Footnote color={'text-secondary'}>
								These colors are used for providing a status for different elements. Opacities for these are generated dynamically. 
								</Footnote>
							<HStack>
							<InputColor label='Positive' variant='fill' value={c.positive} onChange={(e) => handleInputChange('color', 'positive', e)}/>
							<Space.Grow/>
							</HStack>
							<HStack>
							<InputColor label='Informative' variant='fill' value={c.informative} onChange={(e) => handleInputChange('color', 'informative', e)}/>
							<Space.Grow/>
							</HStack>
							<HStack>
							<InputColor label='Negative' variant='fill' value={c.negative} onChange={(e) => handleInputChange('color', 'negative', e)}/>
							<Space.Grow/>
							</HStack>
							<HStack>
							<InputColor label='Notice' variant='fill' value={c.notice} onChange={(e) => handleInputChange('color', 'notice', e)}/>
							<Space.Grow/>
							</HStack>
						</Container>
								
					
						<Container variant="outline">
							
							<Header type='title3' title='Other' />
							<HStack>
							<InputColor label='Icon' variant='fill' value={c.icon} onChange={(e) => handleInputChange('color', 'icon', e)}/>
							<Input label='Opacity (0 - 1)' variant='fill' value={c.iconAlpha} onChange={(e) => handleInputChange('color', 'iconAlpha', e)}/>
							</HStack>
								<Footnote color={'text-secondary'}>
								Icon colors are typically more subtle than text colors as they are weighted more heavily.
								</Footnote>

							<Space.NewSection/>

							<HStack>
							<InputColor label='Border' variant='fill' value={c.border} onChange={(e) => handleInputChange('color', 'border', e)} />
							<Input label='Opacity (0 - 1)' variant='fill' value={c.borderAlpha} onChange={(e) => handleInputChange('color', 'borderAlpha', e)} />
							</HStack>

								<Footnote color={'text-secondary'}>
								Borders help accent the bounds of where a component starts/ends.
								</Footnote>
							
							<Space.NewSection/>

							<HStack>
							<InputColor label='Divider' variant='fill' value={c.divider} onChange={(e) => handleInputChange('color', 'divider', e)} />
							<Input label='Opacity (0 - 1)' variant='fill' value={c.dividerAlpha} onChange={(e) => handleInputChange('color', 'dividerAlpha', e)} />
							</HStack>

							
								<Footnote color={'text-secondary'}>
								Dividers break up sections. For cells, they can provide an extra affordance that it is interactive.
								</Footnote>
							

							<Space.NewSection/>
							<HStack>
							<InputColor label='Read only' variant='fill' value={c.readOnly} onChange={(e) => handleInputChange('color', 'readOnly', e)} />
							<Input label='Opacity (0 - 1)' variant='fill' value={c.readOnlyAlpha} onChange={(e) => handleInputChange('color', 'readOnlyAlpha', e)} />
							</HStack>
							
								<Footnote color={'text-secondary'}>
								Read only color is used for elements that are not as important to the user. They help show the relationship between elements. They don't necessarily need to be readable but can be more of a decorative color that helps provide context. An example could be "Search" text inside a search box. Or the placeholder text in a text input of "John Smith". It should be clear that it is not "user entered text".
								</Footnote>

						</Container>
						
						</VStack>
							


					</>
						)}
						{selectedTab === 'radius' && (
						<>
						<VStack style={{position: 'sticky', zIndex: '2', top: '113px', background: 'rgb(var(--color-background-elevated))'}}>
							<HStack layout='middle'>
								<Subhead>
									Screen sizes:
								</Subhead>
								<RadioChip label="Compact" selected={screenSize === 'compact'} onClick={() => setScreenSize('compact')}/>
								<RadioChip label="Medium" selected={screenSize === 'medium'} onClick={() => setScreenSize('medium')}/>
								<RadioChip label="Expanded" selected={screenSize === 'expanded'} onClick={() => setScreenSize('expanded')}/>
							</HStack>
							<Divider nested/>
						</VStack>
						<div className='new-section'/>
							<Header type={'title2'} title='Radius'/>
								<Container variant="outline">
									<Header type={'title3'} title={'Radius'}/>
									<Footnote color={'text-secondary'}>
										Rather than having a system of "large radius", and "small radius", this is a semantic approach. Each radius here applies to a specific style/class of component. The radii doesn't have to follow a hierarchy of "large control = larger radius", etc.
									</Footnote>
									<Space.NewSection/>

									<Input label='Large controls' variant='fill' value={r.largeControl} onChange={(e) => handleInputChange('radius', 'largeControl', e)} />
									<Footnote color={'text-secondary'}>
										Border radius for large controls.
									</Footnote>
									<Space.NewSection/>

									<Input label='Small controls' variant='fill' value={r.smallControl} onChange={(e) => handleInputChange('radius', 'smallControl', e)} />
									<Footnote color={'text-secondary'}>
										Border radius for small controls such as chips.
									</Footnote>
									<Space.NewSection/>

									<Input label='Containers' variant='fill' value={r.container} onChange={(e) => handleInputChange('radius', 'container', e)} />
									<Footnote color={'text-secondary'}>
										For containers that hold content.
									</Footnote>
									<Space.NewSection/>

									<Input label='Pages/Modals' variant='fill' value={r.page} onChange={(e) => handleInputChange('radius', 'page', e)} />
									<Footnote color={'text-secondary'}>
										For modals and large 'page like' elements that take up a large amount of the screen and act as a secondary overlayed page.
									</Footnote>

								</Container>
								<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
								


						</>
						)}
						{selectedTab === 'spacing' && (
						<>
						<VStack style={{position: 'sticky', zIndex: '2', top: '113px', background: 'rgb(var(--color-background-elevated))'}}>
							<HStack layout='middle'>
								<Subhead>
									Screen sizes:
								</Subhead>
								<RadioChip label="Compact" selected={screenSize === 'compact'} onClick={() => setScreenSize('compact')}/>
								<RadioChip label="Medium" selected={screenSize === 'medium'} onClick={() => setScreenSize('medium')}/>
								<RadioChip label="Expanded" selected={screenSize === 'expanded'} onClick={() => setScreenSize('expanded')}/>
							</HStack>
							<Divider nested/>
						</VStack>

							<div className='new-section'/>
							<Header type={'title2'} title='Spacing'/>
							
							<Container variant="outline">
								
								<Header type={'title3'} title={'Content'}/>
								<Input label='Space between' variant='fill' value={space.content} onChange={(e) => handleInputChange('space', 'content', e)} />
								<Footnote color={'text-secondary'}>
									Defines the space between content elements. Such as, if you have an icon inline next to a text element, this is the space between those two elements.
								</Footnote>
								<Space.NewSection/>
								
								
								<Input label='Text offset' variant='fill' value={space.lineHeight} onChange={(e) => handleInputChange('space', 'lineHeight', e)} />
								<Footnote color={'text-secondary'}>
									When dealing with iconography, an icon typically has a baked in padding of 4px. This means that when looking at the vertical alignment on a page, icons and text don't always line up. "Text offset" gives the option to make text behave the same way. Vertical offset is already accounted for with "line height".
								</Footnote>

							</Container>
								
								<Container variant={'outline'}>

								<Header type={'title3'} title={'Section'}/>
								<Footnote color={'text-secondary'}>
									In addition to the baked in spacing already provided to each component, this provides an extra padding for each section. For instance, if your card has a horizontal margin of 8, and you want the space between the card and the edge of the window to be 16, then the Horizontal section should be "8".
								</Footnote>
								<Input label='Vertical section' variant='fill' value={space.vSection} onChange={(e) => handleInputChange('space', 'vSection', e)} />

								<Input label='Horizontal section' variant='fill' value={space.hSection} onChange={(e) => handleInputChange('space', 'hSection', e)} />
								</Container>

							



							<Container variant="outline">
							<Header type={'title3'} title={'External space'}/>
								<Footnote color={'text-secondary'}>
									This is the spacing between each component. Component margins can be a combination of "external space" and "Internal space + text offset or small internal space". It really requires some playing with the values to get an understanding of how the sizes work together.
								</Footnote>
							<Input label='External Space (horizontally)' variant='fill' value={space.hExternal} onChange={(e) => handleInputChange('space', 'hExternal', e)} />
							<Input label='External Space (vertically)' variant='fill' value={space.vExternal} onChange={(e) => handleInputChange('space', 'vExternal', e)} />
							</Container>
							
							<Container variant="outline">
							<Header type={'title3'} title={'Internal padding'}/>
								<Footnote color={'text-secondary'}>
									This is the internal padding for large components. Horizontally, a component can be made up of External space + Internal space + text offset. Vertically, component space can be made up of External space + internal space + small internal space.
								</Footnote>
							<Input label='Horizontal padding' variant='fill' value={space.hInternal} onChange={(e) => handleInputChange('space', 'hInternal', e)} />
							<Input label='Vertical padding' variant='fill' value={space.vInternal} onChange={(e) => handleInputChange('space', 'vInternal', e)} />
							</Container>
							
							<Container variant="outline">
							<Header type={'title3'} title={'Internal padding (small components)'}/>
								<Footnote color={'text-secondary'}>
									This is the internal padding for small components. In addition to this, a min-height and min-width is applied from the "Small size" token.
								</Footnote>
							<Input label='Horizontal padding' variant='fill' value={space.hInternalSmall} onChange={(e) => handleInputChange('space', 'hInternalSmall', e)} />
							<Input label='Vertical padding' variant='fill' value={space.vInternalSmall} onChange={(e) => handleInputChange('space', 'vInternalSmall', e)} />
							</Container>

						</>
						)}
						{selectedTab === 'size' && (
						<>
						<VStack style={{position: 'sticky', zIndex: '2', top: '113px', background: 'rgb(var(--color-background-elevated))'}}>
							<HStack layout='middle'>
								<Subhead>
									Screen sizes:
								</Subhead>
								<RadioChip label="Compact" selected={screenSize === 'compact'} onClick={() => setScreenSize('compact')}/>
								<RadioChip label="Medium" selected={screenSize === 'medium'} onClick={() => setScreenSize('medium')}/>
								<RadioChip label="Expanded" selected={screenSize === 'expanded'} onClick={() => setScreenSize('expanded')}/>
							</HStack>
							<Divider nested/>
						</VStack>
						<div className='new-section'/>
							<Header type={'title2'} title='Size'/>
							<VStack layout="top">
								<Container variant="outline">
									<Input label='Base size' variant='fill' value={size.base} onChange={(e) => handleInputChange('size', 'base', e)} />
									<Footnote color={'text-secondary'}>
										The base size for your elements. This applies to line height and iconography. Support is limited.
									</Footnote>
									<Space.NewSection/>
									
									<Input label='Small controls (min-height)' variant='fill' value={size.small} onChange={(e) => handleInputChange('size', 'small', e)} />
									
									<Footnote color={'text-secondary'}>
										The minimum width and height of small components such as chips.
									</Footnote>
									
									<Space.NewSection/>
									
									<Input label='Large controls (min-height)' variant='fill' value={size.large} onChange={(e) => handleInputChange('size', 'large', e)} />
									
									<Footnote color={'text-secondary'}>
										The minimum width and height of large components such as cells, large buttons, text inputs, etc.
									</Footnote>
									

								</Container>
							</VStack>


							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
							<Space.NewSection/>
						</>
						)}
						{selectedTab === 'text' && (
							<>
							<Space.NewSection/>
							<TextForm 
								system={ system } 
								screenSize={ screenSize }
								handleInputChange={ handleInputChange }
								setScreenSize={ setScreenSize }
								textFamily={ textFamily }
								/>
							</>
						)}
					</VStack>

					</HStack>
				</Section>
			</VStack>      

			<VStack layout='top' className='desktop tablet' style={{ background: c.backgroundFlat, height: '100%' }}>
				<VStack style={{position: 'sticky', top: '80px', marginTop: '30px'}}>
				<link href={textFamily?.titleSrc} rel="stylesheet" />
				<link href={textFamily?.src} rel="stylesheet" />
				<style>{editorStyle}</style>
				<span className='editor'>
					<Section>
						<HStack grow>


						{selectedTab === 'text' ? (
						<SampleText 
							/>
						):(
						<>
						<SampleElements 
							system={ system }
							colorMode={ colorMode }
							screenSize={ screenSize }
							/>
						</>
					)}
					</HStack>
				</Section>
			</span>
			</VStack>

	</VStack>
			

      </HStack>
      </HStack>


    </>
  );
};

export default Systems;
import React, {useMemo} from 'react'
import { Group, HStack, Headline, Space, VStack } from '@themeable/react-components'

const LogoGraphic = ({ width }) => {
	const uniqueId = useMemo(() => Math.random().toString(36).slice(2, 11), []);
  
	return (
		<svg style={{width: `${width ?width:'40px'}`}} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
			<defs>
				<linearGradient id={`paint0_linear_${uniqueId}`} x1="37.5833" y1="7.33333" x2="27.5" y2="14.6667" gradientUnits="userSpaceOnUse">
				<stop stopColor="#9155FE"/>
				<stop offset="1" stopColor="#1EB0FE"/>
				</linearGradient>
				<linearGradient id={`paint1_linear_${uniqueId}`} x1="5.5" y1="5.5" x2="18.3333" y2="14.6667" gradientUnits="userSpaceOnUse">
				<stop stopColor="#F2532E"/>
				<stop offset="0.392526" stopColor="#AD8BC2"/>
				<stop offset="0.520516" stopColor="#8594DD"/>
				<stop offset="1" stopColor="#1BB6FF"/>
				</linearGradient>
				<linearGradient id={`paint2_linear_${uniqueId}`} x1="18.3333" y1="14.6666" x2="25.6667" y2="39.4166" gradientUnits="userSpaceOnUse">
				<stop stopColor="#1AB5FF"/>
				<stop offset="1" stopColor="#0EC979"/>
				</linearGradient>
			</defs>
			<path d="M27.5 5.5H29.3333C32.7603 5.5 34.4737 5.5 35.75 6.23686C36.5861 6.71959 37.2804 7.41389 37.7631 8.25C38.5 9.52628 38.5 11.2397 38.5 14.6667H27.5V5.5Z" fill={`url(#paint0_linear_${uniqueId})`}/>
			<path d="M5.5 5.5H9.16667C12.5936 5.5 14.3071 5.5 15.5833 6.23686C16.4194 6.71959 17.1137 7.41389 17.5965 8.25C18.3333 9.52628 18.3333 11.2397 18.3333 14.6667H5.5V5.5Z" fill={`url(#paint1_linear_${uniqueId})`}/>
			<path d="M18.3333 14.6666H27.5V31.1666C27.5 34.5935 27.5 36.307 26.7632 37.5833C26.2804 38.4194 25.5861 39.1137 24.75 39.5964C23.4737 40.3333 21.7603 40.3333 18.3333 40.3333V14.6666Z" fill={`url(#paint2_linear_${uniqueId})`}/>
		</svg>



// <svg className="logo" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
// <defs>
//   <linearGradient id={`paint0_linear_${uniqueId}`} x1="20.5" y1="4" x2="15" y2="8" gradientUnits="userSpaceOnUse">
// 	<stop stopColor="#9155FE"/>
// 	<stop offset="1" stopColor="#1EB0FE"/>
//   </linearGradient>
//   <linearGradient id={`paint1_linear_${uniqueId}`} x1="3" y1="3" x2="10" y2="8" gradientUnits="userSpaceOnUse">
// 	<stop stopColor="#F2532E"/>
// 	<stop offset="0.392526" stopColor="#AD8BC2"/>
// 	<stop offset="0.520516" stopColor="#8594DD"/>
// 	<stop offset="1" stopColor="#1BB6FF"/>
//   </linearGradient>
//   <linearGradient id={`paint2_linear_${uniqueId}`} x1="10" y1="8" x2="14" y2="21.5" gradientUnits="userSpaceOnUse">
// 	<stop stopColor="#1AB5FF"/>
// 	<stop offset="1" stopColor="#0EC979"/>
//   </linearGradient>
// </defs>
// <path d="M15 3H16C17.8692 3 18.8038 3 19.5 3.40192C19.9561 3.66523 20.3348 4.04394 20.5981 4.5C21 5.19615 21 6.13077 21 8V8H15V3Z" fill={`url(#paint0_linear_${uniqueId})`}/>
// <path d="M3 3H5C6.86923 3 7.80385 3 8.5 3.40192C8.95606 3.66523 9.33477 4.04394 9.59808 4.5C10 5.19615 10 6.13077 10 8V8H3V3Z" fill={`url(#paint1_linear_${uniqueId})`}/>
// <path d="M10 8H15V17C15 18.8692 15 19.8038 14.5981 20.5C14.3348 20.9561 13.9561 21.3348 13.5 21.5981C12.8038 22 11.8692 22 10 22V22V8Z" fill={`url(#paint2_linear_${uniqueId})`}/>
// </svg>

	);
  };

  const LogoText = () => {
	const uniqueId = useMemo(() => Math.random().toString(36).slice(2, 11), []);
  
	return (
		<svg width="120" viewBox="0 0 148 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M140.645 21.9868C139.127 21.9868 137.783 21.6688 136.613 21.0328C135.464 20.3762 134.562 19.4632 133.905 18.2938C133.269 17.1038 132.951 15.7087 132.951 14.1084V13.7391C132.951 12.1387 133.269 10.7539 133.905 9.5844C134.541 8.39442 135.434 7.48142 136.583 6.8454C137.731 6.18887 139.065 5.8606 140.583 5.8606C142.081 5.8606 143.384 6.19912 144.492 6.87618C145.6 7.53272 146.461 8.45597 147.077 9.64595C147.692 10.8154 148 12.1798 148 13.7391V15.0624H136.89C136.931 16.1087 137.321 16.9602 138.06 17.6167C138.798 18.2733 139.701 18.6015 140.768 18.6015C141.855 18.6015 142.655 18.3656 143.168 17.8937C143.681 17.4218 144.071 16.8986 144.338 16.3242L147.508 17.986C147.22 18.5195 146.8 19.1042 146.246 19.7402C145.712 20.3557 144.994 20.8892 144.092 21.3405C143.189 21.7714 142.04 21.9868 140.645 21.9868ZM136.921 12.1695H144.061C143.979 11.2873 143.62 10.5795 142.984 10.046C142.368 9.51259 141.558 9.24587 140.553 9.24587C139.506 9.24587 138.675 9.51259 138.06 10.046C137.444 10.5795 137.065 11.2873 136.921 12.1695Z" fill="rgba(var(--color-text-primary))"/>
			<path d="M127.488 21.556V0.0133057H131.366V21.556H127.488Z" fill="rgba(var(--color-text-primary))"/>
			<path d="M119.007 21.9868C117.632 21.9868 116.576 21.7509 115.837 21.279C115.099 20.8071 114.555 20.2839 114.206 19.7094H113.652V21.556H109.836V0.0133057H113.714V8.04564H114.268C114.493 7.67633 114.791 7.32755 115.16 6.99928C115.55 6.67101 116.053 6.40429 116.668 6.19912C117.304 5.97344 118.084 5.8606 119.007 5.8606C120.238 5.8606 121.377 6.16835 122.423 6.78385C123.469 7.37884 124.311 8.26106 124.947 9.43052C125.583 10.6 125.901 12.0156 125.901 13.6775V14.1699C125.901 15.8318 125.583 17.2474 124.947 18.4169C124.311 19.5863 123.469 20.4788 122.423 21.0943C121.377 21.6893 120.238 21.9868 119.007 21.9868ZM117.838 18.6015C119.028 18.6015 120.023 18.222 120.823 17.4629C121.623 16.6832 122.023 15.5548 122.023 14.0776V13.7698C122.023 12.2926 121.623 11.1744 120.823 10.4153C120.043 9.63569 119.048 9.24587 117.838 9.24587C116.648 9.24587 115.653 9.63569 114.852 10.4153C114.052 11.1744 113.652 12.2926 113.652 13.7698V14.0776C113.652 15.5548 114.052 16.6832 114.852 17.4629C115.653 18.222 116.648 18.6015 117.838 18.6015Z" fill="rgba(var(--color-text-primary))"/>
			<path d="M98.3042 21.9868C97.2168 21.9868 96.2422 21.8022 95.3805 21.4329C94.5188 21.043 93.8315 20.4891 93.3186 19.771C92.8262 19.0324 92.58 18.1399 92.58 17.0935C92.58 16.0472 92.8262 15.1752 93.3186 14.4777C93.8315 13.7596 94.5291 13.2261 95.4113 12.8773C96.314 12.508 97.3399 12.3234 98.4888 12.3234H102.674V11.4617C102.674 10.7436 102.449 10.1589 101.997 9.7075C101.546 9.23561 100.828 8.99967 99.8429 8.99967C98.8786 8.99967 98.1606 9.22535 97.6887 9.67672C97.2168 10.1076 96.909 10.6718 96.7654 11.3694L93.1955 10.1691C93.4417 9.38949 93.8315 8.68166 94.3649 8.04564C94.9189 7.3891 95.6473 6.86592 96.55 6.4761C97.4732 6.06576 98.5914 5.8606 99.9045 5.8606C101.915 5.8606 103.505 6.36326 104.675 7.36858C105.844 8.3739 106.429 9.8306 106.429 11.7387V17.4321C106.429 18.0476 106.716 18.3553 107.291 18.3553H108.522V21.556H105.936C105.177 21.556 104.552 21.3713 104.059 21.002C103.567 20.6327 103.321 20.1403 103.321 19.5248V19.494H102.736C102.654 19.7402 102.469 20.0685 102.182 20.4788C101.895 20.8686 101.443 21.2174 100.828 21.5252C100.212 21.8329 99.371 21.9868 98.3042 21.9868ZM98.9812 18.8477C100.069 18.8477 100.951 18.5502 101.628 17.9553C102.325 17.3398 102.674 16.5293 102.674 15.524V15.2163H98.7658C98.0477 15.2163 97.4835 15.3701 97.0732 15.6779C96.6628 15.9856 96.4577 16.4165 96.4577 16.9704C96.4577 17.5244 96.6731 17.9758 97.1039 18.3246C97.5348 18.6733 98.1606 18.8477 98.9812 18.8477Z" fill="rgba(var(--color-text-primary))"/>
			<path d="M83.8263 21.9868C82.3081 21.9868 80.9642 21.6688 79.7948 21.0328C78.6458 20.3762 77.7431 19.4632 77.0866 18.2938C76.4505 17.1038 76.1325 15.7087 76.1325 14.1084V13.7391C76.1325 12.1387 76.4505 10.7539 77.0866 9.5844C77.7226 8.39442 78.6151 7.48142 79.764 6.8454C80.9129 6.18887 82.2465 5.8606 83.7648 5.8606C85.2625 5.8606 86.5653 6.19912 87.6732 6.87618C88.7811 7.53272 89.6429 8.45597 90.2584 9.64595C90.8739 10.8154 91.1816 12.1798 91.1816 13.7391V15.0624H80.0718C80.1128 16.1087 80.5026 16.9602 81.2412 17.6167C81.9798 18.2733 82.8826 18.6015 83.9494 18.6015C85.0368 18.6015 85.837 18.3656 86.3499 17.8937C86.8628 17.4218 87.2526 16.8986 87.5194 16.3242L90.6892 17.986C90.402 18.5195 89.9814 19.1042 89.4274 19.7402C88.894 20.3557 88.1759 20.8892 87.2732 21.3405C86.3704 21.7714 85.2215 21.9868 83.8263 21.9868ZM80.1025 12.1695H87.2424C87.1603 11.2873 86.8013 10.5795 86.1653 10.046C85.5498 9.51259 84.7393 9.24587 83.734 9.24587C82.6877 9.24587 81.8567 9.51259 81.2412 10.046C80.6257 10.5795 80.2462 11.2873 80.1025 12.1695Z" fill="rgba(var(--color-text-primary))"/>
			<path d="M52.1245 21.556V6.29149H55.9407V7.95335H56.4946C56.7613 7.44043 57.2024 6.99932 57.818 6.63002C58.4335 6.2402 59.2439 6.04529 60.2492 6.04529C61.3366 6.04529 62.2086 6.26071 62.8651 6.69157C63.5216 7.1019 64.0243 7.6456 64.3731 8.32265H64.927C65.2758 7.66611 65.7682 7.12242 66.4042 6.69157C67.0403 6.26071 67.943 6.04529 69.1125 6.04529C70.0562 6.04529 70.9077 6.25046 71.6668 6.66079C72.4464 7.05061 73.0619 7.65586 73.5133 8.47653C73.9852 9.27668 74.2211 10.2923 74.2211 11.5233V21.556H70.3435V11.8003C70.3435 10.9591 70.128 10.3333 69.6972 9.92296C69.2663 9.49211 68.6611 9.27669 67.8815 9.27669C66.9992 9.27669 66.3119 9.56392 65.8195 10.1384C65.3476 10.6923 65.1117 11.4925 65.1117 12.5389V21.556H61.234V11.8003C61.234 10.9591 61.0186 10.3333 60.5877 9.92296C60.1569 9.49211 59.5516 9.27669 58.772 9.27669C57.8898 9.27669 57.2025 9.56392 56.71 10.1384C56.2382 10.6923 56.0022 11.4925 56.0022 12.5389V21.556H52.1245Z" fill="rgba(var(--color-text-primary))"/>
			<path d="M42.8385 21.9868C41.3203 21.9868 39.9765 21.6688 38.807 21.0328C37.6581 20.3762 36.7553 19.4632 36.0988 18.2938C35.4628 17.1038 35.1447 15.7087 35.1447 14.1084V13.7391C35.1447 12.1387 35.4628 10.7539 36.0988 9.5844C36.7348 8.39442 37.6273 7.48142 38.7762 6.8454C39.9252 6.18887 41.2588 5.8606 42.777 5.8606C44.2747 5.8606 45.5775 6.19912 46.6855 6.87618C47.7934 7.53272 48.6551 8.45597 49.2706 9.64595C49.8861 10.8154 50.1938 12.1798 50.1938 13.7391V15.0624H39.084C39.125 16.1087 39.5148 16.9602 40.2534 17.6167C40.992 18.2733 41.8948 18.6015 42.9617 18.6015C44.049 18.6015 44.8492 18.3656 45.3621 17.8937C45.875 17.4218 46.2649 16.8986 46.5316 16.3242L49.7014 17.986C49.4142 18.5195 48.9936 19.1042 48.4396 19.7402C47.9062 20.3557 47.1881 20.8892 46.2854 21.3405C45.3826 21.7714 44.2337 21.9868 42.8385 21.9868ZM39.1147 12.1695H46.2546C46.1725 11.2873 45.8135 10.5795 45.1775 10.046C44.562 9.51259 43.7515 9.24587 42.7462 9.24587C41.6999 9.24587 40.8689 9.51259 40.2534 10.046C39.6379 10.5795 39.2584 11.2873 39.1147 12.1695Z"  fill="rgba(var(--color-text-primary))"/>
			<path d="M18.4401 21.556V0.0133057H22.3177V8.16874H22.8717C23.0358 7.84047 23.2923 7.5122 23.6411 7.18393C23.9899 6.85566 24.4515 6.58894 25.026 6.38377C25.6209 6.15809 26.3698 6.04525 27.2726 6.04525C28.4625 6.04525 29.4986 6.32223 30.3808 6.87618C31.2836 7.40962 31.9812 8.15848 32.4736 9.12277C32.966 10.0665 33.2122 11.1745 33.2122 12.4465V21.556H29.3345V12.7542C29.3345 11.6053 29.0473 10.7436 28.4728 10.1691C27.9188 9.59466 27.1187 9.30742 26.0723 9.30742C24.8823 9.30742 23.9591 9.7075 23.3025 10.5077C22.646 11.2873 22.3177 12.3849 22.3177 13.8006V21.556H18.4401Z" fill="rgba(var(--color-text-primary))"/>
			<path d="M6.27814 21.556V3.70633H0V0.0133057H16.6186V3.70633H10.3405V21.556H6.27814Z" fill="rgba(var(--color-text-primary))"/>
		</svg>
	);
  };




function LogoHorizontal() {
  return (
	<HStack layout='middle'>
		<Group>
			<LogoGraphic />
			<Space.Content />
			<LogoText />
		</Group>
	</HStack>
  )
}

function LogoVertical() {
  return (
	<VStack layout='center'>
		<Group>
			{/* <div class="logo-container h-stack center"> */}
				<LogoGraphic />
			{/* </div> */}
			<Space.Content />
			<LogoText />
		</Group>
	</VStack>
  )
}

function Logo({ width }) {
  return (
	<VStack layout='center'>
		<Group>
			{/* <div class="logo-container h-stack center"> */}
				<LogoGraphic width={width}/>
			{/* </div> */}
		</Group>
	</VStack>
  )

}

export { LogoHorizontal, LogoVertical, Logo }
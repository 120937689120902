import React from 'react'
import './TreeArrow.css'

function TreeArrow({ lastChild }) {


if(lastChild) {

    return (<>

        <div className='tree-arrow-wrapper'>
            <div className='tree-arrow-line'>
                <div className='tree-arrow-left-pointer'/>
                <div className='tree-arrow-tip' />
                <div className='tree-arrow-right-pointer'/>
            </div>
        </div>

    </>)
} else return (
    <>
        <div className='tree-arrow-wrapper'>
            <div className='tree-arrow-bar'/>
            <div className='tree-arrow-line'>
                <div className='tree-arrow-left-pointer'/>
                <div className='tree-arrow-tip' />
                <div className='tree-arrow-right-pointer'/>
            </div>
        </div>
    </>
)

}

export default TreeArrow
import React, { useState, useEffect, useContext } from 'react';
import { useContextValue } from '../../AppContext';
import { Section, HStack, VStack, Headline, Title1, Title2, Title3, Button, Space, Group, Caption1, Caption2, Label, Container, Body, Sheet, Divider, Input, Footnote, Switch, Subhead } from '@themeable/react-components';
import { doc, updateDoc, arrayUnion, Timestamp } from 'firebase/firestore';
import { db, auth } from '../../services/firebase';
import { CreateNewSystem, DeleteSystem, PublishUpdate, timestampToDate } from '../Utilities/UtilityFunctions'; 
import { handleCheckout } from '../../services/stripe';

function Overview() {
	
    const [renameModal, setRenameModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const { context, setContext, cSpace, cColor, cText, cRadius, cSize } = useContextValue();
    const [publishState, setPublishState] = useState('');
    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const [loadingStripe, setLoadingStripe] = useState(false);

    const [newSystemName, setNewSystemName] = useState('');
    const [updateSystemButton, setUpdateSystemButton] = useState(false);

    useEffect(() => {
      if(publishState === 'published') {window.location.reload()}
    }, [publishState])
    

    const renameSystem = () => {
        setNewSystemName(context?.systemList[context?.selectedSystem].name)
        setUpdateSystemButton(false);
        setErrorMessage('');
        setRenameModal(true);
    }

    const [errorMessage, setErrorMessage] = useState('');
    
    const [deleteSystemButton, setDeleteSystemButton] = useState(false)
    const handleDeleteSystem = (input) => {

        if(input === context.systemList[context.selectedSystem].name) {
            setDeleteSystemButton(true)
        } else {
            setDeleteSystemButton(false)
        }

    }

    const handlePaymentCheckout = async (priceId) => {
        const error = await handleCheckout(priceId);
        if (error) {
            setErrorMessage(error);
        }
      }

    const sendNewSystemName = async () => {
        const regex = /^[a-zA-Z0-9_()-\s]+$/;
        if (!newSystemName.match(regex)) {
            setErrorMessage("System name can only contain letters, numbers, parenthesis, hyphens, or underscores.");
            return; // cancel rest of function
        }
    
        try {
            // Update the name field of the document in the "systems" collection
            const systemDocRef = doc(db, 'systems', context.selectedSystem);
            await updateDoc(systemDocRef, {
                name: newSystemName,
                lastModified: Timestamp.now()  // Use Timestamp.now() for lastModified field
            });
    
            // Update the subscribed systems array in the user's document
            const userRef = doc(db, 'users', auth.currentUser.uid);
            await updateDoc(userRef, {
                'systems.subscribed': arrayUnion(context.selectedSystem),
            });
    
            window.location.reload();
    
        } catch (error) {
            console.error('Error updating system name:', error);
            // Handle the error accordingly
            throw error; // Propagate the error further if needed
        }
    };
    
    if(context?.systemList[context?.selectedSystem]?.name === undefined) {
        return null
    }

return (<>


<Sheet 
    active={subscriptionModal} 
    variant='form' 
    behavior='hug'
    onStateChange={(e) => {setSubscriptionModal(false)}}
    >
    <Section>
        <HStack layout='right middle' style={{position: 'relative'}}>
        <Headline align='center'></Headline>
        <Headline align='center'style={{position: 'absolute', left: '50%', transform: 'translateX(-50%)'}}>Publish system</Headline>
        <Button 
            text1='Cancel'
            variant='quaternary'
            small
            onClick={() => {setSubscriptionModal(false)}}
            />
        </HStack>
        <Space.Content />
        <Divider />
        <Space.Content />
        <Space.Content />
        <Space.Content />
        <Title3>Subscription Settings</Title3>
        <Body>
            To publish a system for Web/iOS/Android and Figma, you must subscribe.
        </Body>
        <Body>To learn more about the power of Themeable, check out our website or subscribe now.</Body>
        <Space.NewSection />

            <Button text1='Subscribe 🎉' loading={loadingStripe} variant='primary' onClick={() => {handlePaymentCheckout('price_1Nf8ItHUEMXMwifTAxyefQ8d'); setLoadingStripe(true);}}/>
            <Button text1='Learn more' variant='secondary' url='https://getthemeable.com/' target='_blank' icon2='ChevronRight'/>


    </Section>
</Sheet>

<Sheet 
    active={renameModal} 
    variant='form' 
    behavior='hug'
    // onStateChange={handleRenameModal}
    onStateChange={(e) => {setRenameModal(false)}}
    >
    <Section>
        <HStack layout='right middle' style={{position: 'relative'}}>
        <Headline align='center'></Headline>
        <Headline align='center'style={{position: 'absolute', left: '50%', transform: 'translateX(-50%)'}}>Rename system</Headline>
        <Button 
            text1='Cancel'
            variant='quaternary'
            small
            onClick={() => {setRenameModal(false)}}
            />
        </HStack>
        
            <Label>
                <Container variant='fill'>
                    <Input 
                        value={newSystemName}
                        onChange={(e) => {setNewSystemName(e.target.value); setUpdateSystemButton(true);}}
                        />
                </Container>
                <Footnote color='--color-negative'>{errorMessage}</Footnote>
            </Label>
            <Space.Content />


        {updateSystemButton && (
            <>
                <Button text1='Update' variant='primary' onClick={() => {sendNewSystemName()}}/>
            </>
        )}

    </Section>
</Sheet>




<Section>
    <Space.NewSection />
    <HStack grow wrap layout='middle'>
            <VStack>
                <Headline color='--color-text-secondarry'>
                    {context.systemList?.length > 0 ? ('') : (<>Welcome back, {context?.user?.name} 👋 </>)}
                </Headline>
                <HStack layout='middle left'>
                    <Title1>
                        {context?.systemList[context?.selectedSystem]?.name}
                    </Title1>
                    <Button 
                        small
                        icon1='Edit'
                        text1='Rename'
                        onClick={() => {renameSystem()}}
                        />
                </HStack>
            </VStack>

        <Space.Grow />
        {/* Future "push update here???" */}

    </HStack>


    <Container style={{height: '250px', overflow: 'hidden'}} variant='outline'>
    <div className='alt-theme' style={{background: 'rgb(var(--ts-color-background-flat))', position: 'relative', height: '250px'}}>
        <div style={{background: '', width: '100%', minWidth: '1000px', left: '40%', top: '20%', position: 'absolute', transform: 'rotate(-10deg) translate(-40%, -50%) scale(100%)'}}>
            <HStack layout='top'>
                <VStack>
                    <Button variant='primary' text1='Primary'/>
                    <Button variant='accent' text1='Accent'/>
                    <Button variant='secondary' text1='Secondary'/>
                    <Button variant='tertiary' text1='Tertiary'/>
                    <Button variant='quaternary' text1='Quaternary'/>
                    <Button variant='destructive' text1='Destructive'/>
                </VStack>
                <VStack layout='left'>
                    <Button small variant='primary' text1='Primary'/>
                    <Button small variant='accent' text1='Accent'/>
                    <Button small variant='secondary' text1='Secondary'/>
                    <Button small variant='tertiary' text1='Tertiary'/>
                    <Button small variant='quaternary' text1='Quaternary'/>
                    <Button small variant='destructive' text1='Destructive'/>
                    <Space.Content />
                    <Divider />
                    <Space.Content />
                    <HStack layout='left'>
                        <Switch checked onChange={() => {console.log('')}} onClick={() => {console.log()}}/>
                        <Switch onChange={() => {console.log('')}} onClick={() => {console.log()}}/>
                    </HStack>
                </VStack>
                <Divider vertical />
                <HStack>
                    <VStack layout='top'>
                        <HStack layout='left'>
                            <Switch checked onChange={() => {console.log('')}} onClick={() => {console.log()}}/>
                            <Switch onChange={() => {console.log('')}} onClick={() => {console.log()}}/>
                        </HStack>
                        <Container variant='fill'>
                            <Input placeholder='Your name'/>
                        </Container>
                        <Label text1='First name' inset>

                        <Container variant='outline'>
                            <Input placeholder='John'/>
                        </Container>
                        </Label>
                        <Label text1='Last name' inset>

                        <Container variant='default'>
                            <Input placeholder='Smith'/>
                        </Container>
                        </Label>
                        <Divider />
                        <Footnote>Footnote</Footnote>
                        <Caption1>Caption 1</Caption1>
                        <Caption2>Caption 2</Caption2>

                    </VStack>
                    <VStack layout='middle'>

                        <Container variant='fill'>
                            <Title1>Title 1</Title1>
                            <Title2>Title 2</Title2>
                            <Title3>Title 3</Title3>
                        </Container>
                        <Container variant='default'>
                            <Headline>Headline</Headline>
                            <Body>Body</Body>
                            <Subhead>Subhead</Subhead>
                            <Footnote>Footnote</Footnote>
                            <Caption1>Caption 1</Caption1>
                            <Caption2>Caption 2</Caption2>
                        </Container>
                    </VStack>
                </HStack>
            </HStack>
        </div>
    </div>
    </Container>
    
    <HStack>
        {context.systemList[context.selectedSystem].createdOn &&(

            <Caption2 color='--color-text-secondary'>
            Created: <span style={{color: 'rgba(var(--color-text-primary))'}}>{context.systemList[context.selectedSystem] && (timestampToDate(context.systemList[context.selectedSystem].createdOn))}</span>
        </Caption2>
        )}
        <Caption2 color='--color-text-secondary'>
            Last updated: <span style={{color: 'rgba(var(--color-text-primary))'}}>{context.systemList[context.selectedSystem] && (timestampToDate(context.systemList[context.selectedSystem].lastModified))}</span>
        </Caption2>
    </HStack>
</Section>

    <Section>
        <Title2>Dynamic Style Link</Title2>
        <Container variant='fill'>
            <Section>
                {context.systemList[context.selectedSystem].css?(
                    <Body>
                        {`<link rel="stylesheet" href="${context.systemList[context.selectedSystem].css}" />`}
                    </Body>
                ):(
                    <VStack layout='left'>
                        <Body>
                            To generate a dynamic style link for embeding in websites, publish this system first.
                        </Body>
                        {context.user.subscription === 'premium'?(
                            <Button 
                                text1='Publish system'
                                variant='primary'
                                onClick={() => {PublishUpdate( setContext, context, cColor, cRadius, cSpace, cSize, cText, setPublishState );}}
                                loading={publishState === 'publishing' ? true : false}
                                />
                                ):(<>
                            <Button
                                    text1='Publish system'
                                    variant='primary'
                                    onClick={() => {setSubscriptionModal(true)}}
                                    />
                        </>)}
                    </VStack>
                )}
        </Section>
    </Container>
</Section>

<Section>
    <HStack layout='middle left'>
        <Title2>Colors</Title2>
        <Button 
            variant='secondary'
            text1='Edit'
            icon1='Edit'
            small
            url={`../systemid=${context.selectedSystem}&page=foundations&subpage=color&density=compact&colorMode=light&l3=`}
            />
    </HStack>
    
    <HStack gridDesktop={8} gridTablet={6} gridMobile={4}>
    {[
        { 
            name: 'Primary', 
            value: context?.systemList[context?.selectedSystem].foundations.color.light.primary,
            alpha: 1
        },
        { 
            name: 'Accent', 
            value: context?.systemList[context?.selectedSystem].foundations.color.light.accent,
            alpha: 1
        },
        { 
            name: 'Text Primary', 
            value: context?.systemList[context?.selectedSystem].foundations.color.light.textPrimary,
            alpha: context?.systemList[context?.selectedSystem].foundations.color.light.textPrimaryAlpha
        },
        { 
            name: 'Text Secondary', 
            value: context?.systemList[context?.selectedSystem].foundations.color.light.textSecondary,
            alpha: context?.systemList[context?.selectedSystem].foundations.color.light.textSecondaryAlpha
        },
        { 
            name: 'Background (Flat)', 
            value: context?.systemList[context?.selectedSystem].foundations.color.light.backgroundFlat,
            alpha: 1
        },
        { 
            name: 'Background (Elevated)', 
            value: context?.systemList[context?.selectedSystem].foundations.color.light.backgroundElevated,
            alpha: 1
        },
        { 
            name: 'Border', 
            value: context?.systemList[context?.selectedSystem].foundations.color.light.border,
            alpha: context?.systemList[context?.selectedSystem].foundations.color.light.borderAlpha
        },
        { 
            name: 'Fill (Container)', 
            value: context?.systemList[context?.selectedSystem].foundations.color.light.fillContainer,
            alpha: context?.systemList[context?.selectedSystem].foundations.color.light.fillContainerAlpha
        },
        { 
            name: 'Fill (Control)', 
            value: context?.systemList[context?.selectedSystem].foundations.color.light.fillControl,
            alpha: context?.systemList[context?.selectedSystem].foundations.color.light.fillControlAlpha
        },
        { 
            name: 'Read only', 
            value: context?.systemList[context?.selectedSystem].foundations.color.light.readOnly,
            alpha: context?.systemList[context?.selectedSystem].foundations.color.light.readOnlyAlpha
        },
        { 
            name: 'Divider', 
            value: context?.systemList[context?.selectedSystem].foundations.color.light.divider,
            alpha: context?.systemList[context?.selectedSystem].foundations.color.light.dividerAlpha
        },
        { 
            name: 'Positive', 
            value: context?.systemList[context?.selectedSystem].foundations.color.light.positive,
            alpha: 1
        },
        { 
            name: 'Negative', 
            value: context?.systemList[context?.selectedSystem].foundations.color.light.negative,
            alpha: 1
        },
        { 
            name: 'Notice', 
            value: context?.systemList[context?.selectedSystem].foundations.color.light.notice,
            alpha: 1
        },
        { 
            name: 'Informative', 
            value: context?.systemList[context?.selectedSystem].foundations.color.light.informative,
            alpha: 1
        },
    ].map((color, index) => (
        <> 
            <div key={index}>
                <VStack>
                    <Group>
                        <div style={{aspectRatio: '1 / 1', opacity: color.alpha, borderRadius: 'var(--radius-large-control)', background: color.value, border: '1px solid rgba(var(--color-border), var(--color-border-alpha))', width: '100%'}}/>
                        <Space.Content />
                        <Space.Content />
                        <Caption1 align='left'>{color.name}</Caption1>
                        <Space.Content />
                        <Caption2 color='--color-text-secondary' modifier='regular' align='left'>{color.value.toUpperCase()}</Caption2>
                        <Space.Content />
                    </Group>
                </VStack>
            </div>
        </>
    ))}
    </HStack>

</Section>	

<Section>
    <HStack layout='middle left'>
        <Title2>Typography</Title2>
        <Button 
            variant='secondary'
            text1='Edit'
            icon1='Edit'
            small
            url={`../systemid=${context.selectedSystem}&page=foundations&subpage=text&density=compact&colorMode=light&l3=styles`}
            />
    </HStack>
    
    <Space.Content />
    <Subhead>
    {context?.systemList[context?.selectedSystem]?.foundations?.text?.family?.titleName}
    </Subhead>
    <Divider />
        <table className='alt-theme'>

            <tr style={{verticalAlign: 'baseline'}}>
               <th style={{whiteSpace: 'nowrap'}}>
                <HStack>
                    <Title1 color='--color-text-secondary'>Title 1</Title1>
                    <Space.Content />
                    <Space.Content />
                </HStack>
                </th> 
               <th>
                <Title1>The quick brown fox jumps over the lazy dog</Title1>
                </th> 
            </tr>

            <tr style={{verticalAlign: 'baseline'}}>
               <th>
                <Title2 color='--color-text-secondary'>Title 2</Title2>
                </th> 
               <th>
                <Title2>The quick brown fox jumps over the lazy dog</Title2>
                </th> 
            </tr>
            
            <tr style={{verticalAlign: 'baseline'}}>
               <th>
                <Title3 color='--color-text-secondary'>Title 3</Title3>
                </th> 
               <th>
                <Title3>The quick brown fox jumps over the lazy dog</Title3>
                </th> 
            </tr>
         </table>   
         <Space.NewSection />
    <Subhead>
    {context.systemList[context.selectedSystem]?.foundations?.text?.family?.name}
    </Subhead>
    <Divider />
    <table className='alt-theme'>
        <tr style={{verticalAlign: 'baseline'}}>
            <th>
            <Headline color='--color-text-secondary'>Headline</Headline>
            </th> 
            <th>
            <Headline>The quick brown fox jumps over the lazy dog</Headline>
            </th> 
        </tr>
        
        <tr style={{verticalAlign: 'baseline'}}>
            <th>
            <Body color='--color-text-secondary'>Body</Body>
            </th> 
            <th>
            <Body>The quick brown fox jumps over the lazy dog</Body>
            </th> 
        </tr>
        
        <tr style={{verticalAlign: 'baseline'}}>
            <th>
            <Subhead color='--color-text-secondary'>Subhead</Subhead>
            </th> 
            <th>
            <Subhead>The quick brown fox jumps over the lazy dog</Subhead>
            </th> 
        </tr>
        
        <tr style={{verticalAlign: 'baseline'}}>
            <th>
            <Footnote color='--color-text-secondary'>Footnote</Footnote>
            </th> 
            <th>
            <Footnote>The quick brown fox jumps over the lazy dog</Footnote>
            </th> 
        </tr>
        
        <tr style={{verticalAlign: 'baseline'}}>
            <th>
            <Caption1 color='--color-text-secondary'>Caption 1</Caption1>
            </th> 
            <th>
            <Caption1>The quick brown fox jumps over the lazy dog</Caption1>
            </th> 
        </tr>
        
        <tr style={{verticalAlign: 'baseline'}}>
            <th>
            <Caption2 color='--color-text-secondary'>Caption 2</Caption2>
            </th> 
            <th>
            <Caption2>The quick brown fox jumps over the lazy dog</Caption2>
            </th> 
        </tr>

    </table>
</Section>	

<Section>
    <VStack layout='left'>
        <Title2>System Options</Title2>
        <Button variant='destructive' text1='Delete System' onClick={() => {setDeleteModal(true)}}/>
        <Button variant='tertiary' text1='Duplicate System' onClick={() => {CreateNewSystem(context.selectedSystem, context)}}/>
    </VStack>
</Section>

<Sheet 
    active={deleteModal} 
    variant='form' 
    behavior='hug'
    onStateChange={(e) => {setDeleteModal(false)}}
    >
    <Section>
        <HStack layout='right middle' style={{position: 'relative'}}>
        <Headline align='center'></Headline>
        <Headline align='center'style={{position: 'absolute', left: '50%', transform: 'translateX(-50%)'}}>Delete System?</Headline>
        <Button 
            text1='Cancel'
            variant='quaternary'
            small
            onClick={() => {setDeleteModal(false)}}
            />
        </HStack>
        <Body align='center'>Are you sure you want to delete this system? This cannot be undone. <br />To confirm, type "{context.systemList[context.selectedSystem].name}" in the box below</Body>
            <Label>
                <Container variant='fill'>
                    <Input 
                        onChange={(e) => {handleDeleteSystem(e.target.value);}}
                        placeholder={context.systemList[context.selectedSystem].name}
                        align='center'
                        />
                </Container>
            </Label>
            <Space.Content />


        {deleteSystemButton && (
            <>
                <Button text1='Confirm Delete System' variant='destructive' onClick={() => {DeleteSystem(context)}}/>
            </>
        )}

    </Section>
</Sheet>


</>)
}

export default Overview
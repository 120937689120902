import React from 'react';
import PropTypes from 'prop-types';
const Container = ({ children, variant }) => {
  
  
  
  return (
    <>
      <div className={`container-${variant} v-stack grow`}>
        {children}
      </div>
    </>
  );
};

Container.defaultProps = {
  variant: 'default',
};

Container.propTypes = {
  variant: PropTypes.oneOf(['default', 'outline', 'fill']),
};

export default Container;

import { Container, HStack, Section, VStack } from '@themeable/react-components'
import React from 'react'
import { useContextValue } from '../AppContext';

function FoundationsPanel({ snippet, form }) {

	const { context, setContext } = useContextValue();

return (
    <VStack grow>
        <Container variant='default'>
            <HStack gridDesktop={2} gridTablet={2} gridMobile={1}>
                <VStack layout='middle'>
                    <Section>
                        {form}
                    </Section>
                </VStack>
                <Container className={`alt-theme ${context?.colorMode} ${context?.density}`}  variant='fill' style={{outline: '1px dotted rgba(var(--color-border), .4)', overflow: 'hidden', borderRadius: 'var(--ts-radius-page)', transition: 'all .4s'}}>
                    <VStack layout={`alt-theme ${context?.colorMode} ${context?.density}`} style={{background: 'rgb(var(--ts-color-background-flat))'}}>
                        {snippet}
                    </VStack>
                </Container>
            </HStack>
        </Container>
    </VStack>
)}

export default FoundationsPanel
import React, { useEffect, useState } from 'react'
import FoundationsPanel from '../../modules/FoundationsPanel'
import { Accordion, Body, Button, Caption1, Container, Divider, Footnote, Group, HStack, Headline, Icon, Input, Label, Media, Section, Space, Subhead, Switch, Tab, Tag, Title2, Title3, VStack } from '@themeable/react-components'
import { useContextValue } from '../../AppContext';
import { InputRadius } from '../../modules/InputPixel';

function TextFamilies() {


	const { context, cText, setCText } = useContextValue();
	
	
	
	const handleTextFamilyChange = (event) => {
		const { name, value } = event.target;

		setCText(prev => ({
			...prev,
			family: {
				...prev.family,
				[name]: value
			}
		}));
	};
	
	const changeFallbackFont = ( style, isTitle ) => {
		
		if (isTitle) {
			setCText(prev => ({
				...prev,
				family: {
					...prev.family,
					titleFallback: style
				}
			}));
		} else {
			setCText(prev => ({
				...prev,
				family: {
					...prev.family,
					fallback: style
				}
			}));
		}
		
	}



return (<>
	<VStack grow>

	{/* <Button url='https://getthemeable.com/education/how-to-choose-a-font-families'>
		<Container style={{
			backgroundImage: 'url(../assets/help-articles/foundation-help-text-family.jpg)',
			backgroundPosition: 'center center',
			backgroundSize: 'cover',
			}}>
			<Section>
				<VStack layout='middle center'>
					<Title2 color='--color-on-primary'>
						Text families
					</Title2>
					<HStack wrap>
						<Group>
							<Headline color='--color-on-primary'>
								Not sure what your settings should be? Check out this article
							</Headline>
							<Icon name='ArrowRight' color='--color-on-primary'/>
						</Group>
					</HStack>
				</VStack>
			</Section>
		</Container>
	</Button> */}

		<Container grow >
			<Section>
				<Group>
					<Subhead color='--color-text-secondary'>Title 1, Title 2, Title 3</Subhead>
					<Title3>Titles</Title3>
				</Group>

				<Divider />
					<Headline>Custom fonts (Unsupported in Figma)</Headline>
					<HStack wrap>
					<VStack maxWidth={'300px'}>

						<Label text1='Font Family'>
							<Container variant='fill'>
								<Input 
									onChange={handleTextFamilyChange}
									name='titleName'
									value={cText?.family?.titleName !== 'NaN' ? cText?.family?.titleName:''}
									/>
							</Container>
						</Label>
					</VStack>
					<VStack>

						<Label text1='Font Link'>
							<Container variant='fill'>
								<Input 
									onChange={handleTextFamilyChange}
									name='titleSrc'
									value={cText?.family?.titleSrc !== 'NaN' ? cText?.family?.titleSrc:''}
									/>
							</Container>
						</Label>
					</VStack>

				</HStack>

				<Divider />


				<VStack maxWidth={'300px'}>

					<Label text1='Font Fallback Style'/>
						<HStack layout='left top'>
							<Group>
								<Button 
									variant={
										(cText.family.titleFallback && cText.family.titleFallback.trim() !== '') ?
											(cText.family.titleFallback.includes('Helvetica') ? 'primary' : 'secondary')
											: (context.systemList[context.selectedSystem].foundations.text.family.titleFallback.includes('Helvetica') ? 'primary' : 'secondary')
									}
									small
									text1='Sans Serif'
									onClick={() => {changeFallbackFont('system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"', true)}}
									/>
								<Button 
									variant={
										(cText.family.titleFallback && cText.family.titleFallback.trim() !== '') ?
											(cText.family.titleFallback.includes('New York') ? 'primary' : 'secondary')
											: (context.systemList[context.selectedSystem].foundations.text.family.titleFallback.includes('New York') ? 'primary' : 'secondary')
									}
									small
									text1='Serif'
									onClick={() => {changeFallbackFont('"New York", "Roboto Serif", ui-serif, serif', true)}}
									/>

							</Group>
						</HStack>
				</VStack>
				<VStack>
					<Label text1='Outputted CSS'>
						<VStack>
							<Group>
								<Footnote color='--color-text-secondary' style={{wordBreak:'break-all'}}>
								{cText.family.titleSrc !== ''?(<>@import url("{cText.family.titleSrc}");</>):''}
								</Footnote>
								{cText.family.titleSrc !== ''?(<Space.Content />):''}
								<Footnote color='--color-text-secondary' style={{wordBreak:'break-all'}}>
									--text-font-family: {cText.family.titleName !== ''?(<>"{cText.family.titleName}", </>):''}{cText.family.titleFallback};
								</Footnote>
							</Group>

						</VStack>
					</Label>
				</VStack>

				
				<Container  variant='fill' style={{outline: '1px dotted rgba(var(--color-border), .4)', overflow: 'hidden', borderRadius: 'var(--ts-radius-container)', transition: 'all .4s'}}>
					<VStack style={{background: 'rgb(var(--ts-color-background-flat))'}}>
						<Section>
							<VStack layout='center middle alt-theme'>
								<Title3>This is a sample title</Title3>
							</VStack>
						</Section>	
					</VStack>
				</Container>

			</Section>
		</Container>
		




		
		<Container grow >
			<Section>
				<Group>
					<Subhead color='--color-text-secondary'>Headline, Body, Subhead, Footnote, Caption 1, Caption 2</Subhead>
					<Title3>Supporting Text</Title3>
				</Group>

				<Divider />
					<Headline>Custom fonts (Unsupported in Figma)</Headline>

					<HStack wrap>
					<VStack maxWidth={'300px'}>

						<Label text1='Font Family'>
							<Container variant='fill'>
								<Input 
									onChange={handleTextFamilyChange}
									name='name'
									value={cText?.family?.name !== 'NaN' ? cText?.family?.name:''}
									/>
							</Container>
						</Label>
					</VStack>
					<VStack>

						<Label text1='Font Link'>
							<Container variant='fill'>
								<Input 
									onChange={handleTextFamilyChange}
									name='src'
									value={cText?.family?.src !== 'NaN' ? cText?.family?.src:''}
									/>
							</Container>
						</Label>
					</VStack>

				</HStack>

				<Divider />


				<VStack maxWidth={'300px'}>

					<Label text1='Font Fallback Style'/>
						<HStack layout='left top'>
							<Group>
								<Button 
									variant={
										(cText.family.fallback && cText.family.fallback.trim() !== '') ?
											(cText.family.fallback.includes('Helvetica') ? 'primary' : 'secondary')
											: (context.systemList[context.selectedSystem].foundations.text.family.fallback.includes('Helvetica') ? 'primary' : 'secondary')
									}
									small
									text1='Sans Serif'
									onClick={() => {changeFallbackFont('system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"', false)}}
									/>
								<Button 
									variant={
										(cText.family.fallback && cText.family.fallback.trim() !== '') ?
											(cText.family.fallback.includes('New York') ? 'primary' : 'secondary')
											: (context.systemList[context.selectedSystem].foundations.text.family.fallback.includes('New York') ? 'primary' : 'secondary')
									}
									small
									text1='Serif'
									onClick={() => {changeFallbackFont('"New York", "Roboto Serif", ui-serif, serif', false)}}
									/>

							</Group>
						</HStack>
				</VStack>
				<VStack>
					<Label text1='Outputted CSS'>
						<VStack>
							<Group>
								<Footnote color='--color-text-secondary' style={{wordBreak:'break-all'}}>
								{cText.family.src !== ''?(<>@import url("{cText.family.src}");</>):''}
								</Footnote>
								{cText.family.src !== ''?(<Space.Content />):''}
								<Footnote color='--color-text-secondary' style={{wordBreak:'break-all'}}>
									--text-font-family: {cText.family.name !== ''?(<>"{cText.family.name}", </>):''}{cText.family.fallback};
								</Footnote>
							</Group>

						</VStack>
					</Label>
				</VStack>

				
				<Container  variant='fill' style={{outline: '1px dotted rgba(var(--color-border), .4)', overflow: 'hidden', borderRadius: 'var(--ts-radius-container)', transition: 'all .4s'}}>
					<VStack style={{background: 'rgb(var(--ts-color-background-flat))'}}>
						<Section>
							<VStack layout='center middle alt-theme'>
								<Headline>This is a sample headline</Headline>
								<Body>This is a sample body</Body>
							</VStack>
						</Section>	
					</VStack>
				</Container>

			</Section>
		</Container>
		

	</VStack>

</>)
}

export default TextFamilies
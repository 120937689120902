import React from 'react';
import PropTypes from 'prop-types';

const Stack = ({ children, layout, direction, wrap, grow, maxWidth, style, grid, className = '' }) => {
  const stackClass = direction === 'horizontal' ? 'h-stack' : 'v-stack';
  const wrapClass = wrap ? 'wrap' : '';  // Determine if 'wrap' class should be added
  const growClass = grow ? 'grow' : '';  // Determine if 'grow' class should be added
  const gridClass = grid ? 'grid' : '';  // Determine if 'grow' class should be added
  
  return (
    <div style={{maxWidth, ...style}} className={`${stackClass} ${layout} ${wrapClass} ${growClass} ${className} ${gridClass}`}>
      {children}
    </div>
  );
};

const HStack = (props) => <Stack {...props} direction="horizontal" />;
const VStack = (props) => <Stack {...props} direction="vertical" />;

Stack.propTypes = {
  direction: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  layout: PropTypes.string,
  children: PropTypes.node,
  wrap: PropTypes.bool,
  className: PropTypes.string,  // Add className as a prop type
  style: PropTypes.object // Add style as a prop type
};

Stack.defaultProps = {
  layout: '',
  wrap: false,
  className: '',  // Default value for className prop
  style: {} // Default value for style prop
};

export { HStack, VStack };

import React from 'react';
import PropTypes from 'prop-types'


const Icon = ({ name, size, color }) => (
  <svg xmlns='http://www.w3.org/2000/svg' className={`icon`} width={size} height={size}>
    <use 
      xlinkHref={`../icons.svg#icon${name}`}
      style={color ? { fill: `rgb(var(--color-${color}))` } : null}
    />
  </svg>
);


Icon.defaultProps = {
  type: 'button',
  text1: 'Action'
}

Icon.propTypes = {
  text: PropTypes.string,
}

export default Icon;
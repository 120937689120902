import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import Text from './Text';
import Loader from './Loader';

const Button = React.forwardRef(
  ({ leadingIcon, trailingIcon, variant, type, text1, iconColor, small, onClick, href, grow, loading }, ref) => {
    const handleClick = (event) => {
      if (type === 'submit') {
        event.preventDefault();
      }

      if (typeof onClick === 'function') {
        onClick(event);
      }
    };
    
    const loaderColors = {
      'primary': 'on-primary',
      'secondary': 'icon',
      'tertiary': 'primary',
      'quaternary': 'icon',
    };

    const loadingColor = loaderColors[variant];

    const content = loading ? (
      <Loader color={loadingColor}/>
    ) : (
      <>
        {leadingIcon && <Icon name={leadingIcon} size={24} />}
        {text1 && 
          (small 
            ? <Text.subhead className='content-view'>{text1}</Text.subhead>
            : <Text.body className='content-view'>{text1}</Text.body>
          )
        }
        {trailingIcon && <Icon name={trailingIcon} size={24} />}
      </>
    );

    if (type === 'link') {
      return (
        <a ref={ref} className={`button hstack middle ${variant} ${small ? 'small' : ''} ${grow ? 'grow' : 'hug'}`} href={href} onClick={handleClick}>
          {content}
        </a>
      );
    }

    return (
      <button
        ref={ref}
        type={type === 'submit' ? 'submit' : 'button'}
        onClick={handleClick}
        className={`button ${variant} h-stack middle ${small ? 'small' : ''} ${grow ? 'grow': ''}`}
      >
        {content}
      </button>
    );
  }
);

Button.defaultProps = {
  type: 'button',
  text1: '',
  variant: 'secondary',
  loading: false
};

Button.propTypes = {
  text1: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'destructive']),
  type: PropTypes.oneOf(['button', 'link', 'submit']),
  color: PropTypes.string,
  loading: PropTypes.bool
};

export default Button;

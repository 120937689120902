import React from 'react'
import FoundationsPanel from '../../modules/FoundationsPanel'
import { Accordion, Body, Button, Caption1, Container, Divider, Footnote, Group, HStack, Headline, Icon, Input, Label, Media, Section, Space, Subhead, Switch, Tab, Tag, Title2, Title3, VStack } from '@themeable/react-components'
import { useContextValue } from '../../AppContext';
import { InputRadius } from '../../modules/InputPixel';

function Radius() {
  return (
    <>
	<VStack layout=''>

		{/* <Button url='https://getthemeable.com/education/how-to-create-a-radius-system'>
			<Container style={{
				backgroundImage: 'url(../assets/help-articles/foundation-help-radius.jpg)',
				backgroundPosition: 'center center',
				backgroundSize: 'cover',
				}}>
				<Section>
					<VStack layout='middle center'>
						<Title2 color='--color-on-primary'>
							Radius
						</Title2>
						<HStack wrap>
							<Group>
								<Headline color='--color-on-primary'>
									Not sure what your settings should be? Check out this article
								</Headline>
								<Icon name='ArrowRight' color='--color-on-primary'/>
							</Group>
						</HStack>
					</VStack>
				</Section>
			</Container>
		</Button> */}

    	<FoundationsPanel 
			form={<>
				<Title3>Controls</Title3>
				<InputRadius 
					name='smallControl'
					label='Small Controls'
					/>
				<InputRadius 
					name='largeControl'
					label='Large Controls'
					/>	
			</>}
		snippet={<>
		<VStack layout=''>
				<div style={{background: 'rgb(var(--ts-color-background-elevated))'}}>
					<Section style={{paddingTop: '0px', paddingBottom: '0px'}}>

						<HStack layout='center'>
							<Tab
								active
								text1='Small tab'
								stacked
								icon1='Placeholder'
								indicator='bottom'
								/>
							<Tab
								text1='Small tab'
								stacked
								icon1='Placeholder'
								indicator='bottom'
								/>
							<Tab
								text1='Small tab'
								stacked
								icon1='Placeholder'
								indicator='bottom'
								/>
						</HStack>
					</Section>

				</div>
				<Section>
					<HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
						<VStack>
							<HStack layout='top left' wrap>
								<Group>
									<Button 
										small
										variant='primary'
										text1='Small'
										/>
									<Button 
										small
										variant='secondary'
										text1='Button'
										/>
									<Button 
										small
										variant='secondary'
										text1='Button'
										/>
									<Button 
										small
										variant='secondary'
										text1='Small'
										/>
									<Button 
										small
										variant='secondary'
										text1='Small'
										/>
								</Group>
							</HStack>

						</VStack>
						<VStack layout='top'>

							<Button>
								<Container variant='fill'>
									<div 
										style={{
											display: 'flex',
											flexGrow: '1',
											height: '80px',
											background: 'rgba(var(--color-fill-container), var(--color-fill-container-alpha))',
											margin: 'var(--space-v-ext) var(--space-h-ext)',
											borderRadius: 'var(--radius-large-control)'
										}}
										/>
									<Caption1>Selectable Card</Caption1>
								</Container>
							</Button>
						</VStack>

					</HStack>
					<Container variant='fill'>
						<HStack layout='left middle'>
							<Input value='Text input'/>
							<Button small variant='tertiary' icon1='CloseCircle' />
						</HStack>
					</Container>
					<Space.NewSection />
					<HStack gridDesktop={2} gridMobile={2} gridTablet={2}>
						<Button text1='Large Button' />
						<Button text1='Large Button' variant='primary'/>
					</HStack>

				</Section>
				<div style={{background: 'rgb(var(--ts-color-background-elevated))'}}>
					<HStack layout='center'>
						<Tab
							active
							text1='Large tab'
							indicator='top'
							/>
						<Tab
							text1='Large tab'
							indicator='top'
							/>
						<Tab
							text1='Large tab'
							indicator='top'
							/>
					</HStack>
				</div>
		</VStack>
		</>}
	/>


	<FoundationsPanel 
		form={<>
			<Title3>Decorative Elements</Title3>
			<InputRadius 
				name='container'
				label='Containers'
				/>
			<InputRadius 
				name='page'
				label='Page'
				/>	
		</>}
		snippet={<>
		<Section>

			<VStack layout='middle'>
				<Container>
					<Section>
						<Accordion 
							parentContent={<>
								<Body>Accordion inside container</Body>
							</>}>
							<Footnote>Child element</Footnote>
						</Accordion>
						<Accordion 
							parentContent={<>
								<Body>Accordion inside container</Body>
							</>}>
							<Footnote>Child element</Footnote>
						</Accordion>
					</Section>				
				</Container>
				<VStack>
					<Accordion 
						parentContent={<>
							<Body>Accordion</Body>
						</>}>
						<Footnote>Child element</Footnote>
					</Accordion>
					
					<Container>
							
						<Accordion 
							parentContent={<>
								<Body>Accordion</Body>
							</>}>
							<Footnote>Child element</Footnote>
						</Accordion>
					</Container>

				</VStack>


			</VStack>
		</Section>

		</>}

	/>

	</VStack>

    </>
  )
}

export default Radius
import React from 'react';
import PropTypes from 'prop-types';
const Footer = ({ text1, inset, error }) => {
  
  return (
    <>
      {text1 ? (
      <div className={`v-stack footer ${inset ? 'inset': ''} ${error ? 'error-text':''}`}>
        {text1}
      </div>
      ):''}
    </>
  );
};

Footer.defaultProps = {
  type: 'label',
  inset: false
};

Footer.propTypes = {
  type: PropTypes.oneOf(['label', 'title3']),
  inset: PropTypes.bool
};

export default Footer;
